// ActionFormLayout.tsx
import { Box } from "@mui/material";
import NotesSection from "./NotesSection";
import { ModelErrors } from "sharedComponents";



export const ActionFormTitle = ({ content }) => {

  return (
    <Box sx={{ padding: '6px 10px',
               border: '1px solid #646D78',
               borderTopLeftRadius: '10px',
               borderBottom: 'none',
               borderTopRightRadius: '10px',
               color: '#fff',
               backgroundColor: '#637381',
    }} >

      <Box>
        {content}
      </Box>

    </Box>
  )
}


export const ActionFormBody = ({ content, contentSub="" }) => {

  return (
    <Box sx={{ background: '#fff', padding: '10px 16px' }}>

      <Box sx={{ marginBottom: '10px', color: '#777777', fontWeight: '500' }}>
        {content}
      </Box>

      {contentSub}

    </Box>
  )
}

export const ActionFormLayout = ({ title, body, form, onSetForm, errors }) => {

  const sub = (
    <>
      { errors && (
        <ModelErrors errors={errors} />
      ) }

      { onSetForm && form && (
        <NotesSection form={form} onSetForm={onSetForm} />
      )}
    </>
  )

  return (
    <>
      <ActionFormTitle content={title}/>

      <ActionFormBody content={body} contentSub={sub}/>
    </>

  )
}

export default ActionFormLayout
