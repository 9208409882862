import { alpha } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

export const ToggleButton = ({ isActive, color, label, onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      flex: 1,
      fontWeight: "normal",
      background: isActive ? color : "#AAB2BD",
      borderColor: isActive ? color : "#AAB2BD",
      color: "#fff",
      gap: 2,
      variant: "outlined",
      "&:hover": {
        background: alpha(isActive ? color : "#AAB2BD", 0.8),
        borderColor: isActive ? color : "#AAB2BD",
      },
    }}
    variant="outlined"
    size="small"
  >
    <Typography noWrap>
      Send message to <strong>{label}</strong> Channel
    </Typography>
    {isActive ? (
      <KeyboardArrowDownIcon/>
    ) : (
      <KeyboardArrowUpIcon/>
    )}
  </Button>
);
