import { Typography } from "@mui/material";
import { startCase } from "lodash";
import { formatDate } from "sharedUtils";
import ReportLogLayout from './ReportLogLayout';

const ServiceSuspensionRequestLog = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const {
    cancelledAt,
    requestedAt,
    date,
    notes,
    withImmediateEffect,
    noVisitsExpectedAfter,
    reasonId,
  } = request.data || {};

  const immediateEffectText = withImmediateEffect
    ? `with immediate effect: ${formatDate(requestedAt)}`
    : "";
  const noVisitsExpectedAfterText = noVisitsExpectedAfter
    ? `Effective From: ${formatDate(noVisitsExpectedAfter)}`
    : "";

  const logHeader = (
    <>
      {date ? `${startCase(request.name)} for ${formatDate(date)}`
            : startCase(request.name)
      }
    </>
  );

  const logContent = (
    <>
      <Typography>
        Vendor has been requested to suspend service {immediateEffectText}
      </Typography>

      {reasonId && (
        <Typography>Reason: {startCase(reasonId)}</Typography>
      )}

      {noVisitsExpectedAfter && (
        <Typography>{noVisitsExpectedAfterText}</Typography>
      )}
    </>
  );

  return (
    <ReportLogLayout logHeader={logHeader} logContent={logContent} object={request} />
  );

};

export default ServiceSuspensionRequestLog;
