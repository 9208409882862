import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { ModelErrors } from "sharedComponents";
import { formatDate } from "sharedUtils";
import NotesSection from "./common/NotesSection";
import BinCollectionReportTable from "./common/BinReportTable";

const ClientFeedbackOnFailedVisitReportForm = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const { collectionResultSlug, reportUuid } = form;

  const {
    serviceName,
    // deliveryResults,
    // collectionResults,
    inUseBinGroups,
    failureDate,
    // visitPlanName,
  } = context;

  const showPartialCollected =
    !isEmpty(inUseBinGroups) &&
    ["partial_failure"].includes(collectionResultSlug);

  // const collectionHandler = (slug) =>
  // onSetForm({
  // collectionResultSlug: slug,
  // });
  //
  // const deliveryHandler = (slug) =>
  // onSetForm({
  // deliveryResultSlug: slug,
  // });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Client Feedback
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>

      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs
          sx={{
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        >
          <Box>
            <Typography>Failure Date</Typography>
            {formatDate(failureDate)}
          </Box>
        </Grid>

        <Grid
          item
          xs
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
            "& > *": {
              flex: 1,
            },
          }}
        >
          {/* <div>
            <Typography variant="h6" fontWeight={"bold"}>
              Was waste collected
            </Typography>
            <VisitFailureChips
              showDeliveryReasonFailure={false}
              onChangeSelect={(event) =>
                onSetForm({
                  collectionFailureReasonSlug: event.target.value,
                })
              }
            />
          </div>
          <div>
            <Typography variant="h6" fontWeight={"bold"}>
              Was waste delivered
            </Typography>
            <VisitFailureChips
              options={deliveryResults}
              selectedSlug={deliveryResultSlug}
              onChange={deliveryHandler}
              showReasonFailure={false}
              onChangeSelect={(event) =>
                onSetForm({
                  deliveryFailureReasonSlug: event.target.value,
                })
              }
            />
          </div> */}
        </Grid>

        {showPartialCollected && (
          <BinCollectionReportTable
            inUseBinGroups={inUseBinGroups}
            onSetForm={onSetForm}
            showCollectionReasonFailure={false}
          />
        )}
      </Grid>

      <NotesSection form={form} onSetForm={onSetForm} />

      <ModelErrors errors={errors} />
    </div>
  );
};

export default ClientFeedbackOnFailedVisitReportForm;
