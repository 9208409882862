import { Controller } from "stimulus";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default class extends Controller {
  connect() {
    // Get the chart data from the data attribute
    const inputData = this.element.dataset.chartData;
    const parsedData = JSON.parse(inputData);

    const data = parsedData['data'];
    const serviceCodes = parsedData['service_codes'];

    // Parse dates
    data.forEach(function (datum) {
      datum['date'] = Date.parse(datum['date']);
    });

    // Create chart
    const chart = am4core.create(this.element, am4charts.XYChart);
    this.chart = chart; // Store the chart instance for later disposal
    chart.logo.disabled = true;

    chart.data = data;
    chart.fontSize = 12;
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    chart.scrollbarX.animationDuration = 0;

    // Date Axis
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.title.text = "Date";
    chart.events.on("ready", function (ev) {
      valueAxis.min = valueAxis.minZoomed;
      valueAxis.max = valueAxis.maxZoomed;
      dateAxis.zoomToDates(Date.parse(parsedData['zoom_start']), Date.parse(parsedData['zoom_end']), false, true);
    });
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = -1;
    dateAxis.endLocation = 2;
    dateAxis.tooltipDateFormat = "EEE dt MMM yyyy";

    dateAxis.dateFormats.setKey("day", "dd MMM");
    dateAxis.periodChangeDateFormats.setKey("day", "[bold]MMM");

    // Value Axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Units Collected";

    // Function to create series
    const createSeries = (name, color, chart) => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.dateX = "date";
      series.dataFields.valueY = name;
      series.width = am4core.percent(100);
      series.tooltipText = "[bold]{valueY}[/] x {name}";
      series.columns.template.fill = color;
      series.strokeWidth = 0;
      series.stacked = true;
      series.tooltip.fontSize = 12;
      chart.scrollbarX.series.push(series);
    };

    // Create all service code series
    serviceCodes.forEach((set) => {
      createSeries(set['name'], set['color'], chart);
    });


    // // Missed Collection Series
    if(parsedData['showMissed']) {
      const seriesMissedCollections = chart.series.push(new am4charts.LineSeries());
      seriesMissedCollections.name = 'Missed Collection Reports';
      seriesMissedCollections.dataFields.dateX = "date";
      seriesMissedCollections.dataFields.valueY = "missed_value";
      seriesMissedCollections.strokeWidth = 1;
      seriesMissedCollections.stroke = "#ccc";
      seriesMissedCollections.tooltip.fontSize = 12;
      seriesMissedCollections.tooltip.getFillFromObject = false;
      seriesMissedCollections.tooltip.background.fill = am4core.color("#656d77");

      seriesMissedCollections.adapter.add("tooltipText", (text, target) => {
        if (!target.tooltipDataItem.dataContext) return text;
        const values = target.tooltipDataItem.dataContext;
        return values.missed ? `Missed ${values.missed_fault} fault : ${values.missed_reason}` : "Success";
      });

      const missedBullet = seriesMissedCollections.bullets.push(new am4charts.Bullet());
      missedBullet.strokeWidth = 0;
      const circle = missedBullet.createChild(am4core.Circle);
      circle.radius = 5;

      missedBullet.adapter.add("fill", (fill, target) => {
        if (!target || !target.tooltipDataItem || !target.tooltipDataItem.dataContext) return fill;
        const values = target.tooltipDataItem.dataContext;
        return values.missed ? "#e64759" : "#5cb86a";
      });
    }

    // HWCN Series
    const seriesHwcns = chart.series.push(new am4charts.LineSeries());
    seriesHwcns.name = 'HWCNs';
    seriesHwcns.dataFields.dateX = "date";
    seriesHwcns.dataFields.valueY = "hwcn_value";
    seriesHwcns.strokeWidth = 1;
    seriesHwcns.stroke = "#ccc";

    const hwcnBullet = seriesHwcns.bullets.push(new am4charts.Bullet());
    hwcnBullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    const triangle = hwcnBullet.createChild(am4core.Triangle);
    triangle.width = 10;
    triangle.height = 10;
    triangle.horizontalCenter = "middle";
    triangle.verticalCenter = "middle";
    triangle.fill = '#ccc';
    triangle.strokeWidth = 0;

    hwcnBullet.events.on('hit', (ev) => {
      const url = ev.target.tooltipDataItem.dataContext.hwcn_url;
      if (url) window.location = url;
    });

    hwcnBullet.adapter.add("hoverable", (hoverable, target) => {
      if (!target || !target.tooltipDataItem || !target.tooltipDataItem.dataContext) return hoverable;
      const values = target.tooltipDataItem.dataContext;
      return values.hwcn;
    });

    hwcnBullet.adapter.add("fillOpacity", (fillOpacity, target) => {
      if (!target || !target.tooltipDataItem || !target.tooltipDataItem.dataContext) return fillOpacity;
      const values = target.tooltipDataItem.dataContext;
      return values.hwcn ? 1 : 0;
    });

    // Invoices Series
    if(parsedData['showInvoices']) {
      const seriesInvoices = chart.series.push(new am4charts.LineSeries());
      seriesInvoices.name = 'Invoices';
      seriesInvoices.dataFields.dateX = "date";
      seriesInvoices.dataFields.valueY = "invoice_value";
      seriesInvoices.strokeWidth = 1;
      seriesInvoices.stroke = "#ccc";

      const invoiceBullet = seriesInvoices.bullets.push(new am4charts.Bullet());
      const square = invoiceBullet.createChild(am4core.Rectangle);
      square.width = 8;
      square.height = 8;
      square.horizontalCenter = "middle";
      square.verticalCenter = "middle";
      square.fill = '#ccc';

      invoiceBullet.strokeWidth = 0;
      invoiceBullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;

      invoiceBullet.events.on('hit', (ev) => {
        window.location = ev.target.tooltipDataItem.dataContext.invoice_url;
      });

      invoiceBullet.adapter.add("fillOpacity", (fillOpacity, target) => {
        if (!target || !target.tooltipDataItem || !target.tooltipDataItem.dataContext) return fillOpacity;
        const values = target.tooltipDataItem.dataContext;
        return values.invoice ? 1 : 0;
      });
    }
    // Cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.fullWidthLineX = true;
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineX.fill = am4core.color("#2c3035");
    chart.cursor.lineX.fillOpacity = 0.1;

    // Legend
    chart.legend = new am4charts.Legend();
    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;
  }

  disconnect() {
    // Dispose of the chart when the controller is disconnected
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
