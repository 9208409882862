// FailedVisitReportLog.tsx
import { Chip, Box, Typography } from "@mui/material";
// import { flatMap } from "lodash";
// import { useTicket } from "ticket/selectors";
import ReportVisitFailureCardTable from "service/report-request-box/ReportVisitFailureCardTable";
import { formatDate } from "sharedUtils";
import ReportLogLayout from './ReportLogLayout';

const FailedVisitReportLog = ({ request: report }) => {
  // const { stakeholderContacts } = useTicket() || {};
  const { notes } = report?.data || {};

  // // Get all stakeholders
  // const allStakeholders = flatMap(stakeholderContacts || [], (x) => x);

  // // Find who inputted the request
  // const inputtedBy = allStakeholders?.find(
  //   (x) => x.id === report?.metadata?.inputtedById
  // );

  const isVendorFeedback = report?.name.includes("VendorFeedback");
  const isClientFeedback = report?.name.includes("ClientFeedback");

  const logHeader = isVendorFeedback || isClientFeedback ? (
    <>
      Update for failed visit report &nbsp;
      <Chip size="xsmall" color="info" label={formatDate(report?.data?.failureDate)} />
    </>
  ) : (
    <>
      Failed visit report sent  &nbsp;
      <Chip size="xsmall" color="error" label={formatDate(report?.data?.failureDate)} />
    </>

  );

  const logContent = (
    <>
      <ReportVisitFailureCardTable report={report.data} />
    </>
  );

  return (
    <ReportLogLayout logHeader={logHeader} logContent={logContent} notes={notes} object={report} createdAt={report?.createdAt }/>
  );
};

export default FailedVisitReportLog;
