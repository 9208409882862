import { Controller } from "stimulus"

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default class extends Controller {
  initialize() {

    var chartData = $(this.element).data('chart-data')
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(this.element, am4charts.XYChart);

    chart.data = chartData['data'];
    chart.logo.disabled = true;

    chart.dateFormatter.inputDateFormat = "MMM yyyy";
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 60;
      dateAxis.startLocation = 0;
      dateAxis.endLocation = 1;
      dateAxis.baseInterval = {
        timeUnit: "month",
        count: 1
      }

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;


    let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = chartData['name'];
      series.dataFields.dateX = "month";
      series.dataFields.valueY = "data";
      series.tooltipText = chartData['tooltip'];
      series.tooltip.fontSize = 12
      series.columns.template.fill = chartData['color']
      series.stacked = true;
      series.strokeWidth = 0;


    chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = dateAxis;

    chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";
      chart.legend.fontSize = 11
      chart.legend.color = "#414141"


    const markerTemplate = chart.legend.markers.template;
          markerTemplate.width = 10;
          markerTemplate.height = 10;

      }
}
