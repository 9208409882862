import { Box, Drawer } from "@mui/material";

export const FooterDrawer = ({
  isDrawerOpen,
  closeDrawer,
  children,
  openFormUrl,
}) => {

  const sx = openFormUrl ? {
    position: "sticky",
    zIndex: 1,
    maxHeight: "60vh",
    marginBottom: '5px',
    background: 'none',
  } : {
    position: "sticky",
    zIndex: 1,
    borderRadius: '4px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    maxHeight: "60vh",
    background: 'none',
  };


  return (
    <Drawer
      variant="persistent"
      anchor="bottom"
      open={isDrawerOpen}
      onClose={closeDrawer}
      PaperProps={{
        sx: sx,
      }}
    >
      <Box sx={{ height: "100%" }}>{children}</Box>
    </Drawer>
  );
};
