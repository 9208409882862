import { Typography } from "@mui/material";
import { startCase } from "lodash";
import { formatDate } from "sharedUtils";
import ReportLogLayout from './ReportLogLayout';

const ServiceTerminationRequestLog = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const {
    date,
    notes,
    finalCollectionRequired,
    finalCollectionRequiredBefore,
    requestedAt,
    noVisitsExpectedAfter,
  } = request.data || {};

  // TODO: Re visit this when confirmation is implemented

  const isFinalCollectionRequired =
    finalCollectionRequired && finalCollectionRequiredBefore;


  const logHeader = (
    <>
      {date ? `${startCase(request.name)} for ${formatDate(date)}`
            : startCase(request.name)
      }
    </>
  );

  const logContent = (
    <>
      {isFinalCollectionRequired ? (
        <div>
          <Typography>
            Vendor has been requested to terminate the service with final collection before:{" "}
            {formatDate(finalCollectionRequiredBefore)}
          </Typography>
        </div>
      ) : requestedAt ? (
        <div>
          <Typography>
            Vendor has been requested to terminate the service immediately:{" "}
            {formatDate(requestedAt)}
          </Typography>
        </div>
      ) :
      (
        <div>
          <Typography>
            Vendor has confirmed to terminate the service from{" "}
            {formatDate(noVisitsExpectedAfter)}
          </Typography>
        </div>
      )}
    </>
  );

  return (
    <ReportLogLayout logHeader={logHeader} logContent={logContent} object={request} />
  );

};

export default ServiceTerminationRequestLog;
