import { pick } from "lodash";
import { useEffect, useState } from "react";
import { Channel } from "service/ticket/types";
import { useDebounce } from "usehooks-ts";
import { useResponse } from "./useResponse";
import stringify from "json-stable-stringify";
import _ from "lodashExtended";
import { prepareSubmitData as _prepareSubmitData } from "sharedUtils";
import { useCannedResponse } from "./useCannedResponse";

export const prepareCannedResponse = (data) => {
  var attributeKeyNames = [
    "specifications",
    "deliveryUnits",
    "collectionUnits",
    "organisationResponse",
    "vendorResponse",
  ];

  return _prepareSubmitData(data, attributeKeyNames);
};

export const useCannedResponses = (formUrl, formData) => {
  const [cannedResponse, setCannedResponse] = useState({});
  const [responsesLoaded, setResponsesLoaded] = useState(false);

  var organisationResponse = useResponse(Channel.Organisation);
  var vendorResponse = useResponse(Channel.Vendor);

  const responseTemplateData = {
    organisationResponse: pick(organisationResponse, [
      "toContactId",
      "responseExpected",
    ]),
    vendorResponse: pick(vendorResponse, ["toContactId", "responseExpected"]),
  };

  const deboucedFormData = useDebounce(stringify(formData), 300);
  const deboucedResponseTemplateData = useDebounce(
    stringify(responseTemplateData),
    300
  );

  useEffect(() => {
    if (formUrl && _.isPresent(formData)) {
      var renderUrl = formUrl.replace(/\.json/, "/render_message.json");
      const csrfToken = $('meta[name="csrf-token"]').attr("content");
      fetch(renderUrl, {
        method: "PUT",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(
          prepareCannedResponse({
            form: {
              ...responseTemplateData,
              ...formData.form,
            },
          })
        ),
      })
        .then((res) => res.json())
        .then((data) => {
          setCannedResponse(data);
          setResponsesLoaded(true);
        })
        .catch((x) => {
          console.error(x);
        });
    }
  }, [formUrl, deboucedFormData, deboucedResponseTemplateData]);

  const cannedOrganisationResponse = useCannedResponse(
    organisationResponse,
    cannedResponse.organisationMessage
  );
  const cannedVendorResponse = useCannedResponse(
    vendorResponse,
    cannedResponse.vendorMessage
  );

  return {
    vendorResponse: cannedVendorResponse,
    organisationResponse: cannedOrganisationResponse,
    responsesLoaded,
    formDefaults: cannedResponse.formDefaults,
    formData,
  };
};
