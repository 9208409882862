import BlockIcon from "@mui/icons-material/Block";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormServiceActionMutation } from "api/services";
import { Contact } from "api/tickets";
import { flatMap } from "lodash";
import { useState } from "react";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";

const NoActionRequiredLog = ({ request, url }) => {
  const { stakeholderContacts } = useTicket() || {};
  const {
    data: { reason },
  } = request || {};
  const [open, setOpen] = useState(false);

  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x,
  );

  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.metadata?.inputtedById,
  );

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5" fontWeight="bold">
          No Action Required
        </Typography>
        <Box flex="1" />
        <IconButton
          disableRipple
          onClick={() => setOpen(!open)}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "20px", color: "#bfbfbf" },
          }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {open && (
        <Divider
          variant="middle"
          sx={{
            m: 0,
            mx: -2,
            mb: 1,
            borderColor: "#fff",
            opacity: "0.2",
          }}
        />
      )}

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          my: 1,
          "& th.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">{reason}</Typography>

            <VoidNoActionRequired url={url} />
          </Box>

          <Typography color="grey" sx={{ fontSize: "10px", mb: 1 }}>
            {`Inputted by ${inputtedBy?.nameWithoutEmail} on ${formatDate(
              request?.createdAt,
            )}`}
          </Typography>
        </>
      </Collapse>
    </>
  );
};

export default NoActionRequiredLog;

const VoidNoActionRequired = ({ url }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const onClose = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <Tooltip title="Void" arrow placement="top">
        <IconButton onClick={handleOpenDialog}>
          <BlockIcon sx={{ color: "#bfbfbf" }} />
        </IconButton>
      </Tooltip>

      {dialogOpen && (
        <VoidDialog onClose={onClose} dialogOpen={dialogOpen} url={url} />
      )}
    </>
  );
};

const VoidDialog = ({ onClose, dialogOpen, url }) => {
  const [submitForm] = useFormServiceActionMutation();

  const submitHandler = () => {
    submitForm({
      url: `${url}/void_no_action_required.json`,
      body: {},
    })
      .unwrap()
      .then(onClose)
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={onClose}>
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "12px" }}>
          Void No Action Required
        </DialogTitle>
        <DialogContent sx={{ p: "8px 16px" }}>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Typography variant="h6" sx={{ fontSize: "12px" }}>
              ARE YOU SURE?
            </Typography>
            <Button
              variant="outlined"
              color="info"
              onClick={onClose}
              sx={{ fontSize: "12px", width: "200px" }}
            >
              No
            </Button>
            <Box>
              <Button
                type="submit"
                variant="contained"
                onClick={submitHandler}
                color="success"
                sx={{ fontSize: "12px", width: "200px" }}
              >
                Yes
              </Button>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
