import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Chip, Box, Grid, Stack, Typography } from "@mui/material";
import { TextMuted } from "sharedComponents";
import YesNoSelect from "components/YesNoSelect";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useState } from "react";
import "react-infinite-calendar/styles.css";

import { formatDate } from "sharedUtils";
import BinReportTable from "./common/BinReportTable";

import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import ActionFormLayout from './common/ActionFormLayout';
import "./styles.scss";


const ClientReportVisitFailureForm = ({ form, context, errors, onSetForm }) => {
  const [open, setOpen] = useState(false);

  const {
    driverTurnUp,
    requestReattempt,
  } = form;

  const {
    serviceName,
    inUseBinGroups,
    lastVisits,
    nextVisits,
  } = context;

  const visitDays = lastVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  const title = (
    <span>
      <strong>Visit Failure Report</strong> &nbsp; <TextMuted> {serviceName}</TextMuted>
    </span>
  )

  const body = (
    <Box sx={{ minHeight: '200px'}} >





      <Grid container spacing={2}>
        <Grid item xs={4}>

          <Box sx={{
            padding: '8px',
            marginBottom: '6px',
            border: '1px solid #F1F1F5',
            borderRadius: '4px', }}
          >

            <Box>
              <Typography>Failure Date</Typography>
              <ServiceCalendarDatePicker
                open={open}
                visitDays={visitDays}
                setOpen={setOpen}
                maxDate={dayjs(new Date())}
                onChange={(newValue) =>
                  onSetForm({
                    failureDate: newValue.format("DD/MM/YYYY"),
                  })
                }
              />
            </Box>


            <Box sx={{ mb: '10px' }}>
              <YesNoSelect
                label="Did the driver arrive?"
                layout="horizontal"
                onChange={(driverTurnUp) => onSetForm({ driverTurnUp, }) }
                value={driverTurnUp}
              />
            </Box>

            <Box >
              <YesNoSelect
                label="Request re-attempt date?"
                layout="horizontal"
                value={requestReattempt}
                onChange={(requestReattempt) => onSetForm({ requestReattempt, }) }
              />
              {requestReattempt === false && nextVisits[0]?.date && (
                <Box sx={{ ml: '10px', mt: '5px', fontWeight: '400' }}>
                  <span >Next visit will be on </span> <Chip size="small" color="success" label={formatDate(nextVisits[0]?.date)} />
                </Box>
              )}

            </Box>

          </Box>

        </Grid>
        <Grid item xs={4}>



            { driverTurnUp && (
              <Box sx={{ padding: '8px', marginBottom: '6px', border: '1px solid #F1F1F5', borderRadius: '4px', }} >
                <Box sx={{ mb: '15px'}}>
                  {!isEmpty(inUseBinGroups) && (
                    <BinReportTable
                      inUseBinGroups={inUseBinGroups}
                      onSetForm={onSetForm}
                      mode="collection"
                      isClient
                    />
                  )}
                </Box>
              </Box>
            )}

        </Grid>
        <Grid item xs={4}>

            { driverTurnUp && (

              <Box sx={{ padding: '8px', marginBottom: '6px', border: '1px solid #F1F1F5', borderRadius: '4px', }} >
                <Box>
                  <BinReportTable
                    inUseBinGroups={[]}
                    onSetForm={onSetForm}
                    mode="delivery"
                    isClient
                  />
                </Box>
              </Box>
            )}


        </Grid>
      </Grid>




    </Box>
  )

//

  return (
   <ActionFormLayout title={title} body={body} form={form} onSetForm={onSetForm} errors={errors}/>
  )

};

export default ClientReportVisitFailureForm;
