import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, IconButton, Grid } from "@mui/material";
import { useContext, useState } from "react";
import ServiceMappingInfo, {
  ServiceMappingInfoProps,
} from "./ServiceMappingInfo";
import VlcTable from "./VlcTable";
import ServiceBoxContext from "ticket/ServiceBoxContext";
import { useTheme } from '@mui/material/styles';
import VendorAccountLink from "./VendorAccountLink";


const VlcSection = ({ service }) => {
  const [openVLC, setOpenVLC] = useState(false);
  const { currentVendorLocationAccount } = useContext(ServiceBoxContext)
  const theme = useTheme();


  const remainingServiceMappings = service?.serviceMappings?.slice(1) || [];

  return (
    <>
      <Grid container gap={0}>

        <Grid item xs={10} sx={{ alignItems: 'center' }}>

          <ServiceMappingInfo openVLC={openVLC}
            {...(currentVendorLocationAccount as ServiceMappingInfoProps)}
            vendorAccountClosedMonthName={service?.vendorAccountClosedMonthName}
          />

        </Grid>
        <Grid item xs={2} sx={{ alignItems: 'center', textAlign: 'right' }}>

          {currentVendorLocationAccount && (

            <IconButton size="small" onClick={() => setOpenVLC(!openVLC)}>
              {openVLC ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>

          )}

        </Grid>

      </Grid>





      <Collapse in={openVLC} timeout={0} unmountOnExit>

        <VlcTable
          vendorLocationAccounts={
            currentVendorLocationAccount?.vendorLocationAccounts
          }
        />

        {remainingServiceMappings?.map((sm) => (
          <Box mt={1}>
            <ServiceMappingInfo
              openVLC={openVLC}
              {...(sm as ServiceMappingInfoProps)}
              oldVLA
            />
            <VlcTable vendorLocationAccounts={sm?.vendorLocationAccounts} />
          </Box>
        ))}

      </Collapse>
    </>
  );
};

export default VlcSection;
