import { Stack, Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

export default ({ label, value, onChange, trueText="Yes", falseText="No", layout='inline' }) => {
  const handleChange = (_event, newValue) => {
    // Only update if a new value is selected
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  const YesNoToggle = (
    <ToggleButtonGroup
      size="small"
      value={value}
      exclusive
      onChange={handleChange}
      sx={{
        "& .MuiToggleButton-root": {
          py: 0,
          fontSize: "12px",
          textTransform: "none",
          "&.Mui-selected": {
            backgroundColor: "#007bff",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#0056b3",
            },
          },
        },
        "& .MuiToggleButtonGroup-grouped": {
          borderRadius: 1,
          mx: 0,
        },
      }}
    >
      <ToggleButton value={true}>{trueText}</ToggleButton>
      <ToggleButton value={false}>{falseText}</ToggleButton>
    </ToggleButtonGroup>
  )

switch (layout) {
  case 'horizontal':
    return (
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: "space-between", alignItems: "center", }}
      >
        <Box>{label}</Box>
        <Box>{YesNoToggle}</Box>
      </Stack>
    );
  case 'vertical':
    return (
      <>
        <Box>{label}</Box>
        {YesNoToggle}
      </>
    );
  default:
    return (
      <>
        <span>{label} &nbsp;</span>
        {YesNoToggle}
      </>
    );
}


};
