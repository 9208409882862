import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import UnknownIcon from "@mui/icons-material/Remove";

import LinkIcon from "@mui/icons-material/Link";
import {
  Box, Card, CardContent, Chip, Collapse, Grid, IconButton, Link, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from "@mui/material";
import { Contact } from "api/tickets";
import { flatMap, get, last, startCase } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import BinDot from "service/BinDot";
import { DebugButton } from "service/GlobalDebugger";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { getInUseBinGroupsForService, } from "ticket/selectors";
import ReportRequestCardLayout from "./ReportRequestCardLayout"
import { TypoSuccess, TypoWarning, TypoError, TypoHaveInfo, TypoNeedInfo } from 'sharedComponents';



const ReportCard = ({ report }) => {

  const inUseBinGroups = getInUseBinGroupsForService(report.serviceUuid);

  // Determine the latest feedback and collected bin group IDs
  const { latestFeedback, collectedBinGroupIds, uncollectedBinGroupIds } =
    useMemo(() => {
      const vendorFeedback = last(report.vendorFeedback);
      const clientFeedback = last(report.clientFeedback);

      const latest = vendorFeedback || clientFeedback;

      return {
        latestFeedback: latest,
        collectedBinGroupIds: get(
          latest,
          "visitReport.collectedBinGroupIds",
          report.collectedBinGroupIds,
        ),
        uncollectedBinGroupIds: get(
          latest,
          "visitReport.uncollectedBinGroupIds",
          report.uncollectedBinGroupIds,
        ),
      };
    }, [report]);

  // Update delivery and collection result slugs based on latest feedback
  const deliveryResultSlug = get(
    latestFeedback,
    "visitReport.deliveryResultSlug",
    report.deliveryResultSlug,
  );
  const collectionResultSlug = get(
    latestFeedback,
    "visitReport.collectionResultSlug",
    report.collectionResultSlug,
  );


  const title = (
    <>
      Failed Visit Report for &nbsp;

      <Chip size="xsmall" color="warning" label={formatDate(report?.date)} /> &nbsp;
         { report.status === 'awaiting_feedback' ? (
            <TypoNeedInfo> <strong>{startCase(report.status)}</strong> </TypoNeedInfo>
           ) : (
            <TypoHaveInfo> <strong>{startCase(report.status)}</strong> </TypoHaveInfo>
          )
         }

    </>
  );

  const label = (
    <Box>

      <strong> Driver
        { report?.driverTurnUp ? (
            <TypoSuccess> Arrived</TypoSuccess>
          ) : (
            <TypoError> Failed to Arrived</TypoError>
          )
        }
      </strong>

      &nbsp; &nbsp; Reported by

      { report.reportedByStakeholderId === 1 || report.reportedByStakeholderId === 2 ? " Client" : " Vendor" }

    </Box>
  );



  const renderReportStatus = (slug) => {
    switch (slug) {
      case 'total_failure':
        return (
          <TypoError component="strong">Nothing</TypoError>
        );
      case 'partial_failure':
        return (
          <TypoWarning component="strong">Partial</TypoWarning>
        );
      case 'success':
        return (
          <TypoSuccess component="strong">All Good</TypoSuccess>
        );
      default:
        return (
          <Typography component="strong" color="info">Unknown Status</Typography>
        );
    }
  };

  const body = (
    <>
        <>
          <Grid container spacing={2}>



            <Grid item xs={6}>
              <Box sx={{ mb: '7px'}}>Collected <strong>{renderReportStatus(collectionResultSlug)}</strong></Box>
              <Box>
                <span>Reason </span>
                 {report?.collectionFailureReason?.name ? (
                   <Typography> {report.collectionFailureReason.name}</Typography>
                 ) : (
                   <Chip label="Not Supplied" size="xsmall" color="info"/>
                )}
              </Box>

            </Grid>

            <Grid item xs={6}>
              <Box sx={{ mb: '7px'}}>Delivered <strong>{renderReportStatus(deliveryResultSlug)}</strong></Box>

              <Box>
                <span>Reason </span>
                {report?.deliveryFailureReason?.name ? (
                  <Typography>
                    {report.deliveryFailureReason.name}
                  </Typography>
                ) : (
                  <Chip label="Not Supplied" size="xsmall" color="info"/>
                )}
              </Box>

            </Grid>

          </Grid>

          { (collectedBinGroupIds.length > 0 || uncollectedBinGroupIds.length > 0) && (
          <Box sx={{ mt: '7px'}}>
            <TableContainer>
               <Table
                 size="small"
                 sx={{
                   "& .MuiTableCell-root": {
                     padding: 0,
                     fontSize: "11px",
                     border: "none",
                   },
                   width: "auto",
                   tableLayout: "fixed",
                 }}
               >
                 <TableBody
                   sx={{
                     "& .MuiTableCell-root": {
                       fontSize: "11px",
                       color: "#BFBFBF",
                     },
                   }}
                 >
                   {inUseBinGroups.map((bg: any) => (
                     <BinGroupRow
                       key={bg.id}
                       binGroup={bg}
                       isCollected={collectedBinGroupIds.includes(bg.id)}
                       isUncollected={uncollectedBinGroupIds.includes(
                         bg.id,
                       )}
                     />
                   ))}
                 </TableBody>
               </Table>
             </TableContainer>
          </Box>
          )}
        </>

    </>
  );


  return (
    <ReportRequestCardLayout title={title} links='' label={label} body={body} request={report} />
  );

};

export default ReportCard;

const BinGroupRow = ({ binGroup, isCollected, isUncollected }) => {

  const getIconColor = () => {
    if (isCollected === true) return "success.main";
    if (isCollected === false) return "error.main";
    return "action.disabled";
  };

  const getIcon = () => {

    const iconSx = { color: getIconColor(), fontSize: '18px', }

    if (isCollected === true) return (
      <CheckIcon sx={{...iconSx}}/>
    );
    if (isCollected === false) return (
      <CloseIcon sx={{...iconSx}}/>
    );
    return (
      <UnknownIcon sx={{...iconSx}}/>
    );
  };

  return (
    <TableRow>
      <TableCell align="center" sx={{ width: '20px', textAlign: 'left'}}>
        {getIcon()}
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <BinDot binGroup={binGroup} tooltipName={true} tooltipPlace="top" />
          <Typography noWrap>{binGroup.binTypes.join(", ")}</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};
