import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormServiceActionMutation } from "api/services";
import { useState } from "react";
import NotesSection from "service/forms/common/NotesSection";
import { useService } from "ticket/selectors";
import BlockIcon from "@mui/icons-material/Block";

const LogActionButton = ({ event }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const onClose = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <Tooltip title="Void" arrow placement="top">

          <Button variant="outlined" size="small" color="error" onClick={handleOpenDialog}>Void</Button>

      </Tooltip>

      {dialogOpen && (
        <VoidFormDialog
          serviceUuid={event?.data?.serviceUuid}
          eventId={event?.data?.eventId}
          onClose={onClose}
          dialogOpen={dialogOpen}
        />
      )}
    </>
  );
};

const VoidFormDialog = ({ serviceUuid, eventId, onClose, dialogOpen }) => {
  const service = useService(serviceUuid);

  const [formData, setFormData] = useState({ notes: "" });
  const [submitForm] = useFormServiceActionMutation();

  const submitHandler = () => {
    submitForm({
      url: service?.voidRequestActionUrl,
      body: {
        form: {
          voidEventId: eventId,
          notes: formData?.notes,
        },
      },
    })
      .unwrap()
      .then(onClose)
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={onClose}>
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "12px" }}>
          Void Service Action
        </DialogTitle>
        <DialogContent sx={{ p: "8px 16px" }}>
          <Alert severity="error" sx={{ fontSize: "12px" }}>
            <p>
              Voiding this action will remove all assocated records in vector.
              This will NOT revoke any requests already sent to the vendor. This
              should only be used in the case that at reply was incorrectly
              tagged.{" "}
            </p>

            <p>
              If the request is no longer needed, use the cancelation feature.
              If the data was inputted incorrectly, void and then re-tag
            </p>
          </Alert>
          <NotesSection form={formData} onSetForm={setFormData} />
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Button
              variant="outlined"
              color="info"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Box>
              <Button
                type="submit"
                variant="contained"
                onClick={submitHandler}
                color="error"
              >
                Void
              </Button>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogActionButton;
