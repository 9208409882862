import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { startCase, uniq } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TimeAgo from "react-timeago";

// import RequestsCards from "service/report-request-box/RequestsCards";
import { useTheme } from '@mui/material/styles';

import CustomTable from "service/forms/common/CustomTable";
import { DebugButton } from "service/GlobalDebugger";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { getLocationId } from "ticket/selectors";


dayjs.extend(relativeTime);

const OutstandingRequestsCollapsible = ({ service }) => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const outstandingRequestsRef = useRef(null);
  const typesContainerRef = useRef(null);
  const { requests } = service;

  const [open, setOpen] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [filter, setFilter] = useState("all");

  const headers = ["Type", "Status", "Ticket ID", "Sent", ""];

  const requestsList = uniq(requests?.map((r) => r.type));

  const filteredRequests = requests.filter(
    (request) => filter === "all" || request.status === filter,
  );

  useEffect(() => {
    const checkOverflow = () => {
      if (
        containerRef.current &&
        outstandingRequestsRef.current &&
        typesContainerRef.current
      ) {
        const containerWidth = containerRef.current.clientWidth;
        const outstandingRequestsWidth =
          outstandingRequestsRef.current.offsetWidth;
        const typesWidth = Array.from(
          typesContainerRef.current.children,
        ).reduce((total, child: any) => total + child.offsetWidth, 0);

        const totalContentWidth = outstandingRequestsWidth + typesWidth + 80;

        setHasOverflow(totalContentWidth > containerWidth);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [requestsList]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter === filter ? "all" : newFilter);
  };

  return (
    <>
      {/* Remove this when changing the table  */}
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "40px",
          // background: "#1C365B",
          // color: "#fff",
          // mx: -2,
          // minHeight: "43px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >

          {open && (
            <Box sx={{ display: "flex", gap: 1, mr: 2 }}>
              {["confirmed", "cancelled", "unconfirmed"].map((status) => (
                <Chip
                  key={status}
                  label={
                    status === "awaiting_response"
                      ? "Awaiting response"
                      : startCase(status)
                  }
                  onClick={() => handleFilterChange(status)}
                  variant={filter === status ? "filled" : "outlined"}
                  color={filter === status ? "warning" : "default"}
                  size="small"
                  sx={{ cursor: "pointer", color: "#fff", }}
                />
              ))}
            </Box>
          )}

          <Typography
            ref={outstandingRequestsRef}
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "#fff",
              mr: 2,
              whiteSpace: "nowrap",
            }}
          >
            {filteredRequests.length}{" "}
            {getLabel(filter, filteredRequests.length)}
          </Typography>

          {!open && (
            <>
              <Box
                ref={typesContainerRef}
                sx={{
                  display: "flex",
                  overflow: "hidden",
                }}
              >
                {requestsList?.map((r: string) => (
                  <Typography
                    key={r}
                    sx={{
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                      color: "#4FC1E9",
                    }}
                  >
                    {startCase(r)}
                  </Typography>
                ))}
              </Box>
              {hasOverflow && (
                <Tooltip
                  arrow
                  placement="top"
                  title={requestsList?.map(startCase).join(", ")}
                >
                  <Typography sx={{ fontSize: "12px", marginLeft: "4px" }}>
                    ...
                  </Typography>
                </Tooltip>
              )}
            </>
          )}
        </Box>
        {requests.length > 0 && (
          <IconButton
            disableRipple
            onClick={() => setOpen(!open)}
            sx={{ color: "#fff"}}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </Box>

      {filteredRequests?.length > 0 && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <CustomTable
            headers={headers}
            rows={<RequestRows requests={filteredRequests} />}
          />
          {/* <RequestsCards requests={filteredRequests} /> */}
        </Collapse>
      )}
    </>
  );
};

export default OutstandingRequestsCollapsible;

const RequestRows = ({ requests }) => {
  const history = useHistory();
  const location = useLocation();
  const locationId = getLocationId();
  const { currentTicketId, highlightedIds, setHighlightedIds } =
    useContext(AppContext);

  const toggleHighlightedReplies = (request) => {
    const searchParams = new URLSearchParams(location.search);
    const currentHighlights = searchParams.getAll("highlight").map(Number);

    if (String(request.linkedTicketIds[0]) === currentTicketId) {
      // We're on the same ticket
      const newHighlights = request?.replyIds?.every((id) =>
        currentHighlights?.includes(id),
      )
        ? currentHighlights.filter((id) => !request?.replyIds?.includes(id)) // Remove all if all are currently highlighted
        : Array.from(new Set([...currentHighlights, ...request.replyIds])); // Add any that aren't already highlighted

      if (newHighlights.length > 0) {
        searchParams.delete("highlight"); // Clear existing highlights
        newHighlights.forEach((id) => searchParams.append("highlight", id));
      } else {
        searchParams.delete("highlight");
      }

      setHighlightedIds(newHighlights);

      // Update the URL and push to history
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // We are not on the same ticket
      const ticketId = request?.linkedTicketIds[0];
      const queryParam = `highlight=${request?.replyIds.join("&highlight=")}`;
      const newTabUrl = `/admin/locations/${locationId}/app#/tickets/${ticketId}?${queryParam}`;
      window.open(newTabUrl, "_blank");
    }
  };

  const handleLinkClick = (e, isInCurrentPath) => {
    if (isInCurrentPath) {
      e.preventDefault();
    }
  };

  return requests.map((request, index) => {
    // highlight is a string url
    const isSelected =
      request?.replyIds.length > 0 &&
      request?.replyIds?.every((id) => highlightedIds?.includes(String(id)));

    const isInCurrentPath =
      currentTicketId === String(request?.linkedTicketIds[0]);

    const isLastRow = index === requests.length - 1;

    return (
      <TableRow
        key={request.id}
        sx={{
          color: "#fff",
          ...(isLastRow && {
            "& .MuiTableCell-root": {
              borderBottom: "none",
            },
          }),
        }}
      >
        <TableCell>
          <Typography noWrap sx={{ fontSize: "11px" }}>
            {startCase(request.type)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography noWrap sx={{ fontSize: "11px" }}>
            {startCase(request.status)}
          </Typography>
        </TableCell>

        <TableCell>
          <Link
            target="_blank"
            href={`/admin/tickets/${request.linkedTicketIds}/app`}
            onClick={(e) => handleLinkClick(e, isInCurrentPath)}
            underline="none"
            sx={{
              color: isInCurrentPath ? "#fff" : "#4FC1E9 !important",
              "&:hover": {
                ...(isInCurrentPath ? { color: "#fff" } : {}),
              },
            }}
          >
            {isInCurrentPath ? "This ticket" : `#${request.linkedTicketIds[0]}`}
          </Link>
        </TableCell>
        <TableCell>
          <Tooltip title={formatDate(request.requestedAt)} arrow>
            <span>
              <TimeChip date={request.requestedAt} />
            </span>
          </Tooltip>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => toggleHighlightedReplies(request)}>
            <LinkIcon
              sx={{
                color: isSelected ? "#4FC1E9" : "#fff",
              }}
            />
          </IconButton>
        </TableCell>
        <DebugButton data={request} />
      </TableRow>
    );
  });
};

export const TimeChip = ({ date }) => {
  const now = dayjs();
  const requestDate = dayjs(date);
  const diffInDays = now.diff(requestDate, "day");
  const isOverTwoDays = diffInDays > 2;

  if (isOverTwoDays) {
    return (
      <Chip
        label={<TimeAgo date={date} />}
        color="error"
        sx={{ fontSize: "11px" }}
      />
    );
  }

  return <TimeAgo date={date} />;
};

const getLabel = (filter, count) => {
  const getSingularPlural = (singular, plural) =>
    count === 1 ? singular : plural;

  switch (filter) {
    case "all":
      return getSingularPlural("Request", "Requests");
    case "confirmed":
      return getSingularPlural("Confirmed Request", "Confirmed Requests");
    case "cancelled":
      return getSingularPlural("Canceled Request", "Canceled Requests");
    case "awaiting_response":
      return getSingularPlural("Awaiting Response", "Awaiting Responses");
    default:
      return "Unknown";
  }
};
