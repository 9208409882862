import { Box, CardContent, Divider, Typography } from "@mui/material";
import { TicketReply } from "../../../api/tickets";
import { styles } from "./ReplyCardStyles";

const ReplyCardContent = ({ reply }: { reply: TicketReply }) => {
  const { message, attachments } = reply;

  return (
    <CardContent sx={styles.cardContent}>
      <Box>{message}</Box>

      {attachments && attachments.length > 0 && (
        <Box sx={{ marginTop: 1 }}>
          <Divider
            sx={{
              left: 0,
              right: 0,
              top: 0,
              background: "#fff",
            }}
          />
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="subtitle1" color="info">
              Attachments:
            </Typography>
            {attachments.map((attachment, key) => (
              <Box key={key}>
                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                  {attachment.name}
                </a>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </CardContent>
  );
};

export default ReplyCardContent;
