import { api } from "./apiConfig";

interface ImageURLs {
  url: string;
  thumb?: { url: string };
  header?: { url: string };
  small?: { url: string };
  large?: { url: string };
}

interface Vendor {
  logo: ImageURLs;
  name: string;
  id: number;
  telephone: string;
  logoOnDark: ImageURLs;
}

interface CalendarEvent {
  status: string;
  date: string;
}

interface ServiceSpecification {
  serviceCode: string;
  quantity: number;
  visitActionId: string;
}

interface BillingHistoryUnit {
  code: string;
  quantity: number;
  lastBilledDate: string;
  vendorServiceCodes: string[];
  vendorServiceDescriptors: string[];
}

interface BillingHistoryDay {
  date: string;
  items: Array<{
    serviceCode: string;
    quantity: number;
    vendorServiceCode: string;
    vendorInvoiceUuids: string[];
  }>;
}

interface Action {
  value: string;
  label: string;
  path: string;
  group?: string;
}

interface Service {
  name: string;
  uuid: string;
  title: string;
  id: number;
  locationId: number;
  vendorId: number;
  vendorLocationCodes: string[];
  vendorServiceCodes: string[];
  status: string;
  visitPlanName: string;
  requestedVisitPlanName: string;
  vendorComments: string[];
  vendorName: string;
  specifications: { [key: string]: ServiceSpecification };
  log: any[];
  billingHistoryUnitAverages: { [key: string]: BillingHistoryUnit };
  billingHistory: BillingHistoryDay[];
  vendorAccountClosedMonthName?: string | null;
  vendorAccount: {
    id: number;
    number: string;
    name: string;
  };
  vendorLocationAccounts: Array<{
    lastBilledOn: string;
    firstBilledOn: string;
    vendorLocationCode: string;
  }>;
  actions: Action[];
  voidRequestActionUrl: string;
}

export interface LocationServiceVendor {
  [vendorId: string]: {
    vendor: Vendor;
    calendar: {
      nextVisit: CalendarEvent;
      lastVisit: CalendarEvent;
    };
    services: Service[];
  };
}

export const servicesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getService: build.query<Service, string>({
      query: (serviceId) => ({ url: `/admin/services/${serviceId}` }),
      providesTags: ["Service"],
      //providesTags: (result = []) => [
      //...result.map(({ id }) => ({ type: 'Service', id } as const)),
      //],
    }),
    getLocationServiceVendor: build.query<
      LocationServiceVendor,
      { locationId: string; vendorId: string }
    >({
      query: ({ locationId, vendorId }) => ({
        url: `/admin/locations/${locationId}/vendor/${vendorId}/xservices`,
      }),
      providesTags: ["Service"],
      //providesTags: (result = []) => [
      //...result.map(({ id }) => ({ type: 'Service', id } as const)),
      //],
    }),
    getLocationServiceVendors: build.query<LocationServiceVendor, string>({
      query: (locationId) => ({
        url: `/admin/locations/${locationId}/services`,
      }),
      providesTags: ["Service"],
      //providesTags: (result = []) => [
      //...result.map(({ id }) => ({ type: 'Service', id } as const)),
      //],
    }),
    getTicketApp: build.query({
      query: (ticketId) => ({
        url: `admin/tickets/${ticketId}/app`,
      }),
    }),
    getLocationRemediation: build.query({
      query: (locationId) => ({
        url: `/admin/location_remediation/${locationId}`
      }),
    }),
    formServiceAction: build.mutation({
      query: ({ url, body }) => {
        return {
          url,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [
        "BinType",
        "BinGroup",
        "Ticket",
        "TicketReplies",
        "Service",
        "FailedVisitReport",
        "AdhocVisitRequests",
        "StockDeliveryRequests",
        "RemediationCases",
        "Visit",
        "VendorServiceMapping",
        "ServiceSuspensionRequests",
        "ServiceTerminationRequests",
        "ServiceResumptionRequests",
        "SpecChangeRequests",
        "VisitFrequencyChangeRequests",
      ],
    }),
  }),
});

export const {
  useGetServiceQuery,
  useGetLocationServiceVendorQuery,
  useGetLocationServiceVendorsQuery,
  useGetTicketAppQuery,
  useFormServiceActionMutation,
  useGetLocationRemediationQuery
} = servicesApi;

export const {
  endpoints: {
    getService,
    getLocationServiceVendor,
    getLocationServiceVendors,
  },
} = servicesApi;

export default servicesApi;
