import PropTypes from "prop-types";
import { DebugButton } from "service/GlobalDebugger";
import "./styles.scss";
import Debugger from "./Debugger";
import { ModelErrors } from "sharedComponents";
import { Box, Stack, Typography } from "@mui/material";
import { TextMuted } from "sharedComponents";
import CollectionInstruction from "service/CollectionInstruction";
import DeliveryInstruction from "service/DeliveryInstruction";
import NotesSection from "./common/NotesSection";
import BinReportTable from "./common/BinReportTable";
import ActionFormLayout from './common/ActionFormLayout';

const CancelAdhocVisitForm = ({ form, context, errors, onSetForm }) => {
  const {
    visitPlanName,
    serviceName,
    requestDeliveryUnits,
    requestCollectionUnits,
    requestDeliveryCodes,
    requestCollectionCodes,
    requestClearAll,
  } = context;


 const title = (
    <span>
      <strong>Cancel Adhoc Visit Date</strong> &nbsp; <TextMuted> {serviceName}</TextMuted>
    </span>
  )


  const body = (
    <>
      <Box sx={{ mt: 1 }}>
         <CollectionInstruction
           {...context}
           collectionUnits={requestCollectionUnits}
           clearAll={requestClearAll}
           requestCollectionCodes={requestCollectionCodes}
           status={"cancelation"}
         />

         <DeliveryInstruction
           deliveryUnits={requestDeliveryUnits}
           requestDeliveryCodes={requestDeliveryCodes}
           status={"cancelation"}
           {...context}
         />
      </Box>
    </>
  )


  return (
   <ActionFormLayout title={title} body={body} form={form} onSetForm={onSetForm} errors={errors}/>
  )




  // return (
  //   <div>
  //     <Stack
  //       direction="row"
  //       justifyContent="flex-start"
  //       alignItems="center"
  //       spacing={2}
  //     >
  //       <Typography variant="h5" sx={{ fontWeight: "bold" }}>
  //         Cancel Adhoc Visit Request
  //       </Typography>
  //       <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
  //         {serviceName}
  //       </Typography>
  //     </Stack>

  //     <Box sx={{ mt: 1 }}>
  //       <CollectionInstruction
  //         {...context}
  //         collectionUnits={requestCollectionUnits}
  //         clearAll={requestClearAll}
  //         requestCollectionCodes={requestCollectionCodes}
  //         status={"cancelation"}
  //       />

  //       <DeliveryInstruction
  //         deliveryUnits={requestDeliveryUnits}
  //         requestDeliveryCodes={requestDeliveryCodes}
  //         status={"cancelation"}
  //         {...context}
  //       />
  //     </Box>
  //     <NotesSection
  //       form={form}
  //       onSetForm={onSetForm}
  //       context={context}
  //       contactLabel="Canceled By"
  //       contactPlaceholder="Who requested the cancellation?"
  //       dateLabel="Cancelled on"
  //       dateValue={form?.cancelledAt}
  //       onChange={(newValue) =>
  //         onSetForm({
  //           cancelledAt: newValue.format("DD/MM/YYYY"),
  //         })
  //       }
  //     />
  //     <DebugButton {...form} />
  //     <ModelErrors errors={errors} />
  //   </div>
  // );
};

CancelAdhocVisitForm.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    vendorLocationCode: PropTypes.string.isRequired,
    visitPlanName: PropTypes.string,
    binGroups: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        legendFillColor: PropTypes.string.isRequired,
        legendBorderColor: PropTypes.string.isRequired,
      })
    ).isRequired,
    binTypes: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        groupName: PropTypes.string.isRequired,
        binGroupId: PropTypes.number.isRequired,
      })
    ).isRequired,
    requestDeliveryUnits: PropTypes.objectOf(
      PropTypes.shape({
        quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        serviceCode: PropTypes.string.isRequired,
      })
    ),
    requestCollectionUnits: PropTypes.objectOf(
      PropTypes.shape({
        quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        serviceCode: PropTypes.string.isRequired,
        visitActionName: PropTypes.string.isRequired,
      })
    ),
    requestClearAll: PropTypes.bool.isRequired,
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default CancelAdhocVisitForm;
