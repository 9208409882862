import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { startCase, uniq } from "lodash";
import { useEffect, useRef, useState } from "react";
import ReportsCards from "service/report-request-box/ReportsCards";
import { useTheme } from '@mui/material/styles';

dayjs.extend(relativeTime);

const RequestRows = ({ requests }) => {
  const history = useHistory();
  const location = useLocation();
  const locationId = getLocationId();
  const { currentTicketId } = useContext(AppContext);
  const { highlightedIds, setHighlightedIds } = useContext(AppContext);

  // TODO: This is broken, we need to add replyIds to reports
  const handleIconClick = (request) => {
    const searchParams = new URLSearchParams(location.search);
    const currentHighlights = searchParams.getAll("highlight").map(Number);

    if (String(request.linkedTicketIds[0]) === currentTicketId) {
      // We're on the same ticket
      const newHighlights = request?.replyIds?.every((id) =>
        currentHighlights?.includes(id)
      )
        ? currentHighlights.filter((id) => !request?.replyIds?.includes(id)) // Remove all if all are currently highlighted
        : Array.from(new Set([...currentHighlights, ...request.replyIds])); // Add any that aren't already highlighted

      if (newHighlights.length > 0) {
        searchParams.delete("highlight"); // Clear existing highlights
        newHighlights.forEach((id) => searchParams.append("highlight", id));
      } else {
        searchParams.delete("highlight");
      }

      setHighlightedIds(newHighlights);

      // Update the URL and push to history
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // We are not on the same ticket
      const ticketId = request?.linkedTicketIds[0];
      const queryParam = `highlight=${request?.replyIds.join("&highlight=")}`;
      const newTabUrl = `/admin/locations/${locationId}/app#/tickets/${ticketId}?${queryParam}`;
      window.open(newTabUrl, "_blank");
    }
  };
  const handleLinkClick = (e, isInCurrentPath) => {
    if (isInCurrentPath) {
      e.preventDefault();
    }
  };

  return requests.map((request, index) => {
    const isSelected =
      request?.replyIds.length > 0 &&
      request?.replyIds?.every((id) => highlightedIds?.includes(String(id)));
    const isInCurrentPath =
      currentTicketId === String(request?.linkedTicketIds[0]);
    const isLastRow = index === requests.length - 1;

    return (
      <TableRow
        key={index}
        sx={{
          color: "#fff",
          ...(isLastRow && {
            "& .MuiTableCell-root": {
              borderBottom: "none",
            },
          }),
        }}
      >
        <TableCell>
          <Typography variant="h6">{formatDate(request.date)}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="h6">
            {request.reportedByStakeholderId === 1 ||
            request.reportedByStakeholderId === 2
              ? "Client"
              : "Vendor"}
          </Typography>
        </TableCell>
        <TableCell>
          <Link
            target="_blank"
            href={`/admin/tickets/${request.linkedTicketIds[0]}/app`}
            onClick={(e) => handleLinkClick(e, isInCurrentPath)}
            underline="none"
            sx={{
              color: isInCurrentPath ? "#fff" : "#4FC1E9 !important",
              "&:hover": {
                ...(isInCurrentPath ? { color: "#fff" } : {}),
              },
            }}
          >
            {isInCurrentPath ? "This ticket" : `#${request.linkedTicketIds[0]}`}
          </Link>
        </TableCell>
        <TableCell>
          <Tooltip title={formatDate(request.reportedAt)} arrow>
            <span>
              <TimeAgo date={request?.reportedAt} />
            </span>
          </Tooltip>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => handleIconClick(request)}>
            <LinkIcon
              sx={{
                color: isSelected ? "#4FC1E9" : "#fff",
              }}
            />
          </IconButton>
          <DebugButton data={request} />
        </TableCell>
      </TableRow>
    );
  });
};

const FailedVisitsCollapsible = ({ service }) => {
  const theme = useTheme();
  const typesContainerRef = useRef(null);
  const { latestVisitFailureReports: reports } = service;

  const [open, setOpen] = useState(true);
  const [hasOverflow, setHasOverflow] = useState(false);

  const requestsList = uniq(reports?.map((r) => r.type));

  useEffect(() => {
    const checkOverflow = () => {
      if (typesContainerRef.current) {
        setHasOverflow(
          typesContainerRef.current.scrollWidth >
            typesContainerRef.current.clientWidth
        );
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [requestsList]);

  return (
    <>
      <Box onClick={() => setOpen(!open)} sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "40px",
          px: '10px',
          cursor: 'pointer',
        }}
      >
        <Box sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
            }}
          >

            <strong>Failed Visit Reports</strong> <span>{reports?.length > 0 ? reports.length : "None"} </span> in last six months

          </Typography>
          <Box
            ref={typesContainerRef}
            sx={{
              display: "flex",
              overflow: "hidden",
              maxWidth: "calc(100% - 200px)",
            }}
          >
            {requestsList?.map((r: string, key: number) => (
              <Typography
                key={key}
                sx={{
                  whiteSpace: "nowrap",
                  marginRight: "8px",
                  color: "#4FC1E9",
                }}
              >
                {startCase(r)}
              </Typography>
            ))}
          </Box>
          {hasOverflow && (
            <Tooltip
              arrow
              placement="top"
              title={requestsList?.map(startCase).join(", ")}
            >
              <Typography sx={{ fontSize: "12px", marginLeft: "4px" }}>
                ...
              </Typography>
            </Tooltip>
          )}
        </Box>
        {reports.length > 0 && (
          <IconButton
            disableRipple
            sx={{
              color: "#fff"
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </Box>

      {reports?.length > 0 && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{
          }}
        >
          <ReportsCards service={service} />
        </Collapse>
      )}
    </>
  );
};

export default FailedVisitsCollapsible;
