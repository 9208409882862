import { Controller } from "stimulus";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default class extends Controller {
  initialize() {

    var chartData = $(this.element).data('chart-data');
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(this.element, am4charts.XYChart);

    chart.data = chartData['data'];
    chart.logo.disabled = true;



    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 1;
      categoryAxis.startLocation = 0;
      categoryAxis.endLocation = 1;
      categoryAxis.renderer.labels.template.wrap = true; // Wrap text if too long
      categoryAxis.renderer.labels.template.maxWidth = 120; // Set a maximum width for the labels to wrap text
      categoryAxis.renderer.labels.template.truncate = false; // Prevent truncation of labels
      categoryAxis.tooltip.disabled = true

          categoryAxis.renderer.labels.template.horizontalCenter = "middle";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      // categoryAxis.renderer.labels.template.dx = 10
    // categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
    //   if (target.dataItem && target.dataItem.index & 1) {
    //     return dy + 15;
    //   }
    //   return dy;
    // });

    if (chartData['rotateLabels']) {
      categoryAxis.renderer.labels.template.rotation = 90;

    }

    categoryAxis.renderer.labels.template.events.on("hit", function(ev) {
      const category = ev.target.dataItem.category;
      const item = chartData['data'].find(d => d.category === category);
      if (item && item.url) {
        window.open(item.url); // Open the URL in a new tab
      }
    });

    let hoverState = categoryAxis.renderer.labels.template.states.create("hover");
      hoverState.properties.fill = am4core.color("#0000FF"); // Set hover color to blue

    categoryAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;

    let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = chartData['name'];
      series.dataFields.categoryX = "category";
      series.dataFields.valueY = "data";
      series.tooltipText = chartData['tooltip'];
      series.tooltip.fontSize = 12;
      series.columns.template.fill = chartData['color'];
      series.stacked = true;
      series.strokeWidth = 0;



    chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

    // chart.legend = new am4charts.Legend();
    //   chart.legend.position = "bottom";
    //   chart.legend.fontSize = 11;
    //   chart.legend.color = "#414141";

    // const markerTemplate = chart.legend.markers.template;
    //   markerTemplate.width = 10;
    //   markerTemplate.height = 10;

  }
}
