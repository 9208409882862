import RemoveIcon from "@mui/icons-material/Remove";
import { DebugButton } from "service/GlobalDebugger";
import React, { useState } from "react";
import "./styles.scss";
import Debugger from "./Debugger";
import { ModelErrors } from "sharedComponents";
import VisitActionSelector from "./VisitActionSelector";
import dotProp from "dot-prop-immutable";
import { sanatizeDotProp, useBinFetch } from "sharedUtils";
import ReactTooltip from "react-tooltip";
import BinDot from "service/BinDot";
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import {
  BinTypeSelectorPopover,
  HeaderDetails,
  QuantitySelector,
} from "./common/QuantitySelector";
import NotesSection from "./common/NotesSection";
import ActionFormLayout from './common/ActionFormLayout';
import { TextMuted } from 'sharedComponents';



const d = dotProp;
interface BinGroup {
  id: number;
  name: string;
  legendFillColor: string;
  legendBorderColor: string;
}

interface VisitAction {
  id: string;
  name: string;
  type: string;
}

interface BinType {
  id: number;
  name: string;
  groupName: string;
  binGroupId: number;
  possibleVisitActions: VisitAction[];
}

interface Specification {
  serviceCode: string;
  quantity: number;
  notInContract: boolean;
  visitActionId: string;
}

interface FormProps {
  specifications: Record<string, Specification>;
  notes: string;
  sourceContactId: number;
  requestedAt: string;
}

interface ContextProps {
  serviceName: string;
  vendorLocationCode: string;
  visitPlanName: string;
  binGroups: Record<string, BinGroup>;
  binTypes: Record<string, BinType>;
  onContractServiceCodes: string[];
}

interface RequestSpecChangeFormProps {
  form: FormProps;
  context: ContextProps;
  errors: any;
  onSetForm: (newForm: any) => void;
}

var RequestSpecChangeForm: React.FC<RequestSpecChangeFormProps> = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const [anchorElem, setAnchorElem] = useState(null);
  const { specifications } = form;

  const { serviceName, onContractServiceCodes } = context;

  const { binTypes, binGroups } = useBinFetch();

  const availableSpecs = _.pick(
    binTypes,
    _.difference(onContractServiceCodes, _.map(specifications, "serviceCode"))
  );

  const updateQuantity = (quantity, serviceCode) => {
    const newSpecs = d.merge(specifications, sanatizeDotProp(serviceCode), {
      quantity,
    });
    specifications;
    onSetForm({ specifications: newSpecs });
  };

  const updateVisitActionId = (visitActionId, serviceCode) => {
    const newSpecs = d.merge(specifications, sanatizeDotProp(serviceCode), {
      visitActionId,
    });
    specifications;
    onSetForm({ specifications: newSpecs });
  };

  const updateSpecsHandler = (serviceCode) => {
    const newSpecs = d.merge(specifications, sanatizeDotProp(serviceCode), {
      serviceCode,
      notInContract: false,
      quantity: 0,
      visit_action_id: "collect_and_replace",
    });
    onSetForm({ specifications: newSpecs });
  };

  const removeSpec = (serviceCode) => {
    const newSpecs = _.omit(specifications, serviceCode);
    onSetForm({ specifications: newSpecs });
  };

  const specRows = _.map(
    specifications,
    ({ serviceCode, quantity, notInContract, visitActionId }) => {
      const {
        name = null,
        groupName = null,
        possibleVisitActions = null,
        binGroupId = null,
      } = binTypes[serviceCode] ?? {};

      const bg = binGroups[binGroupId];

      const notInContractTooltipId = notInContract
        ? `notInContractTooltip-${serviceCode}`
        : null;

      let notInContractTooltip;

      if (notInContractTooltipId) {
        notInContractTooltip = (
          <>
            <ReactTooltip
              id={notInContractTooltipId}
              type="dark"
              place={"left"}
              effect="solid"
            >
              <p>
                {name} - {groupName} is not under contract <br />
                for this account. ({serviceCode})
              </p>
              <p>
                You cannot request the vendor collects this unless <br />
                it is added to the vendor service on the account
              </p>
            </ReactTooltip>
            <i
              className={`text-danger fa fa-warning`}
              data-tip
              data-for={notInContractTooltipId}
            />
          </>
        );
      }

      return (
        <TableRow key={serviceCode}>
          <TableCell scope="row">
            <BinDot
              binGroup={bg}
              tooltipName={true}
              showImg
              {...binTypes[serviceCode]}
            />
          </TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{serviceCode}</TableCell>
          <TableCell>
            {visitActionId != "do_not_collect" ? (
              <QuantitySelector
                quantity={quantity}
                onChange={(q) => updateQuantity(q, serviceCode)}
              />
            ) : (
              "-"
            )}
          </TableCell>
          <TableCell style={{ width: "35%" }}>
            <VisitActionSelector
              defaultValue="collect_and_replace"
              visitActionId={visitActionId}
              possibleVisitActions={possibleVisitActions}
              disabledCollect={notInContract}
              onChange={(vaId) => updateVisitActionId(vaId, serviceCode)}
            />
          </TableCell>
          <TableCell sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              sx={{
                m: 0,
                p: 0,
              }}
            >
              <RemoveIcon
                onClick={() => removeSpec(serviceCode)}
                sx={{
                  fontSize: "35px",
                  color: "red",
                }}
              />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    }
  );



const title = (
    <span>
      <strong>Request Bin Spec Change</strong> &nbsp; <TextMuted> {serviceName}</TextMuted>
    </span>
  )

  const body = (
    <>
        <HeaderDetails
          onClick={(e) => setAnchorElem(e.currentTarget)}
          noOptions={_.toArray(availableSpecs).length === 0}
        />
        <TableContainer
          sx={{
            marginY: 1,
            "& .MuiTableCell-root": {
              fontSize: "11px",
              fontWeight: "inherit",
              color: "inherit",
            },
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  textWrap: "nowrap",
                  fontWeight: "bold",
                  color: "#828B98",
                  border: "1px solid transparent",
                }}
              >
                <TableCell>Type</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Visit Action</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  borderTop: "1px solid #F1F1F5",
                  borderBottom: "1px solid #F1F1F5",
                  padding: 0,
                },
              }}
            >
              {specRows}
            </TableBody>
          </Table>
        </TableContainer>

      <BinTypeSelectorPopover
        anchorEl={anchorElem}
        setAnchorEl={setAnchorElem}
        options={_.toArray(availableSpecs)}
        addBinHandler={updateSpecsHandler}
      />
    </>
  )

  return (
   <ActionFormLayout title={title} body={body} form={form} onSetForm={onSetForm} errors={errors}/>
  )

  // return (
  //   <div>
  //     <Stack
  //       direction="row"
  //       justifyContent="flex-start"
  //       alignItems="center"
  //       spacing={3}
  //     >
  //       <Typography variant="h5" sx={{ fontWeight: "bold" }}>
  //         Request Bin Spec Change
  //       </Typography>
  //       <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
  //         / {serviceName}
  //       </Typography>
  //     </Stack>
  //     <Box sx={{ my: 2 }}>
  //       <HeaderDetails
  //         onClick={(e) => setAnchorElem(e.currentTarget)}
  //         noOptions={_.toArray(availableSpecs).length === 0}
  //       />
  //       <TableContainer
  //         sx={{
  //           marginY: 1,
  //           "& .MuiTableCell-root": {
  //             fontSize: "11px",
  //             fontWeight: "inherit",
  //             color: "inherit",
  //           },
  //         }}
  //       >
  //         <Table size="small">
  //           <TableHead>
  //             <TableRow
  //               sx={{
  //                 textWrap: "nowrap",
  //                 fontWeight: "bold",
  //                 color: "#828B98",
  //                 border: "1px solid transparent",
  //               }}
  //             >
  //               <TableCell>Type</TableCell>
  //               <TableCell>Name</TableCell>
  //               <TableCell>Code</TableCell>
  //               <TableCell>Quantity</TableCell>
  //               <TableCell>Visit Action</TableCell>
  //               <TableCell></TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody
  //             sx={{
  //               "& .MuiTableCell-root": {
  //                 borderTop: "1px solid #F1F1F5",
  //                 borderBottom: "1px solid #F1F1F5",
  //                 padding: 0,
  //               },
  //             }}
  //           >
  //             {specRows}
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //     </Box>

  //     <NotesSection
  //       form={form}
  //       onSetForm={onSetForm}
  //       context={context}
  //       contactPlaceholder="Who requested the change?"
  //       contactLabel="Requested By"
  //       dateLabel="Requested on"
  //       dateValue={form?.requestedAt}
  //       onChange={(newValue) =>
  //         onSetForm({
  //           requestedAt: newValue.format("DD/MM/YYYY"),
  //         })
  //       }
  //     />
  //     <BinTypeSelectorPopover
  //       anchorEl={anchorElem}
  //       setAnchorEl={setAnchorElem}
  //       options={_.toArray(availableSpecs)}
  //       addBinHandler={updateSpecsHandler}
  //     />
  //     <DebugButton specifications={specifications} />
  //     <ModelErrors errors={errors} />
  //   </div>
  // );
};

export default RequestSpecChangeForm;
