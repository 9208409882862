import { Controller } from "stimulus"

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default class extends Controller {
  initialize() {

    var chartData = $(this.element).data('chart-data')
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(this.element, am4charts.XYChart);

    chart.data = chartData['data'];
    chart.logo.disabled = true;

    // Create axes
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      //dateAxis.renderer.minGridDistance = 60;
      //dateAxis.startLocation = 0;
      //dateAxis.endLocation = 1;
      //dateAxis.baseInterval = {
        //timeUnit: "month",
        //count: 1
      //}

    // First value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Registered";

    // Second value axis
    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.title.text = "Total Count";
    valueAxis2.renderer.opposite = true;

    // First series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "registered";
    series.dataFields.dateX = "date";
    series.name = "Daily Registered";
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.fill = "#90bf5c"
    series.strokeWidth = 0;


    // Second series
    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "cumulative";
    series2.dataFields.dateX = "date";
    series2.name = "Cumulative Total";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";
    series2.strokeWidth = 1;
    series2.yAxis = valueAxis2;
    series2.tensionX = 0.77;
    series2.stroke = "#3f95cd"

    //var circleBullet = series2.bullets.push(new am4charts.CircleBullet());
    //circleBullet.circle.stroke = am4core.color("#3f95cd");
    //circleBullet.fill = am4core.color("white");
    //circleBullet.circle.strokeWidth = 2;

    // Add legend
chart.legend = new am4charts.Legend();
chart.legend.position = "bottom";
chart.legend.fontSize = 11
chart.legend.color = "#414141"


const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();


  }
}














