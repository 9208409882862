import { orderBy } from "lodash";
import ReportCard from "./ReportCard";

const ReportsCards = ({ service }) => {
  const { latestVisitFailureReports: reports } = service;

  return (
    <>
      {orderBy(reports, ["reportedAt", "desc"]).map((report, key) => (
        <ReportCard report={report} key={key} />
      ))}
    </>
  );
};

export default ReportsCards;
