import { alpha, Button, styled } from "@mui/material";
import { useGetLocationServiceVendorsQuery } from "api/services";
import _ from "lodash";
import { NestedDropdown } from "mui-nested-menu";
import { useMemo } from "react";
import { getLocationId, useTicket } from "ticket/selectors";

const ACTION_COLOR = "#4FC1E9";

export const CustomOutlinedButton = styled(Button)(({}) => ({
  border: "1px solid",
  borderColor: ACTION_COLOR,
  color: ACTION_COLOR,
  fontSize: "12px",
  "&:hover": {
    borderColor: ACTION_COLOR,
    backgroundColor: alpha(ACTION_COLOR, 0.1),
    color: ACTION_COLOR,
  },
}));

const ActionMenuButton = ({
  serviceActions,
  onClickHandler,
  icon,
  isFooterMenu = false,
}) => {
  const ticket = useTicket();
  const locationId = getLocationId();
  const vendorId = ticket?.vendor?.id;
  const { data: locationServiceVendors } =
    useGetLocationServiceVendorsQuery(locationId);

  const currentServices = useMemo(
    () => locationServiceVendors?.[vendorId]?.services || [],
    [locationServiceVendors, vendorId]
  );

  const transformMenuItem = (item) => ({
    ...item,
    callback: (event) => onClickHandler(event, item),
  });

  const groupActionsByUUID = (actions) => {
    return _.groupBy(actions, "service_uuid");
  };

  const groupActionsByGroupAndLabel = (actions) => {
    return _.groupBy(actions, (action) => action.group || "noGroup");
  };

  const createMenuStructure = (actions) => {
    const groupedActions = groupActionsByUUID(actions);

  if (currentServices?.length > 1) {
      return Object.entries(groupedActions).map(([uuid, actions]) => {
        const currentService = currentServices.find((x) => x.uuid === uuid);
        const groupedByGroupAndLabel = groupActionsByGroupAndLabel(actions);
        const items = Object.entries(groupedByGroupAndLabel).map(
          ([groupName, groupActions]) => {
            if (groupName === "noGroup") {
              return groupActions.map(transformMenuItem);
            } else {
              return {
                label: groupName,
                items: groupActions.map(transformMenuItem),
              };
            }
          }
        );
        return {
          label: `${currentService?.shortName}`,
          items: _.flattenDeep(items),
        };
      });
    } else {
      // Single service case
      const groupedByGroupAndLabel = groupActionsByGroupAndLabel(serviceActions);

      return _.flattenDeep(
        Object.entries(groupedByGroupAndLabel).map(
          ([groupName, groupActions]) => {
            if (groupName === "noGroup") {
              return groupActions.map(transformMenuItem);
            } else {
              return {
                label: groupName,
                items: groupActions.map(transformMenuItem),
              };
            }
          }
        )
      );
    }
  };

  const menuItemsData = {
    label: "",
    items: createMenuStructure(serviceActions),
  };

  const footerStlye = {
    border: "1px solid #bfbfbf",
    px: 0
  };

  const replyStyle = {
    border: "none",
    borderRadius: 0,
    borderRight: "1px solid rgba(255, 255, 255, 0.2)",
    p: "3px",
    "&:hover": {
      border: "none",
      borderRight: "1px solid rgba(255, 255, 255, 0.2)",
    },
  };

  if (serviceActions?.length === 0) {
    return <div style={{ minWidth: "34px" }}></div>;
  }
  return (
    <NestedDropdown
      menuItemsData={menuItemsData}
      MenuProps={{
        elevation: 3,
      }}
      ButtonProps={{
        variant: "outlined",
        size: "small",
        startIcon: icon,
        sx: {
          color: "#bfbfbf",
          fontSize: "12px",
          minWidth: "34px",
          "& .MuiButton-startIcon": {
            m: 0,
            p: 0,
          },
          "& .MuiButton-endIcon": {
            m: 0,
            p: 0,
            display: "inherit",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "18px",
          },
          "&:hover": {
            backgroundColor: alpha("#bfbfbf", 0.1),
            color: "#bfbfbf",
          },
          ...(isFooterMenu ? footerStlye : replyStyle),
        },
      }}
    />
  );
};

export default ActionMenuButton;
