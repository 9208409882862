import { Box, Link, IconButton } from "@mui/material";
import { VendorAccount } from "./ServiceMappingInfo";
import { useTheme } from '@mui/material/styles';

interface VendorAccountLinkProps {
  vendorAccount: VendorAccount;
  vendorAccountClosedMonthName: string;
}

const VendorAccountLink: React.FC<VendorAccountLinkProps> = ({
  vendorAccount,
  vendorAccountClosedMonthName,
}) => {

  const theme = useTheme();


  if (vendorAccount?.closed) {
    return (
      <>
        <Link
          href={`/admin/vendor_accounts/${vendorAccount?.id}`}
          target="_blank"
          underline="none"
          sx={{ color: "#3BAFDA", fontSize: "12px" }}
        >
          {vendorAccount?.nameWithoutClosed}{" "}
        </Link>
        &nbsp;
        <IconButton
          href={`/admin/vendor_services/${vendorAccount?.vendorServiceId}`}
          target="_blank"
          size="small"
          sx={{ color: theme.palette.grey[400] }}
        >
          <i className="fa fa-puzzle-piece" aria-hidden="true"></i>
        </IconButton>

        -{" "}
        <span className="text-danger">
          <strong>Closed</strong> {vendorAccountClosedMonthName}
        </span>
        &nbsp;
      </>
    );
  }

  if (vendorAccount) {
    return (
      <>
        <Link
          href={`/admin/vendor_accounts/${vendorAccount?.id}`}
          target="_blank"
          underline="none"
          sx={{ color: "#3BAFDA", fontSize: "12px" }}
        >
          {vendorAccount?.name}
        </Link>
        &nbsp;
        <IconButton
          href={`/admin/vendor_services/${vendorAccount?.vendorServiceId}`}
          target="_blank"
          size="small"
          sx={{ color: theme.palette.grey[400] }}
        >
          <i className="fa fa-puzzle-piece" aria-hidden="true"></i>
        </IconButton>
      </>
    );
  } else {
    return (
      <Box sx={{ color: "#828B98", fontSize: '12px', fontWeight: 'bold', pt: 1 }}>
        Undefined Account&nbsp;
        <i className="fa fa-puzzle-piece" aria-hidden="true"></i>
      </Box>

    )
  }


};

export default VendorAccountLink;
