import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FolderIcon from "@mui/icons-material/Folder";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PendingIcon from "@mui/icons-material/Pending";
import TagIcon from "@mui/icons-material/Tag";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  Alert, Box, Card, CardContent, Stack, Tooltip, Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { capitalize, filter, findLast, lowerCase, startCase } from "lodash";
import React from "react";
import { formatDate } from "sharedUtils";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

const RemediationCaseTab = ({ remediationCase }) => {
  if (!remediationCase || Object.keys(remediationCase).length === 0) {
    return (
      <Alert severity="warning" sx={{ p: 2, fontSize: "14px" }}>
        No requests to show
      </Alert>
    );
  }
  const openedOnDate = dayjs(remediationCase?.openedOn).startOf("day");
  const today = dayjs().startOf("day");
  let daysOpen = today.diff(openedOnDate, "day");
  daysOpen = Math.max(daysOpen, 0);

  const lastReattemptLogEntry = findLast(remediationCase.allLogEntries, {
    dataType: "Reattempt date confirmed",
  });
  const storedReattemptDate = lastReattemptLogEntry
    ? lastReattemptLogEntry.data.reattemptDate
    : undefined;

  const filteredLogEntries = filter(remediationCase.allLogEntries, (entry) =>
    /failed|failure|reattempt/.test(lowerCase(entry.dataType)),
  );

  return (
    <Box sx={{ p: 1, pt: 0 }} key={remediationCase.id}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          backgroundColor: "#402943",
          color: "#fff",
          "& .MuiCardContent-root:last-child": {
            pb: 1,
          },
        }}
      >
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Case {capitalize(remediationCase?.status)}{" "}
              <span style={{ color: "#DD7DFF" }}>
                on {formatDate(remediationCase?.openedOn)}
              </span>
            </Typography>
          </Box>

          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              alignItems: "start",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", gap: "30px" }}>
              <Tooltip
                placement="top"
                title={
                  <>
                    <div>Case Opened Date</div>{" "}
                    <div>{formatDate(remediationCase?.openedOn)}</div>
                  </>
                }
                arrow
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextSnippetOutlinedIcon />
                  <span> Open for {daysOpen} days</span>
                </Box>
              </Tooltip>
              <Tooltip
                placement="top"
                title="Number of Failed Visit Report"
                arrow
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TagIcon /> <span>{remediationCase?.failureReportCount}</span>
                </Box>
              </Tooltip>
              <Tooltip
                placement="top"
                title="Date of First Failed Visit Report"
                arrow
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarTodayIcon />
                  <span> {formatDate(remediationCase?.firstFailedOn)}</span>
                </Box>
              </Tooltip>
              <Tooltip
                placement="top"
                title="Number of Notifications Send"
                arrow
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <NotificationsActiveIcon />
                  <span> {remediationCase?.notificationsSent}</span>
                </Box>
              </Tooltip>
            </Box>
            <Box sx={{ display: "flex", gap: "30px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {storedReattemptDate && (
                  <>
                    <EventAvailableIcon />
                    {formatDate(storedReattemptDate)}
                  </>
                )}
                {!storedReattemptDate && (
                  <>
                    <PendingIcon />
                    <span>No reattempt date</span>
                  </>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FolderIcon />
                <span>{` ${remediationCase.casesInPastMonthsCount} cases in last 6 months`}</span>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Timeline
        sx={{
          color: "#fff",
          fontSize: "12px",
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItem sx={{ minHeight: 0, alignItems: "center" }}>
          <TimelineDot
            sx={{ color: "#8C3F65", backgroundColor: "#8C3F65", m: 0 }}
          >
            <ExitToAppIcon sx={{ color: "#fff" }} />
          </TimelineDot>
          <TimelineContent sx={{ fontSize: "12px" }}>
            Case opened
          </TimelineContent>
        </TimelineItem>
        {filteredLogEntries?.map((logEntry) => <LogCard log={logEntry} />)}
        <TimelineItem sx={{ minHeight: "15px", my: "8px", pl: "10px" }}>
          <TimelineSeparator>
            <TimelineConnector sx={{ background: "#8C3F65" }} />
          </TimelineSeparator>
        </TimelineItem>
        <TimelineItem sx={{ minHeight: 0, alignItems: "center" }}>
          <TimelineDot
            sx={{ color: "#8C3F65", backgroundColor: "#8C3F65", m: 0 }}
          >
            <ExitToAppIcon
              sx={{ color: "#fff", transform: "rotate(180deg)" }}
            />
          </TimelineDot>
          <TimelineContent sx={{ fontSize: "12px" }}>
            Case closed
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
};

export default RemediationCaseTab;

const LogCard = ({ log }) => {
  const isReattemptConfirmed = log.dataType === "Reattempt date confirmed";

  const messagePrefix = isReattemptConfirmed
    ? "Reattempt on "
    : "Visit failed on ";

  const dateToFormat = isReattemptConfirmed
    ? log.data.reattemptDate
    : log.data.failureDate;

  const textColor = isReattemptConfirmed ? "#D678F6" : "#e9573f";

  const feedbackTitle = (dataType: string) => {
    if (dataType === "Vendor feedback on visit failure recieved")
      return "Vendo Feedback Received";
    if (dataType === "Client feedback on visit failure recieved")
      return "Client Feedback Received";

    return dataType;
  };
  return (
    <React.Fragment key={log.medatada?.requestId}>
      <TimelineItem sx={{ minHeight: "15px", mt: "8px", pl: "10px" }}>
        <TimelineSeparator>
          <TimelineConnector sx={{ background: "#8C3F65" }} />
        </TimelineSeparator>
      </TimelineItem>
      <TimelineItem sx={{ mt: 1 }}>
        <Card
          sx={{
            width: "100%",
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        >
          <CardContent>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Typography>{startCase(feedbackTitle(log?.dataType))}</Typography>
              <Typography color="grey">
                on {formatDate(log.data.reportedAt)}
              </Typography>
            </Stack>
            <Typography color="grey">
              {messagePrefix}
              <span style={{ color: textColor }}>
                {formatDate(dateToFormat)}
              </span>
            </Typography>
          </CardContent>
        </Card>
      </TimelineItem>
    </React.Fragment>
  );
};
