import "./styles.scss";
import { DebugButton } from "service/GlobalDebugger";
import PropTypes from "prop-types";
import Debugger from "./Debugger";
import { ModelErrors } from "sharedComponents";
import { Box, Stack, Typography } from "@mui/material";
import NotesSection from "./common/NotesSection";
import { formatDate } from "sharedUtils";
import ActionFormLayout from './common/ActionFormLayout';
import { TextMuted } from 'sharedComponents';

const CancelSuspensionForm = ({ form, context, errors, onSetForm }) => {
  const { serviceName, requestedAt } = context;


  const title = (
    <span>
      <strong>Cancel Suspension Request</strong> &nbsp; <TextMuted> {serviceName}</TextMuted>
    </span>
  )

  const body = (
    <>
      <li>
        <strong>Vendor was requested on{" "}{formatDate(requestedAt)}{" "}to suspend service</strong>
      </li>
    </>
  )

  return (
   <ActionFormLayout title={title} body={body} form={form} onSetForm={onSetForm} errors={errors}/>
  )

  // return (
  //   <div>
  //     <Stack
  //       direction="row"
  //       justifyContent="flex-start"
  //       alignItems="center"
  //       spacing={2}
  //     >
  //       <Typography variant="h5" sx={{ fontWeight: "bold" }}>
  //         Cancel Suspension Request
  //       </Typography>
  //       <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
  //         {serviceName}
  //       </Typography>
  //     </Stack>
  //     <Box sx={{ marginTop: 1 }}>
  //       <li>
  //         <strong>Vendor was requested on{" "}{formatDate(requestedAt)}{" "}to suspend service</strong>
  //       </li>
  //     </Box>
  //     <NotesSection
  //       form={form}
  //       onSetForm={onSetForm}
  //       context={context}
  //       contactLabel="Canceled By"
  //       contactPlaceholder="Who requested the cancellation?"
  //       dateLabel="Cancelled on"
  //       dateValue={form?.cancelledAt}
  //       onChange={(newValue) =>
  //         onSetForm({
  //           cancelledAt: newValue.format("DD/MM/YYYY"),
  //         })
  //       }
  //     />
  //     <DebugButton {...form} />
  //     <ModelErrors errors={errors} />
  //   </div>
  // );
};

CancelSuspensionForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    cancelledAt: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    noVisitsExpectedAfter: PropTypes.string,
    serviceName: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};
export default CancelSuspensionForm;
