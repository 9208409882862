import React from "react";
import _ from "lodashExtended";
import { Box, Typography, Tooltip, Chip } from "@mui/material";
import { FrequencyChip } from 'sharedComponents';
import { useTheme } from '@mui/material/styles';
import CheckIcon from "@mui/icons-material/Check";
import OverflowTooltip from "service/forms/common/OverflowTooltip";

export const VisitFrequencyRow = ({ service }) => {
  return renderSwitch(service);
};

function renderSwitch(service) {

  const theme = useTheme();

  switch (service.visitFrequencyStatus) {
    case "frequencies_aligned":
      return (
        <Tooltip title={"The Vendor's Frequency and Anenta's Frequency Align"} >
          <Chip label={service.requestedVisitPlanName} size="small" color="success" sx={{fontWeight: 'bold' }}/>
        </Tooltip>
      );
    case "frequencies_not_aligned":
      return (
        <>
          <FrequencyChip
            anentaLabel={service.requestedVisitPlanName}
            anentaColor="success"
            vendorLabel={service.vendorVisitPlanName}
            vendorColor="warning"
          />
        </>
      );
    case "vendor_frequency_only":
      return (
        <>
          <FrequencyChip
            anentaLabel="Not Set"
            anentaColor="error"
            vendorLabel={service.vendorVisitPlanName}
            vendorColor="success"
          />
        </>
      );
    case "vendor_frequency_unknown":
      return (
        <>
          <FrequencyChip
            anentaLabel={service.requestedVisitPlanName}
            anentaColor="success"
            vendorLabel="Unknown"
            vendorColor="error"
          />
        </>
      );
    case "jointly_unspecified_frequency":
      return (
        <>
          <FrequencyChip
            anentaLabel="Not Set"
            anentaColor="error"
            vendorLabel="Unknown"
            vendorColor="warning"
          />
        </>
      );
    default:
      return null;
  }
}
