import { Typography } from "@mui/material";
import { startCase } from "lodash";
import { formatDate } from "sharedUtils";
import ReportLogLayout from './ReportLogLayout';

const ServiceResumptionRequestLog = ({ request }) => {
  const { cancelledAt, date, visitsExpectedAfter } = request.data || {};

  const visitsExpectedAfterText = visitsExpectedAfter
    ? `Effective From: ${formatDate(visitsExpectedAfter)}`
    : "";


  const logHeader = (
    <>
      {date ? `${startCase(request.name)} for ${formatDate(date)}`
        : startCase(request.name)
      }
    </>
  );

  const logContent = (
    <>
      <Typography>
        Vendor has been requested to resume service
      </Typography>

      {visitsExpectedAfter && (
        <Typography>{visitsExpectedAfterText}</Typography>
      )}
    </>
  );

  return (
    <ReportLogLayout logHeader={logHeader} logContent={logContent} object={request} />
  );

};

export default ServiceResumptionRequestLog;
