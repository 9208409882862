import { Controller } from "stimulus"

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default class extends Controller {
  initialize() {

    var chartData = $(this.element).data('chart-data')
    am4core.useTheme(am4themes_animated);
    const chart = am4core.create(this.element, am4charts.XYChart);
    this.chart = chart

    chart.data = chartData['data'];
    const series_data = chartData['series_data'];

    chart.logo.disabled = true;

    chart.dateFormatter.inputDateFormat = "MMM yyyy";
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 60;
      dateAxis.startLocation = 0;
      dateAxis.endLocation = 1;
      dateAxis.baseInterval = {
        timeUnit: "month",
        count: 1
      }



    dateAxis.tooltipDateFormat = "MMM yyyy";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.title.text = chartData['yAxixTitle'];

// Function to create series
    const createSeries = (name, color, tooltip, chart) => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.dateX = "month";
      series.dataFields.valueY = name;
      series.width = am4core.percent(100);
      // series.tooltipText = "[bold]{valueY}[/] x {name}";
      series.tooltipText = tooltip;
      series.columns.template.fill = color;
      series.strokeWidth = 0;
      series.stacked = true;
      series.tooltip.fontSize = 12;
      // debugger
      // chart.scrollbarX.series.push(series);
    };
    // Create all service code series
    series_data.forEach((set) => {
      createSeries(set['name'], set['color'], set['tooltip'], chart);
    });


    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.fontSize = 11
    chart.legend.color = "#414141"


    const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;

  }

  disconnect() {
    // Dispose of the chart when the controller is disconnected
    if (this.chart) {
      this.chart.dispose();
    }
  }

}
