import { createContext } from "react";

interface AppContextProps {
  onSelectAction?: (x: any, y?: any) => void;
  selectedContainer?: any;
  setSelectedContainer?: (x: any) => void;
  formType?: string;
  setFormType?: (x: string) => void;
  cardTab?: string;
  setCardTab?: (x: string) => void;
  showTaggingForm?: boolean;
  setShowTaggingForm?: (x: boolean) => void;
  highlightedIds?: any[];
  setHighlightedIds?: (x: any) => void;
  currentTicketId?: string;
  requestedAtDate?: string;
  setRequestedAtDate?: (x: string) => void;
  eventId?: string;
  setEventId?: (x: string) => void;
  requestType?: string;
  setRequestType: (x: string) => void;
  isDrawerOpen?: boolean;
  closeDrawer?: () => void;
  activeChannel?: string;
  setIsDrawerOpen?: (x: boolean) => void;
  setActiveChannel?: (x: string) => void;
  toggleDrawerAndSetChannel?: (x: any) => void;
  onSelectDate?: (x: any, y?: any) => void;
  openRemediation?: boolean;
  setOpenRemediation?: (x: any) => void;
  onOpenRemediation?: any;
  onCloseRemediation?: any;
}

const AppContext = createContext<AppContextProps>({
  onSelectAction: (_x, {}) => {},
  selectedContainer: undefined,
  setSelectedContainer: (_x) => {},
  formType: "all",
  setFormType: (_x) => {},
  cardTab: "request",
  setCardTab: (_x) => {},
  showTaggingForm: false,
  setShowTaggingForm: (_x) => {},
  highlightedIds: [],
  setHighlightedIds: (_x) => {},
  currentTicketId: "",
  requestedAtDate: "",
  setRequestedAtDate: (_x) => {},
  eventId: "",
  setEventId: (_x) => {},
  requestType: "",
  setRequestType: (_x) => {},
  isDrawerOpen: false,
  closeDrawer: () => {},
  activeChannel: "",
  setIsDrawerOpen: (_x) => {},
  setActiveChannel: (_x) => {},
  toggleDrawerAndSetChannel: (_x) => {},
  onSelectDate: (_x, _y) => {},
});

export default AppContext;
