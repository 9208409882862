import BinDot from "service/BinDot";
import { HeaderDetails, QuantitySelector } from "./QuantitySelector";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";

const DeliveryUnitsTable = ({
  headerDetailsHandler,
  deliveryUnits,
  binTypes,
  binGroups,
  updateDeliveryQuantity,
  removeUnit,
  availableDeliveryOptions,
}) => {
  const noOptions = availableDeliveryOptions.length === 0;
  const deliveryRows = _.map(deliveryUnits, ({ serviceCode, quantity }) => {
    const binTypeInfo = binTypes[serviceCode] || {};
    const { name = "", binGroupId, imageUrl } = binTypeInfo;
    const binGroup = binGroups[binGroupId];

    return (
      <TableRow key={serviceCode}>
        <TableCell scope="row">
          <BinDot binGroup={binGroup} tooltipName={true} />
        </TableCell>
        <TableCell>
          {imageUrl && (
            <img
              src={imageUrl}
              alt={name}
              style={{ marginRight: "10px", width: "30px", height: "30px" }}
            />
          )}
          {name}
        </TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell style={{ width: "10%" }}>
          <QuantitySelector
            quantity={quantity}
            onChange={(q) => updateDeliveryQuantity(q, serviceCode)}
          />
        </TableCell>
        <TableCell>
          <IconButton
            sx={{
              m: 0,
              p: 0,
            }}
          >
            <RemoveIcon
              fontSize="large"
              onClick={(_e) => removeUnit(serviceCode)}
              sx={{
                cursor: "pointer",
                fontSize: "35px",
                color: "red",
                display: "block",
                margin: "auto",
              }}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });
  return (
    <>
      <HeaderDetails onClick={headerDetailsHandler} noOptions={noOptions} />
      <TableContainer
        sx={{
          marginY: 1,
          "& .MuiTableCell-root": {
            fontSize: "11px",
            fontWeight: "inherit",
            color: "inherit",
          },
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                textWrap: "nowrap",
                fontWeight: "bold",
                color: "#828B98",
                border: "1px solid transparent",
              }}
            >
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Deliver Additional Units</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                borderTop: "1px solid #F1F1F5",
                borderBottom: "1px solid #F1F1F5",
                padding: 0,
              },
            }}
          >
            {deliveryRows}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DeliveryUnitsTable;
