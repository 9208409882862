import { alpha } from "@material-ui/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useAddTicketMutation, useGetLocationTicketsQuery } from "api/tickets";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { orderBy, startCase } from "lodash";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import TimeAgo from "react-timeago";
import { DebugButton } from "service/GlobalDebugger";
import { formatDate } from "sharedUtils";
import { useTypedSelector } from "ticket/app/store";
import AppContext from "ticket/AppContext";
import { getLocationId } from "ticket/selectors";

dayjs.extend(relativeTime);

const TicketsList = ({}) => {
  const history = useHistory();
  const locationId = getLocationId();
  const organisationId = useTypedSelector((state) => state.organisationId);
  const { data: tickets, isFetching: isFetchingTickets } =
    useGetLocationTicketsQuery(getLocationId());
  const [addTicket, { isLoading: isCreatingTicket }] = useAddTicketMutation();
  const { currentTicketId } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddTicket = async () => {
    await addTicket({
      locationId: locationId,
      organisationId: organisationId,
      subject: subject,
    });
    handleClose();
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleTicketChange = (_event, newValue) => {
    if (newValue) {
      history.replace(`/tickets/${newValue.id}`);
    }
  };

  return (
    <Box
      sx={{
        "*": {
          fontSize: "12px",
        },
      }}
    >
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddCircleOutlineIcon />}
        fullWidth
        sx={{
          background: "#4FC1E9",
          "&:hover": {
            backgroundColor: alpha("#4FC1E9", 0.8),
          },
        }}
      >
        NEW TICKET
      </Button>
      <DebugButton
        {...tickets}
        sx={{
          position: "absolute",
          left: 0,
        }}
      />
      {isFetchingTickets || isCreatingTicket ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "#E3E6E9",
          }}
        >
          <Skeleton variant="rectangular" width="100%" height={100} />
          <Skeleton variant="rectangular" width="100%" height={100} />
          <Skeleton variant="rectangular" width="100%" height={100} />
        </Box>
      ) : (
        <>
          {Object.values(orderBy(tickets, ["openedOn"], ["desc"])).map(
            (ticket) => {
              const isCurrentTicket = String(ticket.id) === currentTicketId;

              return (
                <Card
                  key={ticket.id}
                  sx={{
                    borderRadius: 0,
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "#E3E6E9",
                    },
                    "& .MuiCardContent-root:last-child": {
                      pb: 2,
                    },
                    ...(isCurrentTicket && {
                      backgroundColor: "#E3E6E9",
                    }),
                  }}
                  onClick={(e) => handleTicketChange(e, ticket)}
                >
                  <CardContent>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#454F5E",
                        }}
                      >
                        #{ticket.id}
                      </Typography>
                      <Box sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "baseline",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#454F5E",
                            }}
                          >
                            Opened by {ticket.openedByAssociationName}
                          </Typography>
                          <Typography sx={{ color: "#777", fontSize: "10px" }}>
                            {formatDate(ticket.openedOn)}
                          </Typography>
                        </Box>

                        <ClientStatus ticket={ticket} />

                        <VendorStatus ticket={ticket} />

                        {ticket.requests.length > 0 ||
                        ticket.failedVisitReports.length > 0 ? (
                          <>
                            {ticket.requests.map((r) => (
                              <Typography
                                key={r.id}
                                sx={{
                                  color: "#777",
                                  fontSize: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: "12px",
                                  },
                                }}
                              >
                                {r.title}
                                {icon[r.status]}
                              </Typography>
                            ))}
                            {ticket.failedVisitReports.map((r) => (
                              <Typography
                                key={r.id}
                                sx={{
                                  color: "#777",
                                  fontSize: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: "12px",
                                  },
                                }}
                              >
                                Failed Visit Report
                                {icon["cancelled"]}
                              </Typography>
                            ))}
                          </>
                        ) : (
                          <Typography sx={{ color: "#777", fontSize: "10px" }}>
                            {ticket.subject}
                          </Typography>
                        )}

                        {}
                      </Box>

                      <Box>
                        {ticket.new && (
                          <Chip
                            label="New"
                            color="success"
                            size="small"
                            sx={{ mr: 1 }}
                          />
                        )}
                        <TimeChip date={ticket.openedOn} />
                        <Typography
                          sx={{
                            textAlign: "end",
                            fontSize: "12px",
                            color: ticket?.assignedAgent?.name
                              ? "orange"
                              : undefined,
                          }}
                        >
                          {ticket?.assignedAgent?.name
                            ? `${ticket.assignedAgent.name}`
                            : "Not Assigned"}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              );
            }
          )}
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ "*": { fontSize: "12px" } }}
      >
        <DialogTitle sx={{ background: "#3BAFDA", color: "#fff" }}>
          New Ticket
        </DialogTitle>
        <DialogContent sx={{ p: 2, pt: "16px !important" }}>
          <Typography sx={{ fontSize: "12px", mb: 1 }}>
            Type of Ticket
          </Typography>
          <RadioGroup value={subject} onChange={handleSubjectChange}>
            {subjects.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio size="small" sx={{ p: 0 }} />}
                label={option}
                sx={{
                  ml: 2,
                  "& .MuiFormControlLabel-label": { fontSize: "12px" },
                }}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%", borderTop: "1px solid #ddd" }}
          >
            <Button
              type="submit"
              variant="contained"
              onClick={handleAddTicket}
              color="success"
              sx={{ fontSize: "12px", width: "200px" }}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              color="info"
              onClick={handleClose}
              sx={{ fontSize: "12px", width: "200px" }}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TicketsList;

const icon = {
  confirmed: <CheckIcon sx={{ color: "green" }} />,
  awaiting_response: <HourglassTopIcon sx={{ color: "orange" }} />,
  cancelled: <CloseIcon sx={{ color: "red" }} />,
};

const TimeChip = ({ date }) => {
  const now = dayjs();
  const requestDate = dayjs(date);
  const diffInHours = now.diff(requestDate, "h");
  const green = diffInHours < 2;
  const yellow = diffInHours >= 2 && diffInHours < 10;

  return (
    <Chip
      label={<TimeAgo date={date} />}
      sx={{
        fontSize: "11px",
        background: green ? "#8CC152" : yellow ? "#DD9907" : "#CF2D42",
        color: "#fff",
      }}
      size="small"
    />
  );
};

const subjects = [
  "NHSE Emergency Collection",
  "Missed Collection",
  "Rescheduled Collection",
  "Ad-hoc Collection",
  "Stock Delivery",
  "Failed Like-for-like Replacements",
  "Service Issue",
  "Service Amendment",
  "General Question",
  "Documentation Required",
  "Pre-Acceptance Audit Required",
  "Direct Waste Order",
  "Product Order",
  "Stop Service",
];

const statusColor = {
  new_message: "#CF2D42",
  awaiting_response: "#CF2D42",
  no_response_required: "#8CC152",
};

const VendorStatus = ({ ticket }) => {
  return (
    <Typography
      sx={{
        fontSize: "12px",
        color: "#454F5E",
      }}
    >
      {ticket?.vendor?.name ? startCase(ticket.vendor.name) : "Vendor"}{" "}
      <Typography
        component={"span"}
        sx={{
          fontSize: "12px",
          color: statusColor[ticket.vendorStatus],
        }}
      >
        {ticket.vendorStatus === "inactive"
          ? "Not Included"
          : startCase(ticket.vendorStatus)}
      </Typography>{" "}
      {ticket?.lastVendorReplyAt &&
        `on ${formatDate(ticket.lastVendorReplyAt)}`}
    </Typography>
  );
};

const ClientStatus = ({ ticket }) => {
  return (
    <Typography
      sx={{
        fontSize: "12px",
        color: "#454F5E",
      }}
    >
      Client{" "}
      <Typography
        component={"span"}
        sx={{
          fontSize: "12px",
          color: statusColor[ticket.organisationStatus],
        }}
      >
        {startCase(ticket.organisationStatus)}
      </Typography>{" "}
      {ticket?.lastOrganisationReplyAt &&
        `on ${formatDate(ticket.lastOrganisationReplyAt)}`}
    </Typography>
  );
};
