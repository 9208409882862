import React from "react";
import { Tooltip } from "@mui/material";
import PartialCollectionTooltip from "./PartialCollectionTooltip";
import { startCase } from "lodash";
import { colorForSlug } from "./ReportVisitFailureCard";
import { FailedVisitReport } from "service/ticket/types";

interface ReportVisitFailureCardTableProps {
  report: FailedVisitReport;
}

const ReportVisitFailureCardTable: React.FC<
  ReportVisitFailureCardTableProps
> = ({ report }) => {
  const { feedback, visitReport } = report;

  const getFailureReason = (type: "collection" | "delivery") => {
    const feedbackSlug = feedback?.[`${type}FailureReasonSlug`];
    const visitReportSlug = visitReport?.[`${type}ResultSlug`];
    return feedbackSlug || visitReportSlug;
  };

  const collectionFailureReason = getFailureReason("collection");
  const deliveryFailureReason = getFailureReason("delivery");

  const tableStyles = {
    width: "100%",
    fontWeight: "bold" as const,
    color: "#979797",
    fontSize: "12px",
    marginTop: "5px",
    tableLayout: "fixed" as const,
  };

  const ResultCell: React.FC<{ type: "collection" | "delivery" }> = ({
    type,
  }) => {
    const slug = visitReport?.[`${type}ResultSlug`];
    return (
      <td style={{ color: colorForSlug[slug || ""] }}>
        <Tooltip
          title={
            slug === "partial_failure" && type === "collection" ? (
              <PartialCollectionTooltip feedback={report?.visitReport} />
            ) : null
          }
          arrow
        >
          <div>
            {generateResultTooltipText(
              slug,
              type === "collection" ? "collected" : "delivered",
            )}
          </div>
        </Tooltip>
      </td>
    );
  };

  return (
    <table style={tableStyles}>
      <thead>
        <tr>
          <th></th>
          <th>Collection</th>
          <th>Delivery</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Feedback</td>
          <ResultCell type="collection" />
          <ResultCell type="delivery" />
        </tr>
        {(collectionFailureReason || deliveryFailureReason) && (
          <tr>
            <td>Reason for Failure</td>
            <td style={{ color: "#fff" }}>
              {startCase(collectionFailureReason)}
            </td>
            <td style={{ color: "#fff" }}>
              {startCase(deliveryFailureReason)}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ReportVisitFailureCardTable;

const generateResultTooltipText = (
  resultSlug: string | undefined,
  type: string,
): string => {
  switch (resultSlug) {
    case "total_failure":
      return `Nothing was ${type}`;
    case "partial_failure":
      return `Some was ${type}`;
    case "success":
      return `All stock was ${type}`;
    default:
      return "";
  }
};
