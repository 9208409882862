import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryIcon from '@mui/icons-material/History';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import { Collapse, Divider, IconButton, Link, Typography, Box, Stack, Grid, Chip, Tooltip } from "@mui/material";
import { TextSmallMuted, BoxVendorServiceArea, TextMuted } from 'sharedComponents';
import _, { first, map } from "lodash";
import { useState } from "react";
import { useBinFetch } from "sharedUtils";
import ServiceBoxContext from "ticket/ServiceBoxContext";
import BinDot from "./BinDot";
import { DebugButton } from "./GlobalDebugger";
import "./service-box.scss";
import { StatusRow } from "./service-box/StatusRow";
import VendorAccountLink from "./service-box/VendorAccountLink";
import { VisitFrequencyRow } from "./service-box/VisitFrequencyRow";
import VlcSection from "./service-box/VlcSection";
import { Specification } from "./Specification";
import { StreamIcon } from "./utils/Icons";
import { useTheme } from '@mui/material/styles';



const ServiceBox = ({
  service,
  openService,
  setOpenServiceMap: setOpenService,
  }) => {

  const theme = useTheme();

  const currentVendorLocationAccount: any = first(service?.serviceMappings);

  const specifications = service?.specifications;
  const billingHistoryUnitAverages = service?.billingHistoryUnitAverages;

  const [open, setOpen] = useState(true);

  const allCodes = _([
    _.map(specifications, "serviceCode"),
    _.map(billingHistoryUnitAverages, "code"),
  ]).flatten().uniq().value();

  const { binGroups } = useBinFetch(allCodes);

  const availableBinGroups = map(binGroups, (b, key) => {
    return <BinDot key={key} binGroup={b} tooltipName={true} tooltipPlace="top" />;
  });

  const noSpecs = Object.keys(specifications).length === 0;

  return (


   <ServiceBoxContext.Provider value={{ currentVendorLocationAccount, }} >

      <Box>

        <Box sx={{ borderBottom: `1px solid ${theme.palette.borderGrey}`, padding: '3px',}}>

          <Grid container spacing={0}>
            <Grid item xs  sx={{ alignItems: 'center' }}>

              <IconButton size="small" onClick={() => setOpenService(!openService)}>
                 {openService ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>

              <TextSmallMuted><strong>{service?.title}</strong></TextSmallMuted>

            </Grid>
            <Grid item xs sx={{ alignItems: 'center', textAlign: 'right' }}>

              {!openService && (
                <>
                  {allCodes.length > 0 && (
                    <Chip label={availableBinGroups} size="small" sx={{ paddingTop:'5px', backgroundColor: '#f5f5f5'}} />
                  )}
                  <Box component="span" sx={{ pl: 1 }}><StatusRow service={service}/></Box>
                </>
              )}


              <Box component="span" sx={{ pl: 1 }}>

                <DebugButton buttonSize="small" { ...service} />

                {service?.streamUrl && (
                  <IconButton size="small" onClick={() => window.open(service?.streamUrl, "_blank")}>
                    <StreamIcon/>
                  </IconButton>
                )}

              </Box>


            </Grid>
          </Grid>

        </Box>


        <Collapse in={openService} timeout={0} unmountOnExit>

          <Box sx={{ borderBottom: `1px solid ${theme.palette.grey[200]}`, p: '6px', pl: '10px' }} >
            <VlcSection service={service} />
          </Box>


          <Stack direction="row" sx={{borderBottom: `1px solid ${theme.palette.grey[200]}`, p: '6px', pl: '10px'}}>

            <Box sx={{ flex: 1, justifyContent: 'left',  textAlign: 'left', alignItems: 'center'}}>
              <TextSmallMuted sx={{mr: 1}}>Freq.</TextSmallMuted>
              <VisitFrequencyRow service={service} />
            </Box>

            <Box sx={{ flex: 1, justifyContent: 'right', textAlign: 'right', alignItems: 'center'}}>

              <TextSmallMuted sx={{mr: 1}}>
                {noSpecs && "Undefined"} Specs
              </TextSmallMuted>

              {allCodes.length > 0 && (
                <Chip label={availableBinGroups} size="small" sx={{ mr: 1, paddingTop:'5px', backgroundColor: '#f5f5f5'}} />
              )}


              <TextSmallMuted sx={{mr: 1}}>Status</TextSmallMuted>
              <StatusRow service={service} />

              <IconButton size="small" sx={{ ml: 1 }}>
                <Tooltip title="Should open chart" >
                  <HistoryIcon/>
                </Tooltip>
              </IconButton>

            </Box>
          </Stack>

          <Box sx={{ borderBottom: `1px solid ${theme.palette.grey[200]}`, py: '6px', px: '0px' }} >
            <Specification {...service}/>
          </Box>
        </Collapse>

      </Box>


    </ServiceBoxContext.Provider>



  );
};

export default ServiceBox;




