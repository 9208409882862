import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import UnknownIcon from "@mui/icons-material/Remove";
import BinDot from "service/BinDot";
import {
  Box, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StatusChip } from "./SttatusChip";

const BinReportTable = ({
  inUseBinGroups,
  onSetForm,
  failureReasonSlug,
  failureReasons,
  mode,
  isClient = false,
  initialStatus,
  initialBinGroupStatus,
}: {
  inUseBinGroups: any[];
  onSetForm: any;
  failureReasonSlug?: string;
  failureReasons?: any[];
  mode: "collection" | "delivery";
  isClient?: boolean;
  initialStatus?: string;
  initialBinGroupStatus?: Record<string, boolean | null>;
}) => {
  const [status, setStatus] = useState(initialStatus || "total_failure");
  const [checkedStates, setCheckedStates] = useState(() => {
    if (mode === "collection") {
      if (initialBinGroupStatus) {
        return initialBinGroupStatus;
      }
      return Object.fromEntries(inUseBinGroups.map((bg) => [bg.id, false]));
    }
    return {};
  });

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);

    if (mode === "collection") {
      let newCheckedStates;
      if (newStatus === "total_failure") {
        newCheckedStates = Object.fromEntries(
          inUseBinGroups.map((bg) => [bg.id, false])
        );
      } else if (newStatus === "success") {
        newCheckedStates = Object.fromEntries(
          inUseBinGroups.map((bg) => [bg.id, true])
        );
      } else {
        newCheckedStates = Object.fromEntries(
          inUseBinGroups.map((bg) => [bg.id, null])
        );
      }
      setCheckedStates(newCheckedStates);
      // Ready for binGroupDeliveryStatus
      // onSetForm({ [`binGroup${startCase(mode)}Status`]: newCheckedStates });
      onSetForm({ binGroupCollectionStatus: newCheckedStates });
    }

    onSetForm({ [`${mode}ResultSlug`]: newStatus });
  };

  const handleCheckboxChange = (binGroupId) => {
    if (mode === "delivery") return;
    setCheckedStates((prev) => {
      const currentState = prev[binGroupId];
      let newState;

      if (currentState === null) {
        newState = true;
      } else if (currentState === true) {
        newState = false;
      } else {
        newState = null;
      }

      const updatedState = {
        ...prev,
        [binGroupId]: newState,
      };

      onSetForm({ binGroupCollectionStatus: updatedState });
      return updatedState;
    });

    setStatus("partial_failure");
  };

  const updateStatus = (states) => {
    const values = Object.values(states);
    const allTrue = values.every((val) => val === true);
    const allFalse = values.every((val) => val === false);

    if (allTrue) {
      setStatus("success");
    } else if (allFalse) {
      setStatus("total_failure");
    } else {
      setStatus("partial_failure");
    }
    onSetForm({
      [`${mode}ResultSlug`]: status,
    });
  };

  useEffect(() => {
    if (mode === "delivery") return;
    updateStatus(checkedStates);
  }, [checkedStates]);

  return (
    <Box >
      <StatusChips
        status={status}
        handleStatusChange={handleStatusChange}
        failureReasonSlug={failureReasonSlug}
        failureReasons={failureReasons}
        onSetForm={onSetForm}
        mode={mode}
        isClient={isClient}
      />
      {mode === "collection" && (
        <TableContainer>
          <Table
            size="small"
            sx={{
              width: "100%",
              "& .MuiTableCell-root": {
                padding: 0,
                px: 1,
                fontSize: "11px",
              },
            }}
          >
            <TableBody sx={{ borderTop: "1px solid #F1F1F5",}}>
              {inUseBinGroups.map((bg) => (
                <CollectedRow
                  key={bg.id}
                  binGroup={bg}
                  isChecked={checkedStates[bg.id]}
                  onCheckboxChange={() => handleCheckboxChange(bg.id)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default BinReportTable;

const StatusChips = ({
  status,
  handleStatusChange,
  failureReasonSlug,
  failureReasons,
  onSetForm,
  mode,
  isClient,
}) => {
  return (

      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
        <Typography >
          What was {mode === "collection" ? "collected" : "delivered"}?
        </Typography>
        <StatusChip
          label="Nothing"
          status="total_failure"
          currentStatus={status}
          onClick={() => handleStatusChange("total_failure")}
        />
        <StatusChip
          label="Partial"
          status="partial_failure"
          currentStatus={status}
          onClick={() => handleStatusChange("partial_failure")}
        />
        <StatusChip
          label="All"
          status="success"
          currentStatus={status}
          onClick={() => handleStatusChange("success")}
        />
        {!isClient && (
          <Select
            size="small"
            displayEmpty
            value={failureReasonSlug}
            onChange={(event) => {
              onSetForm({
                [`${mode}FailureReasonSlug`]: event.target.value,
              });
            }}
          >
            <MenuItem value={null} disabled>
              Reason for failure
            </MenuItem>
            {failureReasons.map((reason) => (
              <MenuItem
                key={reason.slug}
                value={reason.slug}
                sx={{ fontSize: "12px" }}
              >
                {reason.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
  );
};

const CollectedRow = ({ binGroup, isChecked, onCheckboxChange }) => {

  const getIconColor = () => {
    if (isChecked === true) return "success.main";
    if (isChecked === false) return "error.main";
    return "action.disabled";
  };

  const getIcon = () => {

    const iconSx = { color: getIconColor(), fontSize: '18px', }

    if (isChecked === true) return (
      <CheckIcon sx={{...iconSx}}/>
    );
    if (isChecked === false) return (
      <CloseIcon sx={{...iconSx}}/>
    );
    return (
      <UnknownIcon sx={{...iconSx}}/>
    );
  };



  return (
    <TableRow onClick={onCheckboxChange}
      sx={{
        cursor: 'pointer',
        borderLeft: "1px solid #F1F1F5",
        borderRight: "1px solid #F1F1F5",
      }}
    >
      <TableCell
        align="center"
        sx={{
          minHeight: '20px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: "1px solid #F1F1F5",
          borderRight: "1px solid #F1F1F5",
        }}
      >
        {getIcon()}
      </TableCell>
      <TableCell
        sx={{
          borderBottom: "1px solid #F1F1F5",
          borderRight: "1px solid #F1F1F5",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <BinDot binGroup={binGroup} tooltipName={true} tooltipPlace="top" />
          <Typography noWrap>{binGroup.name}</Typography>
        </Box>
      </TableCell>

      <TableCell sx={{ borderBottom: "1px solid #F1F1F5" }}>
        <Typography noWrap>{binGroup?.binTypes?.join(", ")}</Typography>
      </TableCell>
    </TableRow>
  );
};
