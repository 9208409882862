import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Tooltip, Typography } from "@mui/material";
import TimeAgo from "react-timeago";
import { formatDate } from "sharedUtils";
import { TicketReply } from "../../../api/tickets";
import { Channel } from "../types";
import { getBorderColor, styles } from "./ReplyCardStyles";
import { TextSmallMuted, TextSmall, TextMuted } from 'sharedComponents';

const ReplyCardHeader = ({ reply }: { reply: TicketReply }) => {
  const { sentByName, channel } = reply;

  return (
    <>
      <Box
        sx={{
          color: channel === Channel.Agent ? '#272B31' : '',
          backgroundColor: channel === Channel.Agent ? getBorderColor(reply.stakeholderName) : '',
          borderBottom: channel === Channel.Agent ? '' : '1px solid #3A3E43',
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "6px 6px",
          "& *": {
            fontSize: "11px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >

          <Tooltip
            title={formatDate(reply.createdAt)}
            placement="top"
            arrow
            slotProps={{
              tooltip: { style: styles.tooltipStyles.tooltip },
              arrow: { style: styles.tooltipStyles.arrow },
            }}
          >

              <TimeAgo date={reply.createdAt} />
              {reply?.sentToName && ` | Sent to ${reply?.sentToName}`}

          </Tooltip>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >

            Sent by {sentByName}

            {reply.stakeholderName}

        </Box>
      </Box>
      {reply?.reminder && (
        <TextSmall
          sx={{
            color: "#37BC9B",
            p: '6px',
            borderBottom: "1px solid #3A3E43",
          }}
        >
          Reminder set for {formatDate(reply.reminder)}
        </TextSmall>
      )}
    </>
  );
};

export default ReplyCardHeader;
