import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Collapse, IconButton, Grid, Box, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RequestsCards from "./report-request-box/RequestsCards";
import FailedVisitsCollapsible from "./service-box/FailedVisitsCollapsible";
import OutstandingRequestsCollapsible from "./service-box/OutstandingRequestsCollapsible";
import VendorHeader from "./VendorHeader";
import ServiceBox from "./ServiceBox";
import { useTheme } from '@mui/material/styles';

const LocationServicesVendorBox = ({
  vendor,
  services,
  calendar,
  initialOpenState,
}) => {
  const [openServiceMap, setOpenServiceMap] = useState({});
  const [openActions, setOpenActions] = useState(true);

  const theme = useTheme();

  useEffect(() => {
    const newOpenServiceMap = {};
    services?.forEach((service) => {
      if (
        service?.status === "terminated" ||
        service?.status === "account_closed"
      ) {
        newOpenServiceMap[service.id] = false;
      } else {
        newOpenServiceMap[service.id] = initialOpenState;
      }
    });
    setOpenServiceMap(newOpenServiceMap);
  }, [services, initialOpenState]);

  const handleOpenServiceChange = (serviceId, isOpen) => {
    setOpenServiceMap((prevState) => ({
      ...prevState,
      [serviceId]: isOpen,
    }));
  };

  return (
      <Box sx={{background: 'white', borderRadius: '4px' }}>

        <VendorHeader vendor={vendor} calendar={calendar} />

        {services?.map((service, key) => (
          <Box key={key}>
            <ServiceBox
              key={service.id}
              service={service}
              openService={openServiceMap[service.id]}
              setOpenServiceMap={(isOpen) =>
                handleOpenServiceChange(service.id, isOpen)
              }
            />

            <Box sx={{
              background: '#23272D',
              color: "#fff",
              borderRadius: '0 0 4px 4px',
              border: '1px solid #656D78',
              borderTop: 'none',
            }}>

            <Grid container>
              <Grid item xs={6} sx={{ borderRight: '1px solid #646D78' }}>

                {openServiceMap[service.id] && (
                  <Box
                >
                  <FailedVisitsCollapsible service={service} />
                </Box>
              )}

              </Grid>
              <Grid item xs={6} sx={{ borderLeft: '1px solid #646D78' }}>

                <Box onClick={() => setOpenActions(!openActions)}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minHeight: "40px",
                    px: '10px',
                    cursor: 'pointer',
                  }}
                >
                  <strong>Service Requests</strong>

                  {service?.requests.length > 0 && (
                    <IconButton disableRipple sx={{ color: "#fff" }} >
                      {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  )}

                </Box>

                {service?.requests?.length > 0 && (
                  <Collapse in={openActions} timeout="auto" unmountOnExit>
                    <RequestsCards requests={service?.requests} />
                  </Collapse>
                )}

              </Grid>
            </Grid>

            </Box>
          </Box>
        ))}
      </Box>
  );
};



export default LocationServicesVendorBox;
