import { Box, Button, Card, CardContent, Collapse, IconButton, Link, Stack, Typography } from "@mui/material";
import { Contact } from "api/tickets";
import { camelCase, flatMap, mapKeys } from "lodash";
import { useEffect, useState } from "react";
import PdfModal from "service/forms/common/PdfModal";
import { DebugButton } from "service/GlobalDebugger";
import { DownArrowIcon, UpArrowIcon } from "service/utils/Icons";
import { formatDate } from "sharedUtils";
import { getLocationId, useTicket } from "ticket/selectors";
import {
  useFormServiceActionMutation,
  useGetLocationRemediationQuery,
  useGetLocationServiceVendorQuery,
} from "api/services";

import ReportRequestCardLayout from "./ReportRequestCardLayout"
import { TypoHaveInfo, TypoNeedInfo } from 'sharedComponents';

const statusMessages = {
  awaitingResponse: { text: "Awaiting Formal Response", color: "#FFA600" },
  cancelled: { text: "Cancelled", color: "#19E53B" },
  vendorReplied: { text: "Pending Review", color: "#FFA600" },
  accepted: { text: "The remediation response is logged as a Valid PDF", color: "#19E53B" },
  rejected: { text: "Remediation review required for Adhoc Visit by Kim (contract manager)", color: "#FFA600" },
  open: { text: 'Open is a status on from the location home view', color: 'red' }
};

const RemediationCard = ({ isLog = false }) => {
  const { stakeholderContacts } = useTicket() || {};
  const ticket = useTicket();
  const [open, setOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const [submitForm] = useFormServiceActionMutation();
  const locationId = getLocationId();
  const remediationData = useGetLocationRemediationQuery(locationId, {skip: !locationId}).data?.remediation;
  const remediation = remediationData ? mapKeys(remediationData, (_, k) => camelCase(k)) : null;
  const [serviceUuid, setServiceUuid] = useState("");
  const { data: locationServiceVendor } = useGetLocationServiceVendorQuery({
    locationId: `${ticket?.location?.id}`,
    vendorId: ticket?.vendor?.id,
  }, { skip: !ticket?.location?.id || !ticket?.vendor?.id });

  useEffect(() => {
    if (!locationServiceVendor) return;
    setServiceUuid(locationServiceVendor[ticket?.vendor?.id].services[0].uuid);
  }, [locationServiceVendor]);

  const statusMessage = statusMessages[camelCase(remediation?.status)];
  const acceptRemediation = () => {
    const acceptUrl = `/admin/service_actions/${serviceUuid}/accept_remediation_response`;
    submitResponse(acceptUrl);
  };

  const rejectRemediation = () => {
    const rejectUrl = `/admin/service_actions/${serviceUuid}/reject_remediation_response`;
    submitResponse(rejectUrl);
  };

  const submitResponse = (url) => {
    submitForm({
      url: url,
      body: {
        tagged_reply_id: remediation.lastTaggedReplyId,
        form: {
          vendor_id: ticket?.vendor?.id,
          ticket_id: ticket?.id,
          location_uuid: ticket?.location?.uuid,
          service_uuid: serviceUuid,
          authority_id: ticket?.location?.authority_id,
        },
      },
    });
  };

  const title = remediation ? (
    <>
      Remediation
      <Typography
        component="span"
        sx={{
          color: statusMessage.color,
        }}
      >
        {` ${statusMessage.text}`}
      </Typography>
    </>
  ) : (<></>)

  const label = remediation ? (
    <>
      for <strong>{remediation.serviceActionReason}</strong>
    </>
  ) : (<></>)



  const body = remediation ? (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "11px", fontWeight: "400", color: "#828B98" }}>
          Date of contract failure{" "}
          <span style={{ color: "#BFBFBF" }}>{formatDate(remediation.dateOfContractFailure)}</span>
        </Typography>
        <Typography color="grey" sx={{ fontSize: "11px", color: "#5F6369" }}>
          Requested on <span style={{ color: "#828B98" }}>{formatDate(remediation?.requestedOn)}</span> by{" "}
          <span style={{ color: "#828B98" }}>{remediation.inputedByFullName || "No Agent"}</span>
        </Typography>
      </Box>
      <Typography sx={{ fontSize: "11px", color: "#828B98", mb: isLog ? 1 : 0 }}>
        {remediation.message}
      </Typography>

      {remediation?.status == "vendor_replied" && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 1 }}>
         <Button
           variant="contained"
           color="success"
           sx={{ textTransform: "none" }}
           onClick={() => acceptRemediation()}
         >
           Accept
         </Button>
         <Button
           variant="contained"
           color="error"
           sx={{ textTransform: "none" }}
           onClick={() => rejectRemediation()}
         >
           Reject
         </Button>
        </Box>
      )}
      <PdfModal open={openModal} onClose={() => setOpenModal(false)} pdfUrl={remediation.noticePdfUrl} />
    </>

  ) : (<></>)

  return remediation ? (
    <ReportRequestCardLayout title={title} links='' label={label} body={body} request={remediation} />
  ) : (<></>);


  // return remediation ? (
  //   <>
  //     <Box
  //       sx={{
  //         mx: isLog ? 0 : undefined,
  //         "& .MuiPaper-root": {
  //           borderRadius: "0px",
  //         },
  //       }}
  //       key={remediation.id}
  //       data-link-card={remediation.id}
  //     >
  //       <Card
  //         variant="outlined"
  //         sx={{
  //           background: "#1E1E1E",
  //           "& .MuiCardContent-root:last-child": {
  //             pb: 1,
  //           },
  //         }}
  //       >
  //         <CardContent
  //           sx={{
  //             pt: isLog ? 0 : 1,
  //           }}
  //         >
  //           <Stack
  //             direction="row"
  //             justifyContent="space-between"
  //             alignItems="center"
  //             spacing={1}
  //             sx={{
  //               ...(isLog
  //                 ? {
  //                     borderTop: "1px solid rgba(255, 255, 255, 0.2)",
  //                     borderBottom: open ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
  //                     margin: "0px -16px",
  //                     padding: "0px 16px",
  //                   }
  //                 : {}),
  //             }}
  //           >
  //             <Box>
  //               <Typography sx={{ fontSize: "13px", fontWeight: "700", color: "#BFBFBF" }}>
  //                 Remediation
  //                 <Typography
  //                   component="span"
  //                   sx={{
  //                     fontSize: "13px",
  //                     fontWeight: "700",
  //                     color: statusMessage.color,
  //                   }}
  //                 >
  //                   {` ${statusMessage.text}`}
  //                 </Typography>
  //                 <span style={{ color: "#828B98" }}> for</span> <strong>Adhoc Visit</strong>
  //               </Typography>
  //             </Box>
  //             <Box
  //               sx={{
  //                 "& .MuiSvgIcon-root": {
  //                   fontSize: "14px",
  //                   color: "#5F6369",
  //                 },
  //               }}
  //             >
  //               <Link
  //                 onClick={() => setOpenModal(true)}
  //                 underline="none"
  //                 sx={{
  //                   cursor: "pointer",
  //                 }}
  //               >
  //                 Open Notice
  //               </Link>
  //               <IconButton onClick={() => setOpen(!open)}>{open ? <UpArrowIcon /> : <DownArrowIcon />}</IconButton>

  //               <DebugButton {...remediation} />
  //             </Box>
  //           </Stack>
  //           <Box
  //             sx={{
  //               mx: -2,
  //               my: "3px",
  //               borderBottom: "1px solid #5F6369",
  //             }}
  //           />

  //           <Collapse in={open} timeout="auto" unmountOnExit>
  //             <Box
  //               sx={{
  //                 display: "flex",
  //                 justifyContent: "space-between",
  //                 alignItems: "center",
  //                 mt: isLog ? 1 : 0,
  //               }}
  //             >
  //               <Typography sx={{ fontSize: "11px", fontWeight: "400", color: "#828B98" }}>
  //                 Date of contract failure{" "}
  //                 <span style={{ color: "#BFBFBF" }}>{formatDate(remediation.dateOfContractFailure)}</span>
  //               </Typography>
  //               <Typography color="grey" sx={{ fontSize: "11px", color: "#5F6369" }}>
  //                 Requested on <span style={{ color: "#828B98" }}>{formatDate(remediation?.requestedOn)}</span> by{" "}
  //                 <span style={{ color: "#828B98" }}>{remediation.inputedByFullName || "No Agent"}</span>
  //               </Typography>
  //             </Box>
  //             <Typography sx={{ fontSize: "11px", color: "#828B98", mb: isLog ? 1 : 0 }}>
  //               {remediation.message}
  //             </Typography>
  //             {remediation?.status == "vendor_replied" && (
  //               <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 1 }}>
  //                 <Button
  //                   variant="contained"
  //                   color="success"
  //                   sx={{ textTransform: "none" }}
  //                   onClick={() => acceptRemediation()}
  //                 >
  //                   Accept
  //                 </Button>
  //                 <Button
  //                   variant="contained"
  //                   color="error"
  //                   sx={{ textTransform: "none" }}
  //                   onClick={() => rejectRemediation()}
  //                 >
  //                   Reject
  //                 </Button>
  //               </Box>
  //             )}
  //           </Collapse>
  //         </CardContent>
  //       </Card>
  //     </Box>
  //     <PdfModal open={openModal} onClose={() => setOpenModal(false)} pdfUrl={remediation.noticePdfUrl} />
  //   </>
  // ) : (
  //   <></>
  // );
};

export default RemediationCard;
