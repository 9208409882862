import CheckIcon from '@mui/icons-material/Beenhere';
import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";
import { formatDate } from "sharedUtils";
import ReportLogLayout from './ReportLogLayout';

const StyledIcon = ({ onClick }) => (
  <Icon
    component={MenuIcon}
    sx={{
      padding: "8px",
      borderRadius: "50%",
      cursor: "pointer",
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      '&:active': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
      transition: 'background-color 0.3s',
    }}
    onClick={onClick}
  />
);


const ReattemptDateRequestLog = ({ request }) => {

  // ReattemptVisitRequested

  const logHeader = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CheckIcon color="success" fontSize="small" />
      <Box component="span" sx={{ marginLeft: '6px' }}>
        {startCase(request.name)}
      </Box>
    </Box>
  );

  const logContent = (
    <>

    </>
  );

  return (
    <ReportLogLayout logHeader={logHeader} logContent={logContent} object={request} />
  );



};

export default ReattemptDateRequestLog;
