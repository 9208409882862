/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_packs_with_chunks_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// console.log('Hello World from Webpacker')

import "./awesome-font-config";
import "jquery";
import "@babel/polyfill";
import "bootstrap";
import "chart.js";
import "select2";
import linkifyJq from "linkify-jquery";

import datePicker from "pc-bootstrap4-datetimepicker";
const moment = require("moment");

import "../stylesheets/application.scss";

import "@stimulus/polyfills";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));

import PDFObject from "pdfobject";
window.PDFObject = PDFObject;

import swal from "sweetalert2";
window.swal = swal;

const $ = require("jquery");
window.$ = $;
window.jQuery = $;
linkifyJq($, document);

$(document).ready(function () {
  // javascript hackery of checkbox and radio classes to get around simple forms inadequacies
  $(".collection_check_boxes, .collection_radio_buttons").addClass(
    "custom-control-label",
  );
  $("label.checkbox .custom-control-input.boolean").each(
    function (i, checkbox) {
      var $checkbox = $(checkbox);
      var $label = $checkbox.closest("label");
      $label.after($checkbox);
      $label.remove();
    },
  );
  $(".form-group.radio_buttons").removeClass("radio_buttons");
  $(".radio.custom-control").removeClass("radio");
  $(".custom-control-input.radio_buttons")
    .removeClass("radio_buttons")
    .removeClass("form-control");
  $(".collection_radio_buttons.custom-control-label").removeClass(
    "collection_radio_buttons",
  );
  // end -- simple form hackery

  // file attachment set text after selecting file
  $(".file-attachment-holder").on("change", ".custom-file-input", function () {
    var fileName = $(this).val();
    $(this).next(".custom-file-label").html(fileName);
  });

  // Rendering links as <a> in /o/tickets/68123
  $(".ticket-reply-body").each(function (el) {
    $(this).linkify({
      defaultProtocol: "https",
    });
  });

  $('[data-toggle="tooltip"]').tooltip();

  // $.fn.select2.defaults.set( "theme", "bootstrap4" );
  $("select")
    .not(".standard-input")
    .select2({ width: "resolve", minimumResultsForSearch: 20 });

  $('input[type="file"]').on("change", 'input[type="file"]', function (e) {
    var fileName = e.target.files[0].name;
    $(e).html(fileName);
  });

  $(".datetime-holder").not(".standard-input").datetimepicker({
    format: "Do MMMM YYYY",
    defaultDate: moment(),
  });

  $(".datetime-holder")
    .not(".standard-input")
    .datetimepicker()
    .on("dp.change", function () {
      var hidden = $(this).next("input");
      var picker = $(this).data("DateTimePicker");

      var date = picker.date();

      var clear = $(this)
        .closest(".datetime-holder")
        .find(".input-group-append.clear");

      if (clear) {
        if (date) {
          hidden.val(date.format());
          clear.css({ display: "table-cell" });
        } else {
          hidden.val("");
          clear.css({ display: "none" });
        }
      }
    })
    .on("dp.hide", function (e) {
      e.target.blur();
    })
    .find(".input-group-append.clear")
    .on("click", function (e) {
      e.stopPropagation();
      $(this).closest(".datetime-holder").data("DateTimePicker").hide().clear();
    });

  $(" .datetime-holder")
    .find("input")
    .on("click", function (e) {
      $(this).closest(".datetime-holder").data("DateTimePicker").toggle();
    });

  $(" [data-date-open-calender-on]").click(function () {
    var $input = $(this);
    var picker = $input.data("DateTimePicker");
    var currentDate = picker.date();
    if (currentDate) {
      return null;
    } else {
      var calendarDate = moment($input.data("date-open-calender-on"));
      if (!calendarDate.isValid()) {
        calendarDate = $($input.data("date-open-calender-on"))
          .data("DateTimePicker")
          .date();
      }
      picker.date(calendarDate);
      picker.clear();
    }
  });

  //radio with other select input
  //
  $(".radio-with-other").change(function () {
    if ($(this).val() == "Other") {
      $(".radio-with-other")
        .parents(".form-group")
        .find("input.radio-with-other-text-option")
        .show();
      $(".radio-with-other")
        .parents(".form-group")
        .find("input.radio-with-other-text-option")
        .prop("disabled", false);
    } else {
      $(".radio-with-other")
        .parents(".form-group")
        .find("input.radio-with-other-text-option")
        .hide();
      $(".radio-with-other")
        .parents(".form-group")
        .find("input.radio-with-other-text-option")
        .prop("disabled", true);
    }
  });
});

// Support component names relative to this directory:
console.log("application react_ujs");
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

//ReactRailsUJS.start()
