import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { Contact } from "api/tickets";
import { flatMap, startCase } from "lodash";
import { useState } from "react";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";
import ReportLogLayout from './ReportLogLayout';

const VisitFrequencyChangeRequestLog = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const { date, notes } = request.data || {};

  const allStakeholders: Contact[] = flatMap(stakeholderContacts || [], (x) => x );
  const inputtedBy = allStakeholders?.find((x) => x.id === request?.metadata?.inputtedById);

  // TODO: Handle accept spec change when implemented
  // const isConfirmed = false;


  const logHeader = (
    <>
      {date ? `${startCase(request.name)} for ${formatDate(date)}`
            : startCase(request.name)
      }
    </>
  );

  const logContent = (
    <>

    </>
  );

  return (
    <ReportLogLayout logHeader={logHeader} logContent={logContent} object={request} />
  );

};

export default VisitFrequencyChangeRequestLog;
