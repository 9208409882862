import { Box, Stack, Typography } from "@mui/material";
import { ModelErrors } from "sharedComponents";
import "./styles.scss";
import NotesSection from "./common/NotesSection";
import ActionFormLayout from './common/ActionFormLayout';
import { TextMuted } from 'sharedComponents';

const CancelRemediationForm = ({
  form,
  context,
  errors,
  onSetForm }) => {
  const { serviceName } = context;

 const title = (
    <span>
      <strong>Cancel Remediation</strong> &nbsp; <TextMuted> {serviceName}</TextMuted>
    </span>
  )


  const body = (

    <li><strong>Vendor was sent a remediation notice</strong></li>
  )

  return (
   <ActionFormLayout title={title} body={body} form={form} onSetForm={onSetForm} errors={errors}/>
  )


  // return (
  //   <div>
  //     <Stack
  //       direction="row"
  //       justifyContent="flex-start"
  //       alignItems="center"
  //       spacing={2}
  //     >
  //       {" "}

  //     </Stack>
  //     <Box sx={{ marginTop: 1 }}>
  //       <li><strong>Vendor was sent a remediation notice</strong></li>
  //     </Box>
  //     <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
  //       <NotesSection
  //         form={form}
  //         onSetForm={onSetForm}
  //         context={context}
  //         contactLabel="Cancelled By"
  //         contactPlaceholder="Who requested the cancellation?"
  //         dateLabel="Cancelled on"
  //         dateValue={form?.cancelledAt}
  //         onChange={(newValue) =>
  //           onSetForm({
  //             cancelledAt: newValue.format("DD/MM/YYYY"),
  //           })
  //         }
  //       />
  //       <ModelErrors errors={errors} />
  //     </Box>
  //   </div>
  // );
};


export default CancelRemediationForm;
