import React from 'react';
import { Typography, Box, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';





const TypoNeedInfo = ({ children, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Typography component="span" {...props} sx={{ ...sx, color: theme.palette.needInfo }}>
      {children}
    </Typography>
  );
};


const TypoHaveInfo = ({ children, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Typography component="span" {...props} sx={{ ...sx, color: theme.palette.haveInfo }}>
      {children}
    </Typography>
  );
};



const TypoSuccess = ({ children, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Typography component="span" {...props} sx={{ ...sx, color: theme.palette.success.main }}>
      {children}
    </Typography>
  );
};

const TypoError = ({ children, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Typography component="span" {...props} sx={{ ...sx, color: theme.palette.error.main }}>
      {children}
    </Typography>
  );
};


const TypoWarning = ({ children, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Typography component="span" {...props} sx={{ ...sx, color: theme.palette.warning.main }}>
      {children}
    </Typography>
  );
};







const TextSmall = ({ children, sx, ...props }) => {
  return (
    <Box
      component="span"
      sx={{
        typography: 'small',
        ...sx, // Merge additional styles
      }}
      {...props} // Spread additional props like onClick, etc.
    >
      {children}
    </Box>
  );
};

const TextMuted = ({ children, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      component="span"
      sx={{
        color: theme.palette.grey[400],
        ...sx, // Merge additional styles
      }}
      {...props} // Spread additional props like onClick, etc.
    >
      {children}
    </Box>
  );
};

const TextSmallMuted = ({ children, sx, ...props }) => {
  return (
    <TextSmall
      component="span"
      sx={{
        color: 'textMuted',
        ...sx, // Merge additional styles
      }}
      {...props} // Spread additional props like onClick, etc.
    >
      {children}
    </TextSmall>
  );
};



const BoxVendorServiceArea = ({ children, sx, ...props }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: '0 8px',
        borderBottom: `1px solid ${theme.palette.borderGrey}`, // Correctly referencing theme color
        ...sx, // Merge additional styles
      }}
      {...props} // Spread additional props like onClick, etc.
    >
      {children}
    </Box>
  );
};


const ChipGroup = ({ leftLabel = 'left', rightLabel = 'right', leftColor = 'default', rightColor = 'success', boxProps }) => {
  return (
    <>
      <Box display="inline-flex" sx={{ borderRadius: '16px', overflow: 'hidden', ...boxProps }}>
        <Chip
          label={leftLabel}
          color={leftColor}
          size="small"
          sx={{ borderRadius: '16px 0 0 16px', color: leftColor === 'default' ? '#777777' : undefined }}
        />
        <Chip
          label={rightLabel}
          color={rightColor}
          size="small"
          sx={{ borderRadius: '0 16px 16px 0' }}
        />
      </Box>
    </>
  );
};



const FrequencyChip = ({ anentaColor = 'default', anentaLabel = 'Anenta', vendorColor = 'default', vendorLabel = 'Vendor' }) => {
  return (
    <>
    <ChipGroup
      leftLabel="Anenta"
      rightLabel={anentaLabel}
      rightColor={anentaColor}
    />
    <ChipGroup
      leftLabel="Vendor"
      rightLabel={vendorLabel}
      rightColor={vendorColor}
      boxProps={{ ml:1 }}
    />
    </>
  );
};



// Export all custom Box components
export { TypoSuccess, TypoWarning, TypoError, TypoHaveInfo, TypoNeedInfo, TextSmall, TextSmallMuted, TextMuted, BoxVendorServiceArea, FrequencyChip };
