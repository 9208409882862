import { Controller } from "stimulus"

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default class extends Controller {
  initialize() {

    am4core.useTheme(am4themes_animated);

    var chart2 = am4core.create(this.element, am4charts.XYChart);
    chart2.logo.disabled = true;

    chart2.data = $("#authority-tickets-overdue-data").data('chart-data')

    chart2.padding(40, 40, 40, 40);

    var categoryAxis2 = chart2.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis2.renderer.grid.template.location = 0;
    categoryAxis2.dataFields.category = "age";
    categoryAxis2.renderer.minGridDistance = 1;
    categoryAxis2.renderer.inversed = true;
    categoryAxis2.renderer.grid.template.disabled = true;

    var valueAxis2 = chart2.xAxes.push(new am4charts.ValueAxis());
    valueAxis2.min = 0;

    var series2 = chart2.series.push(new am4charts.ColumnSeries());
    series2.dataFields.categoryY = "age";
    series2.dataFields.valueX = "number";
    series2.tooltipText = "{valueX.value}"
    series2.columns.template.strokeOpacity = 0;
    series2.columns.template.column.cornerRadiusBottomRight = 5;
    series2.columns.template.column.cornerRadiusTopRight = 5;

    var labelBullet2 = series2.bullets.push(new am4charts.LabelBullet())
    labelBullet2.label.horizontalCenter = "left";
    labelBullet2.label.dx = 10;
    labelBullet2.label.text = "{values.valueX.workingValue}";
    labelBullet2.locationX = 1;

chart2.legend = new am4charts.Legend();
chart2.legend.position = "bottom";
chart2.legend.fontSize = 11
chart2.legend.color = "#414141"


const markerTemplate = chart2.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;

  }
}
