import { Box } from "@mui/material";
import { orderBy } from "lodash";
import AdhocVisitRequestCard from "./AdhocVisitRequestCard";
import RemediationCard from "./RemediationCard";
import ServiceTerminationRequestCard from "./ServiceTerminationRequestCard";
import ReattemptVisitCard from "./ReattemptVisitCard";
import StockDeliveryRequestCard from "./StockDeliveryCard";

const requestComponents = {
  Adhoc: AdhocVisitRequestCard,
  // Termination: ServiceTerminationRequestCard,
  Reattempt: ReattemptVisitCard,
  Stock: StockDeliveryRequestCard,
};

const RequestCard = ({ request }) => {
  const getRequestComponent = (requestType) => {
    const componentKey = Object.keys(requestComponents).find((key) =>
      requestType?.includes(key),
    );
    return componentKey ? requestComponents[componentKey] : null;
  };


  const SpecificRequestComponent = getRequestComponent(request?.type);

  if (!SpecificRequestComponent) return null;

  return (

      <SpecificRequestComponent request={request} />

  );
};


const RequestsCards = ({ requests }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#262b31",
      }}
    >
      {orderBy(requests, ["requestedAt"], ["desc"]).map((request, index) => (
        <RequestCard key={request.id || index} request={request} />
      ))}

      <RemediationCard />

    </Box>
  );
};

export default RequestsCards;
