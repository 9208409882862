import { Typography, Chip } from "@mui/material";
import OverflowTooltip from "service/forms/common/OverflowTooltip";
import { formatDate } from "sharedUtils";
import { humanize } from "./utils";
import _ from 'lodashExtended'

export const StatusRow = ({ service }) => {
  const serviceStatus = service?.status;

    const date = service?.restartDate || service?.stopDate;

    const label = (serviceStatus !== "running" && _.isPresent(date))
      ? `${humanize(serviceStatus)} on ${formatDate(date)}`
      : humanize(serviceStatus);


    return (
            <Chip size="small"
                sx={{ color: getColor(service?.status) }}
                label={label}
            />
    );
};

const getColor = (status) => {
  const colors = {
    running: "green",
    terminated: "red",
    suspended: "red",
    suspending: "orange",
    terminating: "orange",
    resuming: "orange",
    reinstating: "orange"
  };
  return colors[status] || "inherit";
};
