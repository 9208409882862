import ConfirmNextRoutineVisitForm from "./ConfirmNextRoutineVisitForm";
import ConfirmFinalCollectionForm from "./ConfirmFinalCollectionForm";
import RequestVisitFrequencyChangeForm from "./RequestVisitFrequencyChangeForm";
import AcceptVisitFrequencyChangeForm from "./AcceptVisitFrequencyChangeForm";
import ConfirmVisitFrequencyChangeForm from "./ConfirmVisitFrequencyChangeForm";
import CancelVisitFrequencyChangeForm from "./CancelVisitFrequencyChangeForm";
import RequestTerminationForm from "./RequestTerminationForm";
import CancelTerminationForm from "./CancelTerminationForm";
import ConfirmTerminationForm from "./ConfirmTerminationForm";
import RequestResumptionForm from "./RequestResumptionForm";
import CancelResumptionForm from "./CancelResumptionForm";
import ConfirmResumptionForm from "./ConfirmResumptionForm";
import RequestReinstatementForm from "./RequestReinstatementForm";
import CancelReinstatementForm from "./CancelReinstatementForm";
import ConfirmReinstatementForm from "./ConfirmReinstatementForm";
import RequestSpecChangeForm from "./RequestSpecChangeForm";
import CancelSpecChangeForm from "./CancelSpecChangeForm";
import RequestSuspensionForm from "./RequestSuspensionForm";
import CancelSuspensionForm from "./CancelSuspensionForm";
import ConfirmSuspensionForm from "./ConfirmSuspensionForm";
import RequestAdhocVisitForm from "./RequestAdhocVisitForm";
import RequestStockDeliveryForm from "./RequestStockDeliveryForm";
import CancelStockDeliveryForm from "./CancelStockDeliveryForm";
import ConfirmStockDeliveryForm from "./ConfirmStockDeliveryForm";
import CancelAdhocVisitForm from "./CancelAdhocVisitForm";
import ConfirmAdhocVisitForm from "./ConfirmAdhocVisitForm";
import ClientFeedbackOnFailedVisitReportForm from "./ClientFeedbackOnFailedVisitReportForm";
import VendorFeedbackOnFailedVisitReportForm from "./VendorFeedbackOnFailedVisitReportForm";
import ConfirmVisitFailureReattemptDateForm from "./ConfirmVisitFailureReattemptDateForm";
import RequestReattemptDateForm from "./RequestReattemptDateForm";
import VendorReportVisitFailureResponseForm from "./VendorReportVisitFailureResponseForm";
import VendorReportVisitFailureTagForm from "./VendorReportVisitFailureTagForm";
import ClientReportVisitFailureForm from "./ClientReportVisitFailureForm";
import CancelRemediationForm from "./CancelRemediationForm";
import LogRemediationReplyForm from "./LogRemediationReplyForm";
import NoActionRequiredForm from "./NoActionRequiredForm";

const types = {
  ConfirmNextRoutineVisitForm,
  ConfirmFinalCollectionForm,
  RequestVisitFrequencyChangeForm,
  AcceptVisitFrequencyChangeForm,
  ConfirmVisitFrequencyChangeForm,
  CancelVisitFrequencyChangeForm,
  RequestTerminationForm,
  CancelTerminationForm,
  ConfirmTerminationForm,
  RequestResumptionForm,
  CancelResumptionForm,
  ConfirmResumptionForm,
  RequestReinstatementForm,
  CancelReinstatementForm,
  ConfirmReinstatementForm,
  RequestSuspensionForm,
  CancelSuspensionForm,
  ConfirmSuspensionForm,
  RequestAdhocVisitForm,
  RequestSpecChangeForm,
  CancelSpecChangeForm,
  RequestStockDeliveryForm,
  CancelStockDeliveryForm,
  ConfirmStockDeliveryForm,
  CancelAdhocVisitForm,
  ConfirmAdhocVisitForm,
  ClientFeedbackOnFailedVisitReportForm,
  VendorFeedbackOnFailedVisitReportForm,
  ConfirmVisitFailureReattemptDateForm,
  RequestReattemptDateForm,
  VendorReportVisitFailureResponseForm,
  VendorReportVisitFailureTagForm,
  ClientReportVisitFailureForm,
  CancelRemediationForm,
  LogRemediationReplyForm,
  NoActionRequiredForm,
};

export default (type) => {
  const component = types[type];

  if (component) {
    return component;
  } else {
    throw (
      'missing form component for "' +
      type +
      '", perhaps you forgot to add it to forms/index.js?'
    );
  }
};
