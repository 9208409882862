import { useState } from "react";
import { DebugButton } from "service/GlobalDebugger";
import "./styles.scss";
import Select from "react-select";
import Debugger from "./Debugger";
import { sanatizeDotProp, useBinFetch } from "sharedUtils";
import { Stack, Typography } from "@mui/material";
import { toArray } from "lodash";
import { BinTypeSelectorPopover } from "./common/QuantitySelector";
import CollectionUnitsTable from "./common/CollectionUnitsTable";
import DeliveryUnitsTable from "./common/DeliveryUnitsTable";
import _ from "lodash";
import { CheckBoxWithLabel, ModelErrors } from "sharedComponents";
import dotProp from "dot-prop-immutable";
import NotesSection from "./common/NotesSection";
import dayjs from "dayjs";
import ActionFormLayout from './common/ActionFormLayout';
import { TextMuted } from 'sharedComponents';

const d = dotProp;

interface ICollectionUnit {
  name: string;
  id: string;
}

interface IRequestAdhocVisitForm {
  form: any;
  errors: any;
  context: {
    id: string;
    serviceName: string;
    serviceUuid: string;
    visitPlanName: string;
    availableBinTypeCodes: string[];
    collectionInstructions: ICollectionUnit[];
    availableCollectionServiceCodes: string[];
    availableDeliveryServiceCodes: string[];
  };
  onSetForm: any;
}

const RequestAdhocVisitForm: React.FC<IRequestAdhocVisitForm> = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const {
    deliverAdditionalStock,
    deliveryUnits,
    collectionUnits,
    collectionInstructionId,
  } = form;

  const {
    serviceName,
    availableCollectionServiceCodes,
    availableDeliveryServiceCodes,
    collectionInstructions,
  } = context;

  const { binTypes, binGroups } = useBinFetch();

  const [anchorElemSpecOnly, setAnchorElemSpecOnly] = useState(null);
  const [anchorElemAddStock, setAnchorElemAddStock] = useState(null);

  const availableCollectionOptions = _.pick(
    binTypes,
    _.difference(
      availableCollectionServiceCodes,
      _.map(collectionUnits, "serviceCode")
    )
  );

  const availableDeliveryOptions = _.pick(
    binTypes,
    _.difference(
      availableDeliveryServiceCodes,
      _.map(deliveryUnits, "serviceCode")
    )
  );

  const updateDeliveryQuantity = (quantity, serviceCode) => {
    const newUnits = d.merge(deliveryUnits, sanatizeDotProp(serviceCode), {
      quantity,
    });
    onSetForm({ deliveryUnits: newUnits });
  };
  const updateCollectionQuantity = (quantity, serviceCode) => {
    const newUnits = d.merge(collectionUnits, sanatizeDotProp(serviceCode), {
      quantity,
    });
    onSetForm({ collectionUnits: newUnits });
  };
  const updateCollectionCollectAll = (collectAll, serviceCode) => {
    const newUnits = d.merge(collectionUnits, sanatizeDotProp(serviceCode), {
      collectAll,
    });
    onSetForm({ collectionUnits: newUnits });
  };
  const updateVisitActionId = (visitActionId, serviceCode) => {
    const newSpecs = d.merge(collectionUnits, sanatizeDotProp(serviceCode), {
      visitActionId,
    });
    onSetForm({ collectionUnits: newSpecs });
  };

  const collectionInstructionOptions = _.map(
    collectionInstructions,
    ({ name, id }) => {
      return { label: name, value: id };
    }
  );

  const deliveryOnly = collectionInstructionId == "delivery_only";

  const updateUnitHandler = (serviceCode) => {
    const newCollectionUnits = d.merge(
      collectionUnits,
      sanatizeDotProp(serviceCode),
      {
        visitActionId: "collect_and_replace",
        collectAll: false,
        quantity: 0,
        serviceCode: serviceCode,
      }
    );

    const newDeliveryUnits = d.merge(
      deliveryUnits,
      sanatizeDotProp(serviceCode),
      {
        quantity: 1,
        serviceCode,
      }
    );

    onSetForm({
      collectionUnits: newCollectionUnits,
      deliveryUnits: newDeliveryUnits,
    });
  };

  const removeUnit = (serviceCode) => {
    const newCollectionUnits = _.omit(collectionUnits, serviceCode);
    const newDeliveryUnits = _.omit(deliveryUnits, serviceCode);
    onSetForm({
      collectionUnits: newCollectionUnits,
      deliveryUnits: newDeliveryUnits,
    });
  };


 const title = (
    <span>
      <strong>Request Adhoc Visit</strong> &nbsp; <TextMuted> {serviceName}</TextMuted>
    </span>
  )


  const body = (
    <>
      <div>
        <div className="form-group row">
          <div className="input-wrapper col-md-3">
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 10000 }),
              }}
              options={collectionInstructionOptions}
              value={_.find(collectionInstructionOptions, {
                value: collectionInstructionId,
              })}
              onChange={({ value }) =>
                onSetForm({ collectionInstructionId: value })
              }
            />
          </div>
          <div className="input-wrapper col-md-5">
            {!deliveryOnly ? (
              <CheckBoxWithLabel
                checked={deliverAdditionalStock}
                onClick={(deliverAdditionalStock) =>
                  onSetForm({ deliverAdditionalStock })
                }
              >
                Deliver Additional Stock?
              </CheckBoxWithLabel>
            ) : (
              <strong>Deliver the following</strong>
            )}
          </div>
        </div>
        {collectionInstructionId == "spec_only" ? (
          <CollectionUnitsTable
            headerDetailsHandler={(e) => setAnchorElemSpecOnly(e.currentTarget)}
            collectionUnits={collectionUnits}
            deliveryUnits={deliveryUnits}
            binTypes={binTypes}
            binGroups={binGroups}
            updateCollectionQuantity={updateCollectionQuantity}
            updateCollectionCollectAll={updateCollectionCollectAll}
            deliverAdditionalStock={deliverAdditionalStock}
            updateDeliveryQuantity={updateDeliveryQuantity}
            updateVisitActionId={updateVisitActionId}
            removeUnit={removeUnit}
            availableCollectionOptions={toArray(availableCollectionOptions)}
          />
        ) : null}

        {(deliverAdditionalStock || deliveryOnly) &&
        collectionInstructionId !== "spec_only" ? (
          <DeliveryUnitsTable
            headerDetailsHandler={(e) => setAnchorElemAddStock(e.currentTarget)}
            deliveryUnits={deliveryUnits}
            binTypes={binTypes}
            binGroups={binGroups}
            updateDeliveryQuantity={updateDeliveryQuantity}
            removeUnit={removeUnit}
            availableDeliveryOptions={toArray(availableDeliveryOptions)}
          />
        ) : null}
      </div>

      <BinTypeSelectorPopover
        anchorEl={anchorElemSpecOnly}
        setAnchorEl={setAnchorElemSpecOnly}
        options={toArray(availableCollectionOptions)}
        addBinHandler={updateUnitHandler}
      />

      <BinTypeSelectorPopover
        anchorEl={anchorElemAddStock}
        setAnchorEl={setAnchorElemAddStock}
        options={toArray(availableDeliveryOptions)}
        addBinHandler={updateUnitHandler}
      />
    </>
  )

  return (
   <ActionFormLayout title={title} body={body} form={form} onSetForm={onSetForm} errors={errors}/>
  )

  // return (
  //   <div>
  //     <Stack
  //       direction="row"
  //       justifyContent="flex-start"
  //       alignItems="center"
  //       spacing={3}
  //     >
  //       <DebugButton {...{ form, context, errors }} />
  //       <Typography variant="h5" sx={{ fontWeight: "bold" }}>
  //         Request Adhoc Visit
  //       </Typography>
  //       <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
  //         / {serviceName}
  //       </Typography>
  //     </Stack>
  //     <div className="mt-4">
  //       <div className="form-group row">
  //         <div className="input-wrapper col-md-3">
  //           <Select
  //             styles={{
  //               // Fixes the overlapping problem of the component
  //               menu: (provided) => ({ ...provided, zIndex: 10000 }),
  //             }}
  //             options={collectionInstructionOptions}
  //             value={_.find(collectionInstructionOptions, {
  //               value: collectionInstructionId,
  //             })}
  //             onChange={({ value }) =>
  //               onSetForm({ collectionInstructionId: value })
  //             }
  //           />
  //         </div>
  //         <div className="input-wrapper col-md-5">
  //           {!deliveryOnly ? (
  //             <CheckBoxWithLabel
  //               checked={deliverAdditionalStock}
  //               onClick={(deliverAdditionalStock) =>
  //                 onSetForm({ deliverAdditionalStock })
  //               }
  //             >
  //               Deliver Additional Stock?
  //             </CheckBoxWithLabel>
  //           ) : (
  //             <strong>Deliver the following</strong>
  //           )}
  //         </div>
  //       </div>
  //       {collectionInstructionId == "spec_only" ? (
  //         <CollectionUnitsTable
  //           headerDetailsHandler={(e) => setAnchorElemSpecOnly(e.currentTarget)}
  //           collectionUnits={collectionUnits}
  //           deliveryUnits={deliveryUnits}
  //           binTypes={binTypes}
  //           binGroups={binGroups}
  //           updateCollectionQuantity={updateCollectionQuantity}
  //           updateCollectionCollectAll={updateCollectionCollectAll}
  //           deliverAdditionalStock={deliverAdditionalStock}
  //           updateDeliveryQuantity={updateDeliveryQuantity}
  //           updateVisitActionId={updateVisitActionId}
  //           removeUnit={removeUnit}
  //           availableCollectionOptions={toArray(availableCollectionOptions)}
  //         />
  //       ) : null}

  //       {(deliverAdditionalStock || deliveryOnly) &&
  //       collectionInstructionId !== "spec_only" ? (
  //         <DeliveryUnitsTable
  //           headerDetailsHandler={(e) => setAnchorElemAddStock(e.currentTarget)}
  //           deliveryUnits={deliveryUnits}
  //           binTypes={binTypes}
  //           binGroups={binGroups}
  //           updateDeliveryQuantity={updateDeliveryQuantity}
  //           removeUnit={removeUnit}
  //           availableDeliveryOptions={toArray(availableDeliveryOptions)}
  //         />
  //       ) : null}
  //     </div>

  //     <BinTypeSelectorPopover
  //       anchorEl={anchorElemSpecOnly}
  //       setAnchorEl={setAnchorElemSpecOnly}
  //       options={toArray(availableCollectionOptions)}
  //       addBinHandler={updateUnitHandler}
  //     />

  //     <BinTypeSelectorPopover
  //       anchorEl={anchorElemAddStock}
  //       setAnchorEl={setAnchorElemAddStock}
  //       options={toArray(availableDeliveryOptions)}
  //       addBinHandler={updateUnitHandler}
  //     />

  //     <NotesSection
  //       form={form}
  //       onSetForm={onSetForm}
  //       context={context}
  //       contactLabel="Requested By"
  //       contactPlaceholder="Who made the request?"
  //       dateLabel="Requested On"
  //       // Initial value is not triggering the onChange
  //       dateValue={form?.requestedAt}
  //       onChange={(newValue) =>
  //         onSetForm({
  //           requestedAt: newValue.format("DD/MM/YYYY"),
  //         })
  //       }
  //     />
  //     <DebugButton {...form} />
  //     <ModelErrors errors={errors} />
  //   </div>
  // );
};

export default RequestAdhocVisitForm;
