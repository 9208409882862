import { Chip, Box, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { startCase } from "lodash";
import { formatDate } from "sharedUtils";
import ReportLogLayout from './ReportLogLayout';

const ReattemptDateLog = ({ request }) => {
  const { reattemptDate } = request.data || {};

  // ReattemptDateConfirmed

  const logHeader = request.name == 'ReattemptDateConfirmed' ? (
    <>
      {startCase(request.name)} for &nbsp;
      <Chip size="xsmall" color="success" label={formatDate(reattemptDate)} />
    </>
  ) : (
    <> {request.name}</>
  );

  const logContent = (
    <>
      {startCase(request.name)}
    </>
  );

  return (
    <ReportLogLayout logHeader={logHeader} logContent={logContent} object={request} />
  );


};

export default ReattemptDateLog;
