import { useEffect, useState } from "react";
import { DebugButton } from "service/GlobalDebugger";
import PropTypes from "prop-types";
import "react-infinite-calendar/styles.css";
import "./styles.scss";
import Debugger from "./Debugger";
import { CheckBoxWithLabel, ModelErrors } from "sharedComponents";
import { Box, Stack, Typography } from "@mui/material";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import dayjs from "dayjs";
import NotesSection from "./common/NotesSection";
import { formatDate } from "sharedUtils";
import ActionFormLayout from './common/ActionFormLayout';
import { TextMuted } from 'sharedComponents';

const ConfirmSuspensionForm = ({ form, context, errors, onSetForm }) => {
  const { nextVisits, serviceName, noVisitsExpectedAfter, withImmediateEffect, requestedAt } = context;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(dayjs());

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });


  useEffect(() => {
    if (withImmediateEffect) {
      const requestedDate = dayjs(requestedAt).format("DD/MM/YYYY");
      setValue(dayjs(requestedAt));
      onSetForm({ confirmedNoVisitsExpectedAfter: requestedDate });
    } else {
      const formattedDate = dayjs(noVisitsExpectedAfter).format("DD/MM/YYYY");
      setValue(dayjs(noVisitsExpectedAfter));
      onSetForm({ confirmedNoVisitsExpectedAfter: formattedDate });
    }
  }, [withImmediateEffect, noVisitsExpectedAfter]);



  const title = (
    <span>
      <strong>Confirm Suspension</strong> &nbsp; <TextMuted> {serviceName}</TextMuted>
    </span>
  )

  const body = (
    <>
      {withImmediateEffect ? (
          <div>
            <Box sx={{ marginTop: 1 }}>
              Vendor was requested to suspend service with immediate effect
            </Box>
            <label>No Visits Expected After: {formatDate(requestedAt)}</label>
          </div>
        ) : (
          <div>
            <Box sx={{ marginTop: 1 }}>
              Vendor was requested to suspend service from: {formatDate(noVisitsExpectedAfter)}
            </Box>
            <label>No Visits Expected After</label>
            <ServiceCalendarDatePicker
              // minDate={dayjs(new Date())}
              visitDays={visitDays}
              open={open}
              setOpen={setOpen}
              value={value}
              onChange={(newValue) =>
                onSetForm({
                  confirmedNoVisitsExpectedAfter: newValue.format("DD/MM/YYYY"),
                })
              }
            />
          </div>
        )}
    </>
  )

  return (
   <ActionFormLayout title={title} body={body} form={form} onSetForm={onSetForm} errors={errors}/>
  )


  // return (
  //   <div>
  //     <Stack
  //       direction="row"
  //       justifyContent="flex-start"
  //       alignItems="center"
  //       spacing={3}
  //     >
  //       <Typography variant="h5" sx={{ fontWeight: "bold" }}>
  //         Confirm Suspension
  //       </Typography>
  //       <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
  //         / {serviceName}
  //       </Typography>
  //     </Stack>
  //     <div
  //       className="mt-3"
  //       style={{ display: "flex", flexDirection: "column", gap: "5px" }}
  //     >
  //       {withImmediateEffect ? (
  //         <div>
  //           <Box sx={{ marginTop: 1 }}>
  //             Vendor was requested to suspend service with immediate effect
  //           </Box>
  //           <label>No Visits Expected After: {formatDate(requestedAt)}</label>
  //         </div>
  //       ) : (
  //         <div>
  //           <Box sx={{ marginTop: 1 }}>
  //             Vendor was requested to suspend service from: {formatDate(noVisitsExpectedAfter)}
  //           </Box>
  //           <label>No Visits Expected After</label>
  //           <ServiceCalendarDatePicker
  //             // minDate={dayjs(new Date())}
  //             visitDays={visitDays}
  //             open={open}
  //             setOpen={setOpen}
  //             value={value}
  //             onChange={(newValue) =>
  //               onSetForm({
  //                 confirmedNoVisitsExpectedAfter: newValue.format("DD/MM/YYYY"),
  //               })
  //             }
  //           />
  //         </div>
  //       )}
  //       <NotesSection
  //         form={form}
  //         onSetForm={onSetForm}
  //         context={context}
  //         contactPlaceholder="Who requested the Suspension?"
  //         contactLabel="Confirmed by"
  //         dateLabel="Confirmed on"
  //         dateValue={form?.confirmedAt}
  //         onChange={(newValue) =>
  //           onSetForm({
  //             confirmedAt: newValue.format("DD/MM/YYYY"),
  //           })
  //         }
  //       />
  //     </div>

  //     <DebugButton {...form} />

  //     <ModelErrors errors={errors} />
  //   </div>
  // );
};

ConfirmSuspensionForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    confirmedNoVisitsExpectedAfter: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    noVisitsExpectedAfter: PropTypes.string,
    serviceName: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default ConfirmSuspensionForm;
