import { IconButton, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import { StreamIcon } from "service/utils/Icons";
import { formatDate } from "sharedUtils";

const VlcTable = ({ vendorLocationAccounts }) => {
  return (

    <TableContainer >

      <Table size="small">
        <TableHead sx={{ "& .MuiTableCell-root": { py: 'inherit', fontWeight: "inherit", color: "inherit", }, }}>
          <TableRow
            sx={{
              fontWeight: "noraml",
              color: "#828B98",
              border: "1px solid transparent",
              py: '1px'
            }}
          >
            <TableCell>VLC</TableCell>
            <TableCell>Last Data Received On</TableCell>
            <TableCell>First Billed</TableCell>
            <TableCell>Last Billed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& .MuiTableRow-root": {
              borderTop: "1px solid #F1F1F5",
            },
          }}
        >
          {vendorLocationAccounts?.map((item) => (
            <TableRow key={item.vendorLocationCode}>
              <TableCell>
                <Link
                  target="_blank"
                  href={`/admin/vendors/${
                    item.vendorId
                  }/location_mappings/${encodeURIComponent(
                    item.vendorLocationCode
                  )}`}
                  underline="none"
                  sx={{ color: "#3BAFDA !important" }}
                >
                  {item.vendorLocationCode}
                </Link>
                {item?.streamUrl && (
                    <StreamIcon sx={{ fontSize:'1em', ml: 1}} onClick={() => window.open(item?.streamUrl, "_blank")}/>

                )}
              </TableCell>
              <TableCell>{formatDate(item.lastDataReceivedOn)}</TableCell>
              <TableCell>{formatDate(item.firstBilledOn)}</TableCell>
              <TableCell>{formatDate(item.lastBilledOn)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VlcTable;
