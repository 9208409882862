import { Typography } from "@mui/material";
import { startCase } from "lodash";
import SpecChangeRequestCardDetails from "service/report-request-box/SpecChangeRequestCardDetails";
import ReportLogLayout from './ReportLogLayout';

const SpecChangeRequestLog = ({ request }) => {
  const { date } = request.data || {};

  // TODO: Handle accept spec change when implemented
  // const isConfirmed = false;

  const logHeader = (
    <>
      {date ? `${startCase(request.name)} for ${formatDate(date)}`
            : startCase(request.name)
      }
    </>
  );

  const logContent = (
    <>
      {request?.data?.specifications && (
        <SpecChangeRequestCardDetails request={request?.data} />
      )}
    </>
  );

  return (
    <ReportLogLayout logHeader={logHeader} logContent={logContent} object={request} />
  );

};

export default SpecChangeRequestLog;
