import {
  Timeline,
  TimelineContent,
  TimelineItem,
  timelineItemClasses,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  List,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { formatDate } from "sharedUtils";
import PartialCollectionTooltip from "./PartialCollectionTooltip";
import ReportVisitFailureCardTable from "./ReportVisitFailureCardTable";
import ShowMoreDivider from "service/forms/common/ShowMoreDivider";
import DarkTooltip from "service/forms/common/DarkTooltip";
import React, { useMemo } from "react";
import { FailedVisitReport } from "service/ticket/types";

interface ReportVisitFailureCardProps {
  report: FailedVisitReport;
  toggleOpen: (open: any) => void;
  isOpen: boolean;
}

const collectedResults = {
  total_failure: "Nothing collected",
  partial_failure: "Some collected",
  success: "All stock collected",
};

const deliveredResults = {
  total_failure: "Nothing delivered",
  partial_failure: "Some delivered",
  success: "All stock delivered",
};

export const colorForSlug = {
  success: "#0FA764",
  total_failure: "#FF5733",
  partial_failure: "#ed6c02",
};

const ReportVisitFailureCard: React.FC<ReportVisitFailureCardProps> = ({
  report,
  toggleOpen,
  isOpen,
}) => {
  const allFeedback = useMemo(() => {
    return _.orderBy(
      [...report?.feedback, ...report?.clientFeedback],
      "reportedAt",
      "desc"
    );
  }, [report]);

  return (
    <Box sx={{  }}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          background: "#262B30",
          "& .MuiCardContent-root:last-child": {
            pb: 1,
          },
          "& .MuiTypography-root": {
            fontSize: "12px",
            color: "#fff",
          },
        }}
      >
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Box display="flex" alignItems="center">
              <Typography fontWeight="bold" variant="h5">
                Failure Visit Report
              </Typography>

              <Typography
                sx={{
                  ml: "4px",
                  color: "grey !important",
                  fontSize: "10px !important",
                }}
              >
                for {formatDate(report?.reportedAt)}
              </Typography>
            </Box>
          </Stack>
          <Typography className="custom-typography" fontWeight="bold">
            Visit failed on{" "}
            <span style={{ color: colorForSlug.total_failure }}>
              {formatDate(report?.date)}
            </span>
          </Typography>
          <ReportVisitFailureCardTable report={report} />
          <List sx={{ width: "100%" }} component="nav">
            <ShowMoreDivider toggleOpen={toggleOpen} isOpen={isOpen} />
            <Collapse
              in={isOpen}
              timeout="auto"
              unmountOnExit
              sx={{ color: "#fff" }}
            >
              <TableContainer>
                <Table
                  size="small"
                  sx={{
                    "& .MuiTableCell-root": {
                      color: "#FFFFFF",
                      fontSize: "12px",
                      fontWeight: "bold",
                      borderBottom: "1px solid #323B45",
                    },
                    "& .MuiTypography-root": {
                      fontWeight: "bold",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>RECEIVED</TableCell>
                      <TableCell>STAKEHOLDER</TableCell>
                      <TableCell>COLLECTION</TableCell>
                      <TableCell>DELIVERY</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography noWrap>
                          {formatDate(report.reportedAt)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {report.reportedByStakeholderId === 1 ||
                        report.reportedByStakeholderId === 2
                          ? "CLIENT"
                          : "VENDOR"}
                      </TableCell>
                      <TableCell>
                        <DarkTooltip
                          arrow
                          title={
                            report?.collectionResultSlug ===
                              "partial_failure" && (
                              <PartialCollectionTooltip feedback={report} />
                            )
                          }
                        >
                          <Typography
                            noWrap
                            sx={{
                              color: `${
                                colorForSlug[report.collectionResultSlug]
                              } !important`,
                            }}
                          >
                            {collectedResults[report.collectionResultSlug]}
                          </Typography>
                        </DarkTooltip>
                      </TableCell>
                      <TableCell>
                        <Typography
                          noWrap
                          sx={{
                            color: `${
                              colorForSlug[report.deliveryResultSlug]
                            } !important`,
                          }}
                        >
                          {deliveredResults[report.deliveryResultSlug]}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {allFeedback.map((feedback, index) => (
                      <TableRow key={`combined-${index + 1}`}>
                        <TableCell>{formatDate(feedback.reportedAt)}</TableCell>
                        <TableCell>{feedback.type.toUpperCase()}</TableCell>
                        <TableCell>
                          <DarkTooltip
                            arrow
                            title={
                              feedback?.collectionResultSlug ===
                                "partial_failure" && (
                                <PartialCollectionTooltip feedback={feedback} />
                              )
                            }
                          >
                            <Typography
                              noWrap
                              sx={{
                                color: `${
                                  colorForSlug[feedback.collectionResultSlug]
                                } !important`,
                              }}
                            >
                              {collectedResults[feedback.collectionResultSlug]}
                            </Typography>
                          </DarkTooltip>
                        </TableCell>
                        <TableCell>
                          <Typography
                            noWrap
                            sx={{
                              color: `${
                                colorForSlug[feedback.deliveryResultSlug]
                              } !important`,
                            }}
                          >
                            {deliveredResults[feedback.deliveryResultSlug]}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Timeline
                sx={{
                  pb: 0,
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                  },
                  [`& .${timelineItemClasses.root}`]: {
                    minHeight: 0,
                  },
                }}
              >
                {report?.notes.map((note) => (
                  <TimelineItem key={note.timestamp}>
                    <TimelineOppositeContent>
                      <Typography variant="h6">{note.userName}</Typography>
                      <Typography variant="h6" color="gray" noWrap>
                        on {formatDate(note.timestamp)}
                      </Typography>
                    </TimelineOppositeContent>

                    <TimelineContent>
                      <p>{note.text}</p>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Collapse>
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReportVisitFailureCard;
