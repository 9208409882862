import { Grid } from "@mui/material";
import { useGetLocationServiceVendorsQuery } from "api/services";
import { map } from "lodash";
import LocationServicesVendorBoxAndy from "service/LocationServicesVendorBoxAndy";
import AppContext from "ticket/AppContext";

let SideBarAndy = ({ locationId, onSelectDate }) => {
  const { data: locationServiceVendors } =
    useGetLocationServiceVendorsQuery(locationId);

  const onSelectAction = () => undefined;

  return (
    <AppContext.Provider
      value={{
        onSelectAction,
        selectedContainer: undefined,
        setSelectedContainer: (_x) => {},
        formType: "all",
        setFormType: (_x) => {},
        cardTab: "request",
        setCardTab: (_x) => {},
        showTaggingForm: false,
        setShowTaggingForm: (_x) => {},
        highlightedIds: [],
        setHighlightedIds: (_x) => {},
        currentTicketId: "",
        onSelectDate: onSelectDate,
      }}
    >
      <Grid container spacing={1}>
        {map(locationServiceVendors, (locationServiceVendor) => {
          return (
            <Grid item sx={{ width: "100%", mb: 1 }}>
              <LocationServicesVendorBoxAndy
                initialOpenState={true}
                onSelectAction={onSelectAction}
                key={locationServiceVendor.vendor.id}
                {...locationServiceVendor}
              />
            </Grid>
          );
        })}
      </Grid>
    </AppContext.Provider>
  );
};
export default SideBarAndy;
