import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AppContext from "ticket/AppContext";
import { useTicket } from "ticket/selectors";
import { useFormServiceActionMutation, useGetLocationServiceVendorQuery } from "api/services";

const LogRemediationReplyForm = ({ context }) => {
  const ticket = useTicket();
  const { onCloseRemediation } = useContext(AppContext);
  const [submitForm] = useFormServiceActionMutation();
  const [serviceUuid, setServiceUuid] = useState("");

  const { data: locationServiceVendor } =
  useGetLocationServiceVendorQuery({ locationId: `${ticket?.location?.id}`, vendorId: ticket?.vendor?.id });

  useEffect(() => {
    if (!locationServiceVendor)
      return;

    setServiceUuid(locationServiceVendor[ticket?.vendor?.id].services[0].uuid);
  }, [locationServiceVendor])

  const handleSubmit = () => {
    submitForm({
      url: `/admin/service_actions/${serviceUuid}/log_remediation_reply`,
      body: {
        remediation_notice_id: context.remediationNoticeId,
        tagged_reply_id: context.taggedReplyId,
        form: {
          vendor_id: ticket?.vendor?.id,
          location_uuid: ticket?.location?.uuid,
          service_uuid: serviceUuid,
          authority_id: ticket?.location?.authority_id,
        },
      },
    });
    onCloseRemediation();

  };

  return (
    <>
      <Box
        sx={{
          p: 0,
          "& .MuiTypography-root": { fontSize: "12px" },
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "15px !important", mb: 1 }}
        >
        Log Remediation Reply
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            position: "absolute",
            top: 0,
            right: 1,
          }}
        >
          <IconButton size="large" onClick={() => onCloseRemediation()}>
            <CloseIcon sx={{ fontSize: "30px" }} />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          sx={{ width: "100%" }}
        >
          <Button
            variant="outlined"
            color="info"
            onClick={() => onCloseRemediation()}
            sx={{ fontSize: "12px", width: "200px" }}
          >
            Cancel
          </Button>
          <Box>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              color="success"
              sx={{ fontSize: "12px", width: "200px" }}
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default LogRemediationReplyForm;
