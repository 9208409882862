import { Badge, Box, MenuItem, Select, styled, Tab, Tabs } from "@mui/material";
import { size } from "lodash";
import { useContext } from "react";
import AppContext from "ticket/AppContext";
import {
  useAdhocVisitRequests,
  useFailedVisitReports,
  useRemediationCases,
  useServiceResumptionRequests,
  useServiceSuspensionRequests,
  useServiceTerminationRequests,
  useSpecChangeRequests,
  useStockDeliveryRequests,
  useVisitFrequencyChangeRequests,
} from "ticket/selectors";
import AdhocVisitRequestTab from "./AdhocVisitRequestTab";
import AllRequestTab from "./AllRequestTab";
import RemediationCaseTab from "./RemediationCaseTab";
import ReportTab from "./ReportTab";
import ServiceResumptionRequestTab from "./ServiceResumptionRequestTab";
import ServiceSuspensionRequestTab from "./ServiceSuspensionRequestTab";
import ServiceTerminationRequestTab from "./ServiceTerminationRequestTab";
import SpecChangeRequestTab from "./SpecChangeRequestTab";
import StockDeliveryRequestTab from "./StockDeliveryRequestTab";
import VisitFrequencyChangeRequestTab from "./VisitFrequencyChangeRequestTab";

export const StyledTabs = styled(Tabs)({
  backgroundColor: "#2C3237",
  // borderRadius: "4px",
  ".MuiTabs-indicator": {
    display: "none",
  },
});

export const StyledTab = styled(Tab)({
  padding: '1px 20px',
  // fontSize: "12px",
  color: "#fff",
  // minWidth: "120px",
  textTransform: "none",
  "&.Mui-selected": {
  //   color: "#fff",
    backgroundColor: "#1F2328",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#262B30",
  },
});

export const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value == index && children}
    </Box>
  );
};

const BadgeTabLabel = ({ count, label, color, ...other }) => (
  <Badge
    color={color}
    badgeContent={count > 0 ? count : null}
    slotProps={{
      badge: {
        style: {
          right: "-10px",
          top: 0,
        },
      },
    }}
    {...other}
  >
    {label}
  </Badge>
);

const MainTabs = () => {
  const { formType, setFormType, cardTab, setCardTab } = useContext(AppContext);
  const adhocVisitRequests = useAdhocVisitRequests();
  const stockDeliveryRequests = useStockDeliveryRequests();
  const remediationCase = useRemediationCases();
  const failedVisitReports = useFailedVisitReports();
  const serviceSuspensionRequests = useServiceSuspensionRequests();
  const serviceTerminationRequests = useServiceTerminationRequests();
  const serviceResumptionRequests = useServiceResumptionRequests();
  const specChangeRequests = useSpecChangeRequests();
  const visitFrequencyChangeRequests = useVisitFrequencyChangeRequests();

  const handleChange = (_event, newValue) => {
    setCardTab(newValue);
  };

  const handleformTypeChange = (event) => {
    setFormType(event.target.value);
  };

  const requestsCount =
    size(adhocVisitRequests) +
    size(stockDeliveryRequests) +
    size(specChangeRequests) +
    size(visitFrequencyChangeRequests) +
    size(serviceSuspensionRequests) +
    size(serviceResumptionRequests) +
    size(serviceTerminationRequests);

  return (
    <Box sx={{ width: "100%", backgroundColor: "#1E2328", borderRadius: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs value={cardTab} onChange={handleChange}>
          <StyledTab
            value="request"
            label={
              <BadgeTabLabel
                color="primary"
                count={requestsCount}
                label="Requests"
              />
            }
          />
          <StyledTab
            value="report"
            label={
              <BadgeTabLabel
                color="primary"
                count={failedVisitReports?.length}
                label="Reports"
              />
            }
          />
          <StyledTab
            value="case"
            label={
              <Badge
                color="secondary"
                badgeContent={remediationCase ? "!" : null}
                slotProps={{
                  badge: {
                    style: {
                      right: "-10px",
                      top: 0,
                    },
                  },
                }}
              >
                Case
              </Badge>
            }
            sx={{
              ...(remediationCase ? { border: "2px solid #9c27b0" } : {}),
            }}
          />
        </StyledTabs>
      </Box>
      <TabPanel value={cardTab} index="request">
        <Box sx={{ p: 2 }}>
          <Select
            value={formType}
            onChange={handleformTypeChange}
            displayEmpty
            fullWidth
            SelectDisplayProps={{
              style: {
                background: "#31363A",
                color: "#fff",
                fontSize: "12px",
              },
            }}
            inputProps={{
              "aria-label": "Without label",
            }}
            sx={{
              "& .MuiSvgIcon-root": {
                color: "#fff",
              },
            }}
          >
            <MenuItem sx={{ fontSize: "12px" }} value="all">
              All Requests
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value="adhoc">
              Adhoc Visit Requests
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value="stock">
              Stock Delivery Requests
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value="spec">
              Spec Change Requests
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value="frequency">
              Visit Frequency Change Requests
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value="suspension">
              Service Suspension Requests
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value="termination">
              Service Termination Requests
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value="resumption">
              Service Resumption Requests
            </MenuItem>
          </Select>
        </Box>
        {formType === "all" && (
          <AllRequestTab
            adhocVisitRequests={adhocVisitRequests}
            stockDeliveryRequests={stockDeliveryRequests}
            specChangeRequests={specChangeRequests}
            visitFrequencyChangeRequests={visitFrequencyChangeRequests}
            serviceSuspensionRequests={serviceSuspensionRequests}
            serviceTerminationRequests={serviceTerminationRequests}
            serviceResumptionRequests={serviceResumptionRequests}
          />
        )}
        {formType === "adhoc" && (
          <AdhocVisitRequestTab adhocVisitRequests={adhocVisitRequests} />
        )}
        {formType === "stock" && (
          <StockDeliveryRequestTab
            stockDeliveryRequests={stockDeliveryRequests}
          />
        )}
        {formType === "spec" && (
          <SpecChangeRequestTab specChangeRequests={specChangeRequests} />
        )}
        {formType === "frequency" && (
          <VisitFrequencyChangeRequestTab
            visitFrequencyChangeRequests={visitFrequencyChangeRequests}
          />
        )}
        {formType === "suspension" && (
          <ServiceSuspensionRequestTab
            serviceSuspensionRequests={serviceSuspensionRequests}
          />
        )}
        {formType === "termination" && (
          <ServiceTerminationRequestTab
            serviceTerminationRequests={serviceTerminationRequests}
          />
        )}
        {formType === "resumption" && (
          <ServiceResumptionRequestTab
            serviceResumptionRequests={serviceResumptionRequests}
          />
        )}
      </TabPanel>
      <TabPanel value={cardTab} index="report">
        <ReportTab failedVisitReports={failedVisitReports} />
      </TabPanel>

      <TabPanel value={cardTab} index="case">
        <Box sx={{ p: 2 }}>
          <RemediationCaseTab remediationCase={remediationCase} />
        </Box>
      </TabPanel>
    </Box>
  );
};

export default MainTabs;
