// ReportLogLayout.tsx
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack, Box, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { TextSmallMuted, TextSmall, TextMuted } from 'sharedComponents';
import { useState } from "react";
import { useTicket } from "ticket/selectors";
import { flatMap } from "lodash";
import { formatDate } from "sharedUtils";


const ReportLogLayout = ({ logHeader, logContent, notes2, createdAt, object = undefined, extraLinks = undefined, startOpen = false }) => {
  const { stakeholderContacts } = useTicket() || {};
  const allStakeholders = flatMap(stakeholderContacts || [], (x) => x);
  const inputtedBy = allStakeholders?.find((x) => x.id === object?.metadata?.inputtedById);
  const [open, setOpen] = useState(startOpen);
  const { notes } = object?.data || {};

  return (
    <>
      <Stack direction="row" onClick={() => setOpen(!open)}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            cursor: 'pointer',
            pl: '8px',
            backgroundColor:  open ? "#121212" : "inherit",
            borderBottom: '0px dashed #4B4B4B',
            borderWidth:  open ? "1px" : "0",
          }}
        >

        <Box>
         {logHeader}
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          {extraLinks}
          <IconButton disableRipple sx={{color: '#fff'}}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Stack>



      <Collapse in={open} timeout={0} unmountOnExit >
        <Box sx={{ m: '6px 0 10px 8px', px: '10px', borderLeft: '1px solid #4B4B4B' }}>
          {logContent}
          {notes && (
            <Box><strong>Note:</strong> {notes}</Box>
          )}
          {inputtedBy && (
            <Box sx={{ mt: '5px', textAlign: 'right' }}>
              <TextSmall>
                Inputted by <strong>{inputtedBy?.nameWithoutEmail}</strong> on <strong>{formatDate(object?.createdAt)}</strong>
              </TextSmall>
            </Box>
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default ReportLogLayout;



