import { Box, TableCell, TableRow, Typography,} from "@mui/material";
import { map, startCase } from "lodash";
import BinDot from "service/BinDot";
import { DeliveryTable } from "service/DeliveryInstruction";
import { formatDate, useBinFetch } from "sharedUtils";
import ReportLogLayout from './ReportLogLayout';

const StockDeliveryRequestLog = ({ request }) => {

  const { binTypes, binGroups } = useBinFetch();
  const { date, deliveryUnits } = request.data || {};

  const deliveryRows = map(deliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={serviceCode}>
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });


 const logHeader = (
    <>
      {date ? `${startCase(request.name)} for ${formatDate(date)}`
            : startCase(request.name)
      }
    </>
  );

 const logContent = (
    <>
      {deliveryUnits && <DeliveryTable rows={deliveryRows} label={false} />}
    </>
  );

  return (
    <ReportLogLayout logHeader={logHeader} logContent={logContent} object={request} />
  );

};

export default StockDeliveryRequestLog;
