import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme();

const successMain = '#8CC152';
const errorMain = '#D2302F';
const warningMain = '#ED6C01';

export const theme = createTheme({
   typography: {
    body1: {
      fontWeight: 'inherit',
    },
    small: {
      fontSize:'0.875rem'
    },
    medium: {
      fontSize:'1rem'
    },
    tiny: {
      fontSize:'0.6rem'
    },
    xsmall: {
      fontSize:'11px'
    },
    h5: {
      fontSize:'1.2rem'
    },
  },
  palette: {

    borderGrey: defaultTheme.palette.grey[300],
    darkBackgroundGrey: '#1F2328',

    success: {
      main: successMain,
      contrastText: "#fff",
    },
    error: {
      main: errorMain,
      contrastText: "#fff",
    },
    warning: {
      main: warningMain,
      contrastText: "#fff",
    },


    outlined: {
      main: "#555656",
    },
    white: {
      main: "#fff",
    },
    info: {
      main: "#637381",
    },
    needInfo: {
      color: "#FFA600"
    },
    haveInfo: {
      color: "#1CAF38" // #19E53B Brighter
    },
    textMuted: "#777777",

    visits: {

      success: {
        main: "#7BB841",
        contrastText: "#fff"
      },
      failure: {
        main: "#EA3223",
        contrastText: "#fff"
      },
      conflicting: {
        main: "#EF8635",
        contrastText: "#fff"
      },
      unknown: {
        main: "#2B78E4",
        contrastText: "#fff"
      },
      planned: {
        main: "#f5bd43",
        contrastText: "#fff"
      },
      assumed: {
        main: "#8f3fcd",
        contrastText: "#fff"
      },
      canceled: {
        main: "#A1A4A9",
        contrastText: "#fff"
      },
    },

  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
      variants: [
        {
          props: { size: 'xsmall' },
          style: {
            height: '20px',
            borderRadius: '5px',
            '& .MuiChip-label': {
              paddingLeft: '6px',
              paddingRight: '6px',
            },
          },
        },
      ],
    },

    MuiBox: {
      styleOverrides: {
        root: {
          fontSize: '13px', // Override the default font size for Box components
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontStyle: "normal",
          fontWeight: 600,
          boxShadow: 'none', // Remove shadow
          '&:hover': {
            boxShadow: 'none', // Remove shadow on hover
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: "10px 0px",
          "&::before": {
            borderTop: "3px solid rgba(0, 0, 0, 0.12)",
          },
          "&::after": {
            borderTop: "3px solid rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          fontSize: 'inherit',
        },
        colorSuccess: {
          color: successMain,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
          fontSize: 'inherit',
          '&:hover': {
            color: '#4FC1E9', // Color changes to red on hover
          },
        },

      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#5F6369',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
          '&:active': {
            textDecoration: 'none',
          },
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // fontSize: "13px", // Set the default fontSize for tooltip content
          background: "#000",
        },
        arrow: {
          color: "#000",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        SelectDisplayProps: {
          style: {
            // fontSize: "13px",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          // fontSize: "13px",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          // fontSize: "13px",
        },
      },
    },
  },
});

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    info: true;
  }
}


export default theme;
