import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import OutlinedFlag from "@mui/icons-material/OutlinedFlag";
import { Box, Button, Grid } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { scrollToBottom } from "service/ticket/utils";
import { useCannedResponseFetch } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { useTicket } from "ticket/selectors";
import { useLocalStorage } from "usehooks-ts";
import { useAddTicketReplyMutation } from "../../../../api/tickets";
import {
  AGENT_COLOR,
  ORGANISATION_COLOR,
  VENDOR_COLOR,
} from "../../channel-header/ChannelHeaderStyles";
import { Channel } from "../../types";
import AgentSelect from "./AgentSelect";
import { ActionSection, MessageSection } from "./FormComponents";

const ResponseForm = ({ stakeholderContacts }) => {
  // Selectors
  const ticket = useTicket();
  const { closeDrawer, activeChannel: channel } = useContext(AppContext);

  // Local state
  const [responseExpected, setResponseExpected] = useState(false);
  const [cannedResponseId, setCannedResponseId] = useState(false);
  const [blobFiles, setBlobFiles] = useState([]);
  const [originalFileNames, setOriginalFileNames] = useState([]);
  const [addNewTicketReply] = useAddTicketReplyMutation();
  const [assignedAgentId, setAssignedAgentId] = useState(
    ticket?.assignedAgent?.id || ""
  );

  const [message, setMessage] = useLocalStorage(`message-${channel}`, null);

  // Form hooks
  const { handleSubmit, control, setValue, watch } = useForm();
  const toContactId = watch("to_contact_id");
  const textAreaRef = useRef(null);

  // Background color logic
  const backgroundForm =
    channel === Channel.Organisation
      ? ORGANISATION_COLOR
      : channel === Channel.Agent
      ? AGENT_COLOR
      : VENDOR_COLOR;

  // Event handlers
  const toggleResponse = () => {
    setResponseExpected(!responseExpected);
  };

  const handleSelectedResponse = (e: any) => {
    setCannedResponseId(e.target.value);
  };

  const cannedMessage = useCannedResponseFetch(
    cannedResponseId,
    ticket?.id,
    toContactId
  );

  useEffect(() => {
    if (cannedMessage) {
      setMessage(cannedMessage);
    }
  }, [cannedMessage]);

  // set initial value
  useEffect(() => {
    const storedMessage = window.localStorage.getItem(`message-${channel}`);
    if (storedMessage) {
      setMessage(JSON.parse(storedMessage));
    } else {
      setMessage("");
    }
  }, [channel, setMessage]);

  const handleFileUpload = (files: any) => {
    const newBlobFiles = [];
    const newOriginalFileNames = [];

    for (const file of files) {
      const blob = new Blob([file], { type: file.type });
      newBlobFiles.push(blob);
      newOriginalFileNames.push(file.name);
    }

    setBlobFiles([...blobFiles, ...newBlobFiles]);
    setOriginalFileNames([...originalFileNames, ...newOriginalFileNames]);
  };

  // Form submission logic
  const onSubmitNewReply = (url: string) => async (data: any) => {
    try {
      await addNewTicketReply({
        url,
        channel: channel,
        message: message,
        responseExpected: responseExpected,
        blobFiles: blobFiles,
        originalFileNames: originalFileNames,
        assignedAgentId: assignedAgentId,
        data,
      })
        .unwrap()
        .then(() => scrollToBottom())
        .then(() => closeDrawer())
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Failure:", error);
    }
  };

  useEffect(() => {
    if (ticket?.defaultContactMap && channel) {
      const defaultValue = ticket.defaultContactMap[channel]?.id;
      if (defaultValue !== undefined) {
        setValue("to_contact_id", defaultValue);
      }
    }
  }, [ticket, channel, setValue]);

  useEffect(() => {
    const cursorMarker = "**cursor**";
    if (message) {
      const cursorPosition = message.indexOf(cursorMarker);

      if (cursorPosition !== -1 && textAreaRef.current) {
        const textArea = textAreaRef.current;
        textArea.focus();
        textArea.setSelectionRange(
          cursorPosition,
          cursorPosition + cursorMarker.length
        );
      }
    }
  }, [message, setValue, textAreaRef]);

  const displayIcon = responseExpected ? (
    <HourglassBottomIcon />
  ) : (
    <OutlinedFlag />
  );

  const displayText = responseExpected
    ? "Response expected"
    : "No response expected";

  return (

    <form onSubmit={handleSubmit(onSubmitNewReply(ticket?.submitPath))} >

      <Grid container spacing={2} sx={{ mt: '5px'}}>
        <Grid item xs={9}>

          <MessageSection
            message={message}
            setMessage={setMessage}
            control={control}
            defaultValueContactId={ticket?.defaultContactMap[channel]?.id || null}
            backgroundForm={backgroundForm}
            stakeholderContacts={stakeholderContacts}
            channel={channel}
            handleSelectedResponse={handleSelectedResponse}
            textAreaRef={textAreaRef}
          />

        </Grid>

        <Grid item
          xs={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            position: "relative",
          }}
        >
          <ActionSection
            control={control}
            handleFileUpload={handleFileUpload}
            closeDrawer={closeDrawer}
          />
        </Grid>

      </Grid>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            mt: '20px',
            maxHeight: '34px'
          }}
        >
          <AgentSelect
            assignedAgentId={assignedAgentId}
            setAssignedAgentId={setAssignedAgentId}
          />

          {/* Response Required */}
          <Controller
            name="response_expected"
            control={control}
            render={({}) => (
              <Button
                variant="contained"
                color={responseExpected ? "error" : "success"}
                onClick={toggleResponse}
                size="small"
                sx={{
                  fontSize: "12px",
                  textWrap: "nowrap",
                  ml: 1
                }}
              >
                {displayIcon}
                {displayText}
              </Button>
            )}
          />
          <Button
            type="submit"
            variant="contained"
            color="success"
            size="small"
            sx={{ flex: 1, ml: 1, fontSize: "12px", }}
          >
            Send Message to {channel}
          </Button>
        </Box>

    </form>
  );
};

export default ResponseForm;
