import { Controller } from "stimulus"

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default class extends Controller {
  initialize() {

    var chartData = $(this.element).data('chart-data')
    console.log(chartData)
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(this.element, am4charts.XYChart);

    chart.data = chartData['data'];
    chart.logo.disabled = true;

//chart.numberFormatter.numberFormat = "#.'%'";
chart.dateFormatter.inputDateFormat = "MMM yyyy";

let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  // dateAxis.renderer.minGridDistance = 60;
  // dateAxis.startLocation = 0;
  // dateAxis.endLocation = 1;
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.renderer.minGridDistance = 20;
  dateAxis.renderer.cellStartLocation = 0.1;
  dateAxis.renderer.cellEndLocation = 0.9;
  dateAxis.baseInterval = {
    timeUnit: "month",
    count: 1
  }



let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //valueAxis.min = 0;
  //valueAxis.max = 100;
  //valueAxis.strictMinMax = true;
  //valueAxis.calculateTotals = true;
  // valueAxis.renderer.minWidth = 50;
  //
  //
function createSeries(field, name, color, stacked, width) {

  let series = chart.series.push(new am4charts.ColumnSeries());
  series.name = name;
  series.dataFields.dateX = "month";
  series.columns.template.width = am4core.percent(width);
  series.tooltipText = "{name}: {valueY}";
  series.columns.template.fill = color;
  series.strokeWidth = 0;
  series.dataFields.valueY = field;
  //series.dataFields.valueYShow = "totalPercent";
  series.stacked = stacked;
  // series.tooltip.pointerOrientation = "vertical";
  series.tooltip.fontSize = 12
  // series.tooltip.dy = -10;

  // let bullet = series.bullets.push(new am4charts.LabelBullet());
  // bullet.interactionsEnabled = false;
  // bullet.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
  // bullet.label.fill = am4core.color("#ffffff");
  // bullet.locationY = 0.5;
}

createSeries("total-collections", "Scheduled Collections", "#ccc", false, 40)
// createSeries("result-unconfirmed", "Not yet Confirmed", "#4c4f4d", true)
createSeries("as-scheduled", "As Scheduled", "#90bf5c", false, 80)
createSeries("within-2-days", "Within 2 days", "#ffe9b6", true, 80)
createSeries("more-than-2-days", "More than 2 days", "#ffb1c0", true, 80)
createSeries("not-preformed-and-not-yet-confirmed", "Not Performed", "#bf1d1d", true, 80)
// createSeries("extra-collection", "Schedule Unknown", "#ccc", true)

chart.cursor = new am4charts.XYCursor();
chart.cursor.xAxis = dateAxis;
// chart.scrollbarX = new am4core.Scrollbar();



chart.legend = new am4charts.Legend();
chart.legend.position = "bottom";
chart.legend.fontSize = 11
chart.legend.color = "#414141"


const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;

  }
}
