import { useState } from "react";
import { DebugButton } from "service/GlobalDebugger";
import PropTypes from "prop-types";
import "react-infinite-calendar/styles.css";
import "./styles.scss";
import Debugger from "./Debugger";
import { Stack, Typography } from "@mui/material";
import Select from "react-select";
import { CheckBoxWithLabel, ModelErrors } from "sharedComponents";
import NotesSection from "./common/NotesSection";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import dayjs from "dayjs";
import _ from "lodash";
import ActionFormLayout from './common/ActionFormLayout';
import { TextMuted } from 'sharedComponents';

const RequestSuspensionForm = ({ form, context, errors, onSetForm }) => {
  const { withImmediateEffect, reasonId } = form;

  const [open, setOpen] = useState(false);
  const { nextVisits, serviceName, visitPlanName, suspensionReasons } = context;

  const reasonOptions = _.map(suspensionReasons, ({ name, id }) => {
    return { label: name, value: id };
  });

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  const title = (
    <span>
      <strong>Request Service Suspension</strong> &nbsp; <TextMuted> {serviceName}</TextMuted>
    </span>
  )

  const body = (
    <>
      <div className="form-group row">
          <label className="col-md-2 control-label required">
            Reason for Suspension
          </label>
          <div className="input-wrapper col-md-10">
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              options={reasonOptions}
              value={_.find(reasonOptions, { value: reasonId })}
              onChange={({ value }) => onSetForm({ reasonId: value })}
              name="invoice"
              placeholder="Select the reason"
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="input-wrapper col-md-10">
            <CheckBoxWithLabel
              checked={withImmediateEffect}
              onClick={(withImmediateEffect) =>
                onSetForm({ withImmediateEffect })
              }
            >
              With Immediate Effect?
            </CheckBoxWithLabel>
          </div>
        </div>

        {!withImmediateEffect ? (
          <div className="form-group row">
            <label className="col-md-2 control-label required">
              Effective From
            </label>
            <div className="input-wrapper col-md-10">
              <ServiceCalendarDatePicker
                // minDate={dayjs(new Date())}
                visitDays={visitDays}
                open={open}
                setOpen={setOpen}
                onChange={(newValue) =>
                  onSetForm({
                    noVisitsExpectedAfter: newValue.format("DD/MM/YYYY"),
                  })
                }
              />
            </div>
          </div>
        ) : null}
    </>
  )

  return (
   <ActionFormLayout title={title} body={body} form={form} onSetForm={onSetForm} errors={errors}/>
  )



  // return (
  //   <div>
  //     <Stack
  //       direction="row"
  //       justifyContent="flex-start"
  //       alignItems="center"
  //       spacing={2}
  //     >
  //       <Typography variant="h5" sx={{ fontWeight: "bold" }}>
  //         Request Service Suspension
  //       </Typography>
  //       <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
  //         / {serviceName}
  //       </Typography>
  //     </Stack>

  //     <div className="mt-3">
  //       <div className="form-group row">
  //         <label className="col-md-2 control-label required">
  //           Reason for Suspension
  //         </label>
  //         <div className="input-wrapper col-md-10">
  //           <Select
  //             styles={{
  //               // Fixes the overlapping problem of the component
  //               menu: (provided) => ({ ...provided, zIndex: 9999 }),
  //             }}
  //             options={reasonOptions}
  //             value={_.find(reasonOptions, { value: reasonId })}
  //             onChange={({ value }) => onSetForm({ reasonId: value })}
  //             name="invoice"
  //             placeholder="Select the reason"
  //           />
  //         </div>
  //       </div>

  //       <div className="form-group row">
  //         <div className="input-wrapper col-md-10">
  //           <CheckBoxWithLabel
  //             checked={withImmediateEffect}
  //             onClick={(withImmediateEffect) =>
  //               onSetForm({ withImmediateEffect })
  //             }
  //           >
  //             With Immediate Effect?
  //           </CheckBoxWithLabel>
  //         </div>
  //       </div>

  //       {!withImmediateEffect ? (
  //         <div className="form-group row">
  //           <label className="col-md-2 control-label required">
  //             Effective From
  //           </label>
  //           <div className="input-wrapper col-md-10">
  //             <ServiceCalendarDatePicker
  //               // minDate={dayjs(new Date())}
  //               visitDays={visitDays}
  //               open={open}
  //               setOpen={setOpen}
  //               onChange={(newValue) =>
  //                 onSetForm({
  //                   noVisitsExpectedAfter: newValue.format("DD/MM/YYYY"),
  //                 })
  //               }
  //             />
  //           </div>
  //         </div>
  //       ) : null}

  //       <NotesSection
  //         form={form}
  //         onSetForm={onSetForm}
  //         context={context}
  //         contactPlaceholder="Who made the request?"
  //         contactLabel="Requested by"
  //         dateLabel="Requested on"
  //         dateValue={form?.requestedAt}
  //         onChange={(newValue) =>
  //           onSetForm({
  //             requestedAt: newValue.format("DD/MM/YYYY"),
  //           })
  //         }
  //       />
  //     </div>

  //     <DebugButton {...form} />

  //     <ModelErrors errors={errors} />
  //   </div>
  // );
};

RequestSuspensionForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    withImmediateEffect: PropTypes.bool.isRequired,
    noVisitsExpectedAfter: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    vendorLocationCode: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
    suspensionReasons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    visitPlanName: PropTypes.string.isRequired,
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default RequestSuspensionForm;
