import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { RootNode } from "ticket";
import { createStoreWithHistory } from "ticket/app/store";
// import { getStore } from "ticket/app/store";
// import { RootNode } from "ticket";
import { theme } from "../theme";
import { HashRouter as Router } from "react-router-dom";

interface initialProps {
  ticketId: number;
  serviceIds: number[];
  locationId: number;
  vendorId: number;
}

export default (props: initialProps) => {
  const { store } = createStoreWithHistory(props);
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <RootNode/>
          </Router>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
};
