import LinkIcon from "@mui/icons-material/Link";
import {
  Box, Card, CardContent, Chip, Collapse, IconButton, Link, Stack, Typography,
} from "@mui/material";
import { Contact } from "api/tickets";
import _ from 'lodashExtended';
import { flatMap } from "lodash";
import { useContext, useMemo, useState } from "react";
// import { useHistory, useLocation } from "react-router-dom";
import { DebugButton } from "service/GlobalDebugger";
import {
  DownArrowIcon,
  UpArrowIcon,
} from "service/utils/Icons";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { getLocationId, useTicket } from "ticket/selectors";
import ReportRequestCardLayout from "./ReportRequestCardLayout"

const ReattemptVisitCard = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};

  // const history = useHistory();
  // const location = useLocation();
  const locationId = getLocationId();
  const {
    currentTicketId,
    highlightedIds,
    setHighlightedIds,
    onOpenRemediation,
  } = useContext(AppContext);

  // const [open, setOpen] = useState(true);

  // Get all stakeholders
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x
  );

  // Find who inputted the request
  // TODO: Get inputtedById on the request ?
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.inputtedById
  );


  const statusMap = {
    awaiting_date: "#FFA600",
    date_received: "#1CAF38",
  };

  const title = (
    <>
      Re-attempt Visit{" "}
      {_.isPresent(request?.reattemptDate) ? (
        <>
          <span style={{ color: "#19E53B" }}>Confirmed </span>
          for {formatDate(request?.reattemptDate)}
        </>
      ) : (
        <Typography
          component="span"
          sx={{
            fontSize: "13px",
            fontWeight: "700",
            color: statusMap["awaiting_date"],
          }}
        >
          Awaiting Date
        </Typography>
      )}
    </>
  )

  const body = (
    <>
      { _.isPresent(request?.reattemptDate) ? (
        <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
          Visit confirmed for{" "}
          <span style={{ color: "#19E53B" }}>
            {formatDate(request?.reattemptDate)}
          </span>
          <Typography
            component="span"
            sx={{ fontSize: "11px", color: "#828B98" }}
          >
            {" "}
            on{" "}
            <span style={{ color: "#BFBFBF" }}>
              {formatDate(request?.confirmedAt)}
            </span>{" "}
            by{" "}
            <span style={{ color: "#BFBFBF" }}>
              {inputtedBy?.nameWithoutEmail || "No Agent"}
            </span>
          </Typography>
        </Typography>
      ) : (
        <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
          Visit confirmed for{" "}
          <Chip
            label="Awaiting Date"
            size="small"
            sx={{
              background: "#5F6369",
              color: "#BFBFBF",
              fontSize: "11px",
              fontWeight: "bold",
            }}
          />
        </Typography>
      )}
    </>
  )

  return (
    <ReportRequestCardLayout title={title} links='' label={undefined} body={body} request={request} />
  );
};

export default ReattemptVisitCard;
