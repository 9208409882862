import { Box, Grid } from "@mui/material";
import OrganisationMessageSection from "./common/OrganisationMessageSection";
import VendorMessageSection from "./common/VendorMessageSection";

interface ServiceActionMessageFormProps {
  formDefaults: {
    vendor: {
      responseRequired: boolean,
        messageRequired: boolean
    },
      organisation: {
        responseRequired: boolean,
          messageRequired: boolean
      }
  }
}

export const ServiceActionMessageForm:FC<ServiceActionMessageFormProps> = ({ formDefaults }) => {
  return (
    <Box  sx={{
      padding: '0 8px',
      backgroundColor: '#EEEEEE',
    }}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <OrganisationMessageSection {...formDefaults.organisation} />
      </Grid>
      <Grid item xs={6}>
        <VendorMessageSection {...formDefaults.vendor} />
      </Grid>
    </Grid>
  </Box>
  );
};



